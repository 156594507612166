<template>
  <div id="person-info">
    <!-- 个人资料界面 -->
    <div class="table-content">
      <div class="person-info-content">
        <div class="person-info-content-title">
          个人资料
          <i class="el-icon-edit" v-if="disable" @click="edit"></i>
          <i class="el-icon-finished" v-if="!disable" @click="saveValue"></i>
          <i class="el-icon-close" v-if="!disable" @click="cancelEdit"></i>
        </div>
        <div class="person-info-content-data">
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">姓名</div>
            <div class="person-info-content-line-value">
              <el-input
                v-model="userData.userName"
                placeholder="请输入内容"
                :disabled="disable"
              ></el-input>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">手机号</div>
            <div class="person-info-content-line-value">
              <el-input
                v-model="userData.phone"
                placeholder="请输入内容"
                :disabled="disable"
              ></el-input>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">密码</div>
            <div class="person-info-content-line-value">
              <div
                class="person-info-content-line-value-but"
                @click="changePassword"
              >
                修改密码
              </div>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">性别</div>
            <div class="person-info-content-line-value">
              <el-radio-group v-model="userData.sex" :disabled="disable">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">生日</div>
            <div class="person-info-content-line-value">
              <el-date-picker
                v-model="userData.birthday"
                :disabled="disable"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">所属部门</div>
            <div class="person-info-content-line-value">
              <span v-for="(item, index) in userData.department" :key="index">
                {{ item.departmentName }}
              </span>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">角色</div>
            <div class="person-info-content-line-value">
              <span v-for="(item, index) in userData.role" :key="index">
                {{ item.roleName }}
              </span>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">入职时间</div>
            <div class="person-info-content-line-value">
              {{ userData.hiredate == "" ? "--" : userData.hiredate }}
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">状态</div>
            <div class="person-info-content-line-value">
              {{ userData.state == "1" ? "在职" : "离职" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "../components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      disable: true,
      userData: {
        userId: "", // 员工ID
        userName: "",
        phone: "", // 手机号
        icon: "", // 默认图像
        psw: "",
        sex: "",
        birthday: "",
        hiredate: "", // 入职时间,
        identity: "", // 身份证号
        state: "", // 状态:1-> 在职员工(默认状态);0->离职员工
        company: ["", ""], //公司ids
        role: [],
        department: [],
      },
      dialogData: {
        title: "修改密码",
        width: "40%",
        flag: false,
        buttonArray: [
          {
            text: "确定",
            active: "sure",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [
          {
            type: "input_psw",
            title: "原始密码",
            value: "",
          },
          {
            type: "input_psw",
            title: "新密码",
            value: "",
          },
          {
            type: "input_psw",
            title: "确认新密码",
            value: "",
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 获取个人基本信息
     */
    getEmployeeInformationById() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/user/isCharge",
        requestData,
        (data) => {
          this.userData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    edit() {
      this.disable = false;
    },
    saveValue() {
      this.disable = true;
      if (this.userData.userName == "") {
        this.$message({
          message: "用户名不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (this.userData.phone == "") {
        this.$message({
          message: "手机号不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      let requestData = {
        userId: this.userData.userId, // 员工ID
        userName: this.userData.userName,
        phone: this.userData.phone, // 手机号
        // psw: this.userData.psw,
        sex: this.userData.sex,
        birthday: this.userData.birthday,
      };
      this.$request(
        "post",
        "/user/isCharge",
        requestData,
        (data) => {
          this.$message({
            message: "修改成功!",
            type: "success",
            duration: 1000,
          });
          this.getEmployeeInformationById();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.getEmployeeInformationById();
        }
      );
    },
    changePassword() {
      this.disable = false;
      this.dialogData.flag = true;
    },
    cancelEdit() {
      this.disable = true;
      this.getEmployeeInformationById();
    },
    drawerOper(active) {
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;
        case "sure":
          if (
            this.dialogData.data[0].value == "" ||
            this.dialogData.data[1].value == "" ||
            this.dialogData.data[2].value == ""
          ) {
            this.$message({
              message: "手机号不可为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          // if (this.dialogData.data[0].value != this.userData.psw) {
          //   this.$message({
          //     message: "原密码错误,请重新输入!",
          //     type: "error",
          //     duration: 1000,
          //   });
          //   this.dialogData.data[0].value = "";
          //   this.dialogData.data[1].value = "";
          //   this.dialogData.data[2].value = "";
          //   return;
          // }
          if (this.dialogData.data[1].value != this.dialogData.data[2].value) {
            this.$message({
              message: "两次密码不一致,请重新输入!",
              type: "error",
              duration: 1000,
            });
            this.dialogData.data[0].value = "";
            this.dialogData.data[1].value = "";
            this.dialogData.data[2].value = "";
            return;
          }
          let requestData = {
            userId: this.baseInfo.userId,
            oldPsw: this.dialogData.data[0].value,
            newPsw: this.dialogData.data[1].value,
          };
          this.$request(
            "post",
            "/user/updateEmployeePsw",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message({
                message: "密码修改成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.data[0].value = "";
              this.dialogData.data[1].value = "";
              this.dialogData.data[2].value = "";
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
                duration: 1000,
              });
              this.dialogData.data[0].value = "";
              this.dialogData.data[1].value = "";
              this.dialogData.data[2].value = "";
            }
          );
          break;

        default:
          break;
      }
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getEmployeeInformationById();
  },
};
</script>

<style lang="less" scoped>
#person-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .person-info-content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .person-info-content-title {
      width: 90%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 40px;
      font-size: 17px;
      i {
        margin-left: 20px;
        transition: 0.3s;
        &:hover {
          color: rgb(51, 253, 10);
          cursor: pointer;
        }
      }
    }
    .person-info-content-data {
      width: 80%;
      margin-left: 80px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .person-info-content-line {
        display: flex;
        width: 100%;
        height: 40px;
        .person-info-content-line-span {
          width: 100px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 10px;
        }
        .person-info-content-line-value {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 20px;
          .person-info-content-line-value-but {
            width: 100px;
            height: 30px;
            border: 1px solid @activeColor;
            color: @activeColor;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s;
          }
          .person-info-content-line-value-but:hover {
            cursor: pointer;
            background-color: @activeColor;
            color: #fff;
          }
          .el-input {
            width: 220px !important;
          }
          .person-info-content-line-value-text {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
</style>
