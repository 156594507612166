<template>
  <div id="person-info">
    <!-- 个人资料界面 -->
    <div class="table-content">
      <div class="person-info-content">
        <div class="person-info-content-title">
          个人资料
          <i class="el-icon-edit" v-if="disable" @click="edit"></i>
          <i class="el-icon-finished" v-if="!disable" @click="saveValue"></i>
          <i class="el-icon-close" v-if="!disable" @click="cancelEdit"></i>
        </div>
        <div class="person-info-content-data">
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">姓名</div>
            <div class="person-info-content-line-value">
              <el-input
                v-if="!disable"
                v-model="userData.userName"
                placeholder="请输入内容"
              ></el-input>
              <span v-else>{{ userData.userName }}</span>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">手机号</div>
            <div class="person-info-content-line-value">
              <el-input
                v-model="userData.phone"
                placeholder="请输入内容"
                v-if="!disable"
              ></el-input>
              <span v-else>{{ userData.phone }}</span>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">密码</div>
            <div class="person-info-content-line-value">
              <el-button
                type="primary"
                size="mini"
                plain
                @click="changePassword"
                >修改密码</el-button
              >
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">性别</div>
            <div class="person-info-content-line-value">
              <el-radio-group v-model="userData.sex" v-if="!disable">
                <el-radio label="男">男</el-radio>
                <el-radio label="女">女</el-radio>
              </el-radio-group>
              <span v-else>{{ userData.sex }}</span>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">生日</div>
            <div class="person-info-content-line-value">
              <el-date-picker
                v-model="userData.birthday"
                v-if="!disable"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <span v-else>{{ userData.birthday }}</span>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">所属部门</div>
            <div class="person-info-content-line-value">
              <span v-if="userData.department">
                {{ userData.department.join(",") }}
              </span>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">角色</div>
            <div class="person-info-content-line-value">
              <span v-if="userData.role">
                {{ userData.role.join(",") }}
              </span>
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">入职时间</div>
            <div class="person-info-content-line-value">
              {{ userData.hiredate == "" ? "--" : userData.hiredate }}
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">状态</div>
            <div class="person-info-content-line-value">
              {{ userData.state }}
            </div>
          </div>
          <div class="person-info-content-line">
            <div class="person-info-content-line-span">呼叫号码</div>
            <div class="person-info-content-line-value">
              <el-button type="primary" size="mini" plain @click="settingPhone"
                >设置</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @deleteItem="deleteItem"
        @launch="launch"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import my_dialog from "../components/dialog.vue";
export default {
  components: {
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      disable: true,
      userData: {},
      dialogData: {
        title: "修改密码",
        width: "40%",
        flag: false,
        buttonArray: [
          {
            text: "确定",
            active: "sure",
          },
          {
            text: "取消",
            active: "cancel",
          },
        ],
        data: [
          {
            type: "input_psw",
            title: "原始密码",
            value: "",
          },
          {
            type: "input_psw",
            title: "新密码",
            value: "",
          },
          {
            type: "input_psw",
            title: "确认新密码",
            value: "",
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 获取个人基本信息
     */
    getEmployeeInformationById() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
      };
      this.$request(
        "post",
        "/user/getEmployeeInformationById",
        requestData,
        (data) => {
          this.userData = data;
          this.userData.department = data.department.map(
            (item) => item.departmentName
          );
          this.userData.role = data.role.map((item) => item.roleName);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    edit() {
      this.disable = false;
    },
    saveValue() {
      this.disable = true;
      if (this.userData.userName == "") {
        this.$message({
          message: "用户名不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      if (this.userData.phone == "") {
        this.$message({
          message: "手机号不可为空!",
          type: "error",
          duration: 1000,
        });
        return;
      }
      let requestData = {
        userId: this.userData.userId, // 员工ID
        userName: this.userData.userName,
        phone: this.userData.phone, // 手机号
        // psw: this.userData.psw,
        sex: this.userData.sex,
        birthday: this.userData.birthday,
      };
      this.$request(
        "post",
        "/user/isCharge",
        requestData,
        (data) => {
          this.$message({
            message: "修改成功!",
            type: "success",
            duration: 1000,
          });
          this.getEmployeeInformationById();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.getEmployeeInformationById();
        }
      );
    },
    changePassword() {
      this.disable = false;
      this.dialogData.flag = true;
    },
    cancelEdit() {
      this.disable = true;
      this.getEmployeeInformationById();
    },
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "sure":
          if (
            this.dialogData.data[0].value == "" ||
            this.dialogData.data[1].value == "" ||
            this.dialogData.data[2].value == ""
          ) {
            this.$message({
              message: "手机号不可为空!",
              type: "error",
              duration: 1000,
            });
            return;
          }
          if (this.dialogData.data[1].value != this.dialogData.data[2].value) {
            this.$message({
              message: "两次密码不一致,请重新输入!",
              type: "error",
              duration: 1000,
            });
            this.dialogData.data[0].value = "";
            this.dialogData.data[1].value = "";
            this.dialogData.data[2].value = "";
            return;
          }
          requestData = {
            userId: this.baseInfo.userId,
            oldPsw: this.dialogData.data[0].value,
            newPsw: this.dialogData.data[1].value,
          };
          this.$request(
            "post",
            "/user/updateEmployeePsw",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.$message({
                message: "密码修改成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.data[0].value = "";
              this.dialogData.data[1].value = "";
              this.dialogData.data[2].value = "";
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
                duration: 1000,
              });
              this.dialogData.data[0].value = "";
              this.dialogData.data[1].value = "";
              this.dialogData.data[2].value = "";
            }
          );
          break;
        case "addNumber":
          // 添加号码组合
          this.dialogData.data[0].value.push({
            userId: this.baseInfo.userId,
            callNumber: "", // 呼叫号
            middleNumber: "", // 中间号
          });
          break;
        case "saveNumber":
          // 保存号码组合
          requestData = {
            telUsers: this.dialogData.data[0].value,
            userId: this.baseInfo.userId,
          };
          requestData.telUsers.forEach((item, index) => {
            item.sort = index;
          });
          this.$request(
            "post",
            "/telUser/renewal",
            requestData,
            (data) => {
              this.$message.success("保存成功!");
              this.dialogData.flag = false;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    colseDialog() {
      this.dialogData.flag = false;
    },
    deleteItem(index) {
      this.dialogData.data[0].value.splice(index, 1);
    },
    launch(data) {
      this.$confirm(
        "此操作将禁用其他号码组合, 并启用该号码组合, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$request(
            "get",
            "/telUser/enable?id=" + data.item.id,
            {},
            (data) => {
              this.$message.success("启用成功!");
              this.settingPhone();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {});
    },
    /**
     * 外呼号码设置
     */
    settingPhone() {
      this.$request(
        "get",
        "/telUser/listByUser?userId=" + this.baseInfo.userId,
        {},
        (data) => {
          this.dialogData.width = "650px";
          this.dialogData.title = "外呼号码设置";
          this.dialogData.buttonArray = [
            {
              text: "添加组合",
              active: "addNumber",
            },
            {
              text: "保存",
              active: "saveNumber",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "number-setting",
              value: data || [],
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getEmployeeInformationById();
  },
};
</script>

<style lang="less" scoped>
#person-info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .person-info-content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .person-info-content-title {
      width: 90%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 40px;
      font-size: 17px;
      i {
        margin-left: 20px;
        transition: 0.3s;
        &:hover {
          color: rgb(51, 253, 10);
          cursor: pointer;
        }
      }
    }
    .person-info-content-data {
      width: 80%;
      margin-left: 80px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      .person-info-content-line {
        display: flex;
        width: 100%;
        height: 40px;
        .person-info-content-line-span {
          width: 100px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          margin-right: 10px;
        }
        .person-info-content-line-value {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          margin-left: 20px;
          .person-info-content-line-value-but {
            width: 100px;
            height: 30px;
            border: 1px solid @activeColor;
            color: @activeColor;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s;
          }
          .person-info-content-line-value-but:hover {
            cursor: pointer;
            background-color: @activeColor;
            color: #fff;
          }
          .el-input {
            width: 220px !important;
          }
          .person-info-content-line-value-text {
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }
      }
    }
  }
}
</style>
