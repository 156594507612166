<template>
  <div id="tax-issue-code">
    <div class="tax-issue-code-search">
      <div class="tax-issue-code-search-item">
        <span class="tax-issue-code-search-item-title"
          >跨区域涉税事项报验管理编号</span
        >
        <span class="tax-issue-code-search-item-value"
          ><el-input v-model="searchData.kqysssxbgglbm"></el-input
        ></span>
      </div>
      <div class="tax-issue-code-search-item">
        <span class="tax-issue-code-search-item-title"
          >合同对方纳税人识别号</span
        >
        <span class="tax-issue-code-search-item-value"
          ><el-input v-model="searchData.htdfnsrsbh"></el-input
        ></span>
      </div>
      <div class="tax-issue-code-search-item">
        <span class="tax-issue-code-search-item-title">合同对方纳税人名称</span>
        <span class="tax-issue-code-search-item-value"
          ><el-input v-model="searchData.htdfnsrmc"></el-input
        ></span>
      </div>
      <div class="tax-issue-code-search-item">
        <span class="tax-issue-code-search-item-title"
          >跨区域经营地行政区划</span
        >
        <span class="tax-issue-code-search-item-value"
          ><area-cascader
            :level="1"
            type="text"
            placeholder="请选择地区"
            v-model="searchData.kqyjydxzqh"
            :data="pcaa"
            @change="handleChange"
          ></area-cascader>
        </span>
      </div>
      <div class="tax-issue-code-search-item">
        <span class="tax-issue-code-search-item-title"
          >跨区域涉税事项有效期起</span
        >
        <span class="tax-issue-code-search-item-value"
          ><el-date-picker
            v-model="searchData.yxqq"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker
        ></span>
      </div>
      <div class="tax-issue-code-search-item">
        <span class="tax-issue-code-search-item-title"
          >跨区域涉税事项有效期止</span
        >
        <span class="tax-issue-code-search-item-value"
          ><el-date-picker
            v-model="searchData.yxqz"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker
        ></span>
      </div>
      <div class="tax-issue-code-search-item">
        <span class="tax-issue-code-search-item-title">工程项目名称</span>
        <span class="tax-issue-code-search-item-value"
          ><el-input v-model="searchData.gcxmmc"></el-input
          ><el-button
            type="primary"
            size="mini"
            style="margin-left: 10px"
            @click="initTableData"
            >查询</el-button
          ></span
        >
      </div>
    </div>
    <div class="tax-issue-code-table">
      <el-table
        :data="tableData"
        style="width: 100%; height: 400px;"
        :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
        v-loading="loading"
      >
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column
          prop="kqysssxbgglbm"
          label="跨区域涉税事项报验管理编号"
          width="220"
        >
        </el-table-column>
        <el-table-column prop="gcxmmc" label="工程项目名称"> </el-table-column>
        <el-table-column prop="fwfsd" label="跨区域经营地行政区划">
        </el-table-column>
        <el-table-column prop="jdxzmc" label="跨区域经营乡镇街道">
        </el-table-column>
        <el-table-column
          prop="wcjyd"
          label="跨区域经营地址"
          width="150"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="drawerOper('choose', scope.row)"
              >选择</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tax-issue-code-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total,sizes, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <!-- 登录认证 -->
    <el-dialog
      class="login-dialog"
      title="登录认证"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @closed="loginClosed"
      append-to-body
    >
      <el-tabs v-model="activeName" @tab-click="tbasClick">
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          :label="item.label"
          :name="item.name"
        >
          <div class="login-dialog-note" v-if="activeName == '短信认证'">
            <el-form
              label-position="left"
              label-width="160px"
              :model="loginForm"
            >
              <el-form-item label="社会信用代码/识别号:">
                <el-input disabled v-model="loginForm.nsrsbh"></el-input>
              </el-form-item>
              <el-form-item label="数电账号:">
                <el-input disabled v-model="loginForm.username"></el-input>
              </el-form-item>
              <el-form-item label="手机验证码:">
                <el-input v-model="loginForm.sms"></el-input>
              </el-form-item>
            </el-form>
            <div class="button">
              <el-button
                :disabled="codeFlag"
                type="primary"
                size="small"
                @click="getCode"
              >
                <span v-if="codeFlag">({{ buttonText }})</span>
                <span v-else>获取验证码</span>
              </el-button>
            </div>
          </div>
          <div v-else-if="activeName == '扫码认证'">
            <el-alert
              :title="
                `请使用账号${loginForm.username}登录税务APP扫描二维码完成认证, 认证成功后, 点击认证按钮`
              "
              type="warning"
              show-icon
            >
            </el-alert>
            <div class="face-dialog-content">
              <img
                style="width: 150px; height: 150px"
                :src="`data:image/png;base64,${qrCode}`"
                alt=""
              />
            </div>
          </div>
          <div v-else-if="activeName == '人脸认证'">
            <el-alert
              :title="
                `请使用账号${loginForm.username}登录税务APP扫描二维码完成认证, 认证成功后, 点击认证按钮`
              "
              type="warning"
              show-icon
            >
            </el-alert>
            <div class="face-dialog-content">
              <qriously :value="qrCode" :size="180" />
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          :loading="loginLoading"
          @click="drawerOper('renzheng')"
          >认证</el-button
        >
      </span>
    </el-dialog>
    <!-- 人脸识别 -->
    <el-dialog
      class="face-dialog"
      title="人脸识别"
      :visible.sync="faceFlag"
      width="500px"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @closed="faceClosed"
    >
      <div class="face-dialog-content">
        <qriously :value="qrCode" :size="180" />
        <div class="text" style="cursor: pointer;" @click="getFaceRecognition">
          点击刷新
        </div>
        <div class="text">请使用手机扫码进行人脸识别</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { pca, pcaa } from "area-data";
export default {
  props: ["data"],
  data() {
    return {
      loading: false,
      loginLoading: false,
      searchData: {
        kqysssxbgglbm: "",
        htdfnsrsbh: "",
        htdfnsrmc: "",
        kqyjydxzqh: "",
        gcxmmc: "",
        yxqq: "",
        yxqz: "",
      },
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      pcaa: pcaa,
      dialogVisible: false, // 登录认证
      faceFlag: false, // 人脸识别
      tabList: [
        {
          label: "短信认证",
          name: "短信认证",
        },
        {
          label: "扫码认证",
          name: "扫码认证",
        },
        {
          label: "人脸认证",
          name: "人脸认证",
        },
      ],
      buttonText: "60秒",
      countdown: 60,
      timer: null, // 短信倒计时
      time: null, // 二维码状态轮询
      activeName: "短信认证",
      codeFlag: false,
      qrCode: "",
      loginForm: {
        nsrsbh: "", // 纳税人识别号
        username: "", // 电票平台账号
        password: "", // 电票平台密码
        position: "", // 电子税务局身份
        ewmlx: "", // 1-人脸二维码登录 10-税务 app 扫码登录
        ewmid: "", // /
        sms: "", // 验证码
      },
    };
  },
  methods: {
    initTableData() {
      this.loading = true;
      let requestData = {
        newNsrsbh: this.loginForm.nsrsbh, // 需要切换的纳税人识别号
        username: this.loginForm.username, // 电票平台账号
        sf: this.loginForm.position, // 电子税务局身份，切换后纳税人的身份
      };
      this.$request(
        "post",
        "/invoice/changeUser",
        requestData,
        (data) => {
          let requestData2 = {
            username: this.loginForm.username, // 电票平台账号
            nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号码
            ...this.searchData,
            page: this.pageData.currentPage,
            size: this.pageData.pageSize,
          };
          this.$request(
            "post",
            "/invoice/queryKqysssxbyglbh",
            requestData2,
            (data2) => {
              this.loading = false;
              if (data2.code == 200) {
                this.tableData = data2.data;
              } else if (data2.code == 302) {
                this.dialogVisible = true;
                setTimeout(() => {
                  this.$message.error("暂未登录电票平台, 请先登录!");
                }, 100);
              } else {
                this.$message.error(data2.message);
              }
            },
            (errorMessage) => {
              this.loading = false;
              this.$message.error(errorMessage);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    handleChange() {
      this.searchData.kqyjydxzqh =
        this.searchData.kqyjydxzqh[0] +
        "-" +
        this.searchData.kqyjydxzqh[1] +
        "-" +
        this.searchData.kqyjydxzqh[2];
    },
    tbasClick(e) {
      this.loginForm.ewmlx = "";
      this.loginForm.ewmid = "";
      if (this.activeName !== "短信认证") {
        switch (this.activeName) {
          case "扫码认证":
            this.loginForm.ewmlx = 10;
            let requestData = {
              nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
              username: this.loginForm.username, // 电票平台账号
              password: this.loginForm.password, // 用户电票平台密码
              ewmlx: this.loginForm.ewmlx, // 1-人脸二维码登录 10-税务 app 扫码登录
            };
            this.$request(
              "post",
              "/invoice/loginDppt",
              requestData,
              (data) => {
                if (data.code === 10009) {
                  this.loginForm.ewmid = data.data.ewmid;
                  this.qrCode = data.data.qrcode;
                }
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;
          case "人脸认证":
            this.loginForm.ewmlx = 1;
            break;
          default:
            break;
        }
      }
    },
    /**
     * 获取人脸识别二维码
     */
    getFaceRecognition() {
      let requestData = {
        username: this.loginForm.username, // 电票平台账号
        nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
        type: "", // 类型 值为 2 获取个人所得税二维码，不传或者其他值都是税局app 二维码
      };
      this.$request(
        "post",
        "/invoice/getFaceImg",
        requestData,
        (data) => {
          if (data.code === 200) {
            this.qrCode = data.data.ewm;
            console.log("this.qrCode", this.qrCode);
            this.faceFlag = true;
            setTimeout(() => {
              this.$message.error("暂未进行人脸识别认证, 请认证!");
            }, 100);
            clearInterval(this.time);
            this.time = setInterval(() => {
              this.getFaceRecognitionState(data.data.rzid);
            }, 1000);
          } else {
            this.$message.error(data.message);
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取人脸二维码认证状态
     */
    getFaceRecognitionState(rzid) {
      let requestData = {
        username: this.loginForm.username, // 电票平台账号
        nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
        rzid: rzid, // 认证id
        type: "", // 类型 值为 2 获取个人所得税二维码，不传或者其他值都是税局app 二维码
      };
      this.$request(
        "post",
        "/invoice/getFaceState",
        requestData,
        (data) => {
          // 检查受理状态: 1 未认证，2 成功，3 二维码过期
          if (data.data.slzt == 2) {
            this.faceFlag = false;
            clearInterval(this.time);
            this.$message.success(data.message);
          }
          if (data.data.slzt == 3) {
            clearInterval(this.time);
            this.$message.error("二维码已过期，请重新获取!");
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    loginClosed() {
      this.loginForm.ewmlx = "";
      this.loginForm.ewmid = "";
      this.loginForm.sms = "";
      this.activeName = "短信认证";
    },
    faceClosed() {
      clearInterval(this.time);
    },
    /**
     * 获取验证码
     */
    getCode() {
      this.codeFlag = true;
      let requestData = {
        username: this.loginForm.username, // 电票平台账号
        nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
        password: this.loginForm.password, // 用户电票平台密码
        sms: this.loginForm.sms, // 验证码
        // cert: "", // 1-使用证书登录 2-使用软证书登录
        // ewmlx: "", // 1-人脸二维码登录 10-税务 app 扫码登录
        // ewmid: "", // 第一次调用只传二维码类型，会返回 ewmid 和二维码的 base64，第二次调用二维码类型跟第一次调用值必须一样
        // pin: "", // 软证书口令，cert 为 2 时如果有就传
      };
      this.$request(
        "post",
        "/invoice/loginDppt",
        requestData,
        (data) => {
          if (data.code === 200) {
            this.$message.success(data.message);
          } else {
            this.$message.error(data.message);
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.countdown > 0 && this.countdown <= 60) {
            this.countdown--;
            if (this.countdown !== 0) {
              this.buttonText = this.countdown + "秒";
            } else {
              clearInterval(this.timer);
              this.buttonText = "60秒";
              this.codeFlag = false;
              this.countdown = 60;
              this.timer = null;
            }
          }
        }, 1000);
      }
    },
    /**
     * 弹出框操作事件
     */
    drawerOper(active, data) {
      let that = this;
      let requestData = {};
      switch (active) {
        case "renzheng":
          // 验证码登录认证
          requestData = {
            username: this.loginForm.username, // 电票平台账号
            nsrsbh: this.loginForm.nsrsbh, // 纳税人识别号
            password: this.loginForm.password, // 用户电票平台密码
            sms: this.loginForm.sms, // 验证码
            ewmlx: this.loginForm.ewmlx, // 1-人脸二维码登录 10-税务 app 扫码登录
            ewmid: this.loginForm.ewmid, // 第一次调用只传二维码类型，会返回 ewmid 和二维码的 base64，第二次调用二维码类型跟第一次调用值必须一样
          };
          if (this.activeName == "短信认证" && !requestData.sms) {
            this.$message.error("请先获取验证码后再进行认证!");
            return;
          }
          this.loginLoading = true;
          this.$request(
            "post",
            "/invoice/loginDppt",
            requestData,
            (data) => {
              this.loginLoading = false;
              if (data.code === 200) {
                this.loginClosed();
                this.$message.success(data.message);
                this.dialogVisible = false;
              } else {
                this.$message.error(data.message);
              }
            },
            (errorMessage) => {
              this.loginLoading = false;
              this.$message.error(errorMessage);
            }
          );
          break;
        case "choose":
          // 取消
          this.$emit("addCode", data);
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          break;
        default:
          break;
      }
    },
    getClientInfo() {
      let requestData = {
        id: this.data.clientId,
      };
      this.$request(
        "post",
        "/client/getClientInfoByClientID",
        requestData,
        (data) => {
          this.loginForm.nsrsbh = data.taxNum;
          this.loginForm.username = data.username;
          this.loginForm.password = data.password;
          this.loginForm.position = data.position;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  created() {
    this.getClientInfo();
  },
};
</script>

<style lang="less" scoped>
#tax-issue-code {
  width: 100%;
  height: 100%;
  .tax-issue-code-search {
    width: 80%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .tax-issue-code-search-item {
      width: 50%;
      height: 40px;
      display: flex;
      align-items: center;
      .tax-issue-code-search-item-title {
        width: 250px;
        text-align: right;
        font-size: 14px;
        margin-right: 10px;
      }
      .tax-issue-code-search-item-value {
        display: flex;
        flex: 1;
      }
    }
  }
  .tax-issue-code-table {
    height: 400px;
  }
  /deep/ .cascader-menu-list {
    .cascader-menu-option.selected {
      color: #40a3ff;
    }
  }
  /deep/.area-select .area-selected-trigger {
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="less" scoped>
.login-dialog,
.face-dialog {
  text-align: left;
  /deep/ .el-dialog {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 200px);
    max-width: calc(100% - 30px);

    .el-dialog__header {
      border-radius: 3px;
      background-color: #f5f8fa;
      display: flex;
      padding: 0;
      padding-left: 10px;
      align-items: center;
      height: 45px;
      border-bottom: 1px solid #ced4db;
    }
    .el-dialog__body {
      padding: 0;
      flex: 1;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 0;
      padding-right: 1rem;
      background-color: #f5f8fa;
      border-radius: 0 0 5px 5px;
      border-top: 1px solid #ced4db;
      height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  /deep/ .el-tabs {
    .el-tabs__nav-scroll {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
    }
    .el-tabs__item {
      font-size: 17px;
      .is-active {
        color: #303130;
      }
    }
  }
  .login-dialog-note {
    position: relative;
    width: 440px;
    padding: 10px 50px 0 30px;
    box-sizing: border-box;
    /deep/ .el-form-item {
      margin-bottom: 15px;
    }
    .button {
      position: absolute;
      bottom: 3px;
      right: -57px;
    }
  }
  .face-dialog-content {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .text {
      font-size: 14px;
      color: @activeColor;
      margin-top: 20px;
    }
  }
}
</style>
