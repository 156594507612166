<template>
  <!-- 抽屉 成本 -->
  <div id="company-drawer-table-cost">
    <div class="company-drawer-table-cost-search">
      <div class="company-drawer-table-cost-search-item">
        <el-date-picker
          v-model="searchData.date"
          type="month"
          placeholder="选择年月"
          :clearable="false"
          value-format="yyyy-MM"
          @change="initTableData"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="company-drawer-table-cost-content">
      <el-table
        ref="table"
        :data="tableData"
        :header-cell-style="{ background: '#F5F7FA', color: '#606266' }"
        highlight-current-row
        style="width: 100%"
        height="98%"
        border
        v-loading="loading"
        :row-style="{ height: '60px' }"
        :cell-style="columnStyle"
        @sort-change="sortChange"
      >
        <el-table-column type="index" width="40" fixed></el-table-column>
        <el-table-column
          v-for="(item, index) in tableMenu"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :fixed="item.fixed"
          :width="item.width"
          align="center"
          show-overflow-tooltip
          :sortable="item.sortable ? 'custom' : false"
        >
          <template slot-scope="scope">
            <el-input
              v-if="item.type == 'input'"
              v-model="scope.row[item.prop]"
              :disabled="editFlag"
              @keyup.enter.native="
                changeItem(scope.row[item.prop], scope.row, item.prop)
              "
            ></el-input>
            <el-popover
              trigger="hover"
              placement="top"
              v-else-if="item.type == 'select' && item.prop == 'chargeUpStatus'"
            >
              <p>操作人: {{ scope.row.lastEditUserForChargeUp }}</p>
              <p>操作时间: {{ scope.row.lastEditTimeForChargeUp }}</p>
              <div slot="reference" class="name-wrapper">
                <el-select
                  v-model="scope.row[item.prop]"
                  :disabled="chargeUpConfirmFlag"
                  :name="scope.row[item.prop]"
                  @change="
                    changeItem(scope.row[item.prop], scope.row, item.prop)
                  "
                >
                  <el-option
                    v-for="it in item.options"
                    :key="it.value"
                    :label="it.label"
                    :value="it.value"
                  ></el-option
                ></el-select>
              </div>
            </el-popover>
            <el-popover
              trigger="hover"
              placement="top"
              v-else-if="item.type == 'select' && item.prop == 'checkStatus'"
            >
              <p>操作人: {{ scope.row.lastEditUserForCheck }}</p>
              <p>操作时间: {{ scope.row.lastEditTimeForCheck }}</p>
              <div slot="reference" class="name-wrapper">
                <el-select
                  v-model="scope.row[item.prop]"
                  :disabled="checkFlag"
                  :name="scope.row[item.prop]"
                  @change="
                    changeItem(scope.row[item.prop], scope.row, item.prop)
                  "
                >
                  <el-option
                    v-for="it in item.options"
                    :key="it.value"
                    :label="it.label"
                    :value="it.value"
                  ></el-option
                ></el-select>
              </div>
            </el-popover>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="company-drawer-table-cost-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as taxData from "@/views/tax/js/tax.js";
export default {
  props: {
    tempClient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pictLoading: false,
      tabIndex: 0,
      loading: false,
      baseInfo: {},
      tableData: [],
      searchData: {
        input: "",
        department: "",
        departTreeData: [],
        userId: "",
        userList: [],
        userIdList: [],
        tempUserId: [],
        date: this.$formatTime(new Date() - 31 * 24 * 3600 * 1000, "YYYY-MM"),
        companyType: "",
        nearlyYearWarn: false,
        annualOperatingCostWarn: false,
        tentativeCostIsNull: false,
        annualOperatingExpenseWarn: false,
      },
      allFunctionButtons: this.$featureData.getAllFunctionButtons("taxCost"),
      tableMenu: taxData.taxIncomeTableMenuByDrawer(),
      tableData: [],
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 20, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "运单详情",
        buttonArray: [
          {
            text: "确认",
            active: "confirm",
          },
        ],
        data: [],
      },
      tempData: {},
      whetherAgencyHead: false,
      editFlag: true,
      value: [],
      options: [],
      defaultSort: {
        prop: "",
        order: "",
      },
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "taxCost",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("this.allFunctionButtonscc", this.allFunctionButtons);
          this.editFlag = !this.allFunctionButtons.some(
            (item) => item.key == "edit" && item.authority == "1"
          );
          this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
      });
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getEmployeeList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        clientId: this.tempClient.id,
        clientName: this.searchData.input, // 客户简称
        date: this.searchData.date, // 日期
        companyType: this.searchData.companyType, // 公司类型
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "", // 部门 id
        userIdList: this.searchData.tempUserId, // 员工 id 集合
        nearlyYearWarn: this.searchData.nearlyYearWarn, // 年营业成本（年营业成本除以年营业收入）占比至少 50%
        annualOperatingCostWarn: this.searchData.annualOperatingCostWarn, // 年营业费用（年营业费用除以年营业收入）超过30%
        tentativeCostIsNull: this.searchData.tentativeCostIsNull, // 暂估成本未填
        annualOperatingExpenseWarn: this.searchData.annualOperatingExpenseWarn, // 近12月营业收入（超过400w）500万预警
        sortProp: this.defaultSort.prop, // 手动排序项
        sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        pageSize: this.pageData.pageSize, // 每页条数
        currentPage: this.pageData.currentPage, // 当前页数
      };
      this.$request(
        "post",
        "/enterpriseCost/page",
        requestData,
        (data) => {
          this.tableData = data.records;
          this.tableData.forEach((item) => {
            item.host = item.host.join(",");
            item.consultant = item.consultant.join(",");
          });
          this.pageData.total = data.total; // 获取总条目数
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 界面操作
     */
    handleClick(data, flag) {
      this.tempData = data;
      let requestData = {};
      switch (flag) {
        case 0:
          break;
        default:
          break;
      }
    },
    /**
     * 编辑银行授权状态 和 流水状态
     */
    changeItem(value, row, prop) {
      let requestData = {
        clientId: row.clientId, // 客户 id
        id: row.id, // id
        date: this.searchData.date, // 年份
      };
      requestData[prop] = value;
      if (prop == "chargeUpStatus") {
        // 记账状态
        requestData.lastEditUserForChargeUp = this.baseInfo.userId;
        requestData.lastEditTimeForChargeUp = this.$formatTime(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      if (prop == "checkStatus") {
        // 审核状态
        requestData.lastEditUserForCheck = this.baseInfo.userId;
        requestData.lastEditTimeForCheck = this.$formatTime(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      let flag = this.$numberReg(value);
      if (!flag) {
        this.$message.error("请填写数字再进行编辑");
        return;
      }
      this.$request(
        "post",
        "/enterpriseCost/saveOrUpdate",
        requestData,
        (data) => {
          this.$message.success("编辑成功!");
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉数据或dialog数据操作事件
     */
    drawerOper(active, data) {
      let requestData = {};
      switch (active) {
        case "cancel":
          this.colseDialog();
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 9) {
        return "background:	#FEF0F0;";
      }
    },
    /**
     * 表格排序事件
     */
    sortChange(data) {
      const prop = this.$toLowerCase(data.prop);
      this.defaultSort.prop = data.order == null ? "" : prop;
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.initTableData();
    },
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#company-drawer-table-cost {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .company-drawer-table-cost-search {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: 10px;
    .company-drawer-table-cost-search-item {
      width: 170px;
      min-width: 170px;
      height: 30px;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }
  }
  .company-drawer-table-cost-content {
    width: 100%;
    height: 100%;
    border-top: 1px solid #e9ebed;
    /deep/ .el-select [name="未完成"] {
      color: #f56c6c;
    }
    /deep/ .el-select [name="已完成"] {
      color: #67c23a;
    }
    /deep/ .el-select [name="未审核"] {
      color: #f56c6c;
    }
    /deep/ .el-select [name="未通过"] {
      color: #e6a23c;
    }
    /deep/ .el-select [name="已通过"] {
      color: #67c23a;
    }
    /deep/ .red .el-input__inner {
      color: #f56c6c;
    }
    /deep/ .red .el-input__inner {
      color: #f56c6c;
    }
    /deep/ .el-input__inner {
      border: 0;
      background-color: #fff;
    }
    /deep/ .el-input__suffix {
      display: none;
    }
    /deep/ .el-table--scrollable-x .el-table__body-wrapper {
      z-index: 1;
      &::-webkit-scrollbar {
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    /deep/ .el-table--scrollable-y .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .company-drawer-table-cost-pagination {
    width: 100%;
    height: auto;
  }
}
</style>
