<template>
  <div id="contract-renew">
    <!-- 续签管理 -->
    <div class="screen">
      <div class="screen-line">
        <div class="contract-screen-fiftrate">
          <el-popover
            placement="bottom-start"
            width="250"
            trigger="manual"
            :visible-arrow="false"
            v-model="popoData.popoVisible"
          >
            <div class="popover-search">
              <popo_search
                :list="popoData.list"
                @cancel="popoCanael"
                @reset="popoReset"
                @makeSure="popoMakeSure"
              ></popo_search>
            </div>
            <div slot="reference" @click="popoverOpen">
              筛选 <i class="el-icon-s-operation"></i>
            </div>
          </el-popover>
        </div>
        <div class="contract-screen-search">
          <el-input
            placeholder="客户全称/编号/合同编号"
            v-model="searchData.input"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="contract-screen-search">
          <el-cascader
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
        </div>
        <div class="contract-screen-search">
          <el-select
            v-model="searchData.userId"
            @change="selectInitTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="contract-screen-checked">
          <el-checkbox v-model="searchData.checked" @change="selectAllRequire"
            >包含已续签</el-checkbox
          >
        </div>
        <div class="contract-screen-active"></div>
      </div>
      <div class="screen-line" v-if="searchData.screenList.length > 0">
        <div class="title">筛选条件:</div>
        <el-tag
          v-for="(tag, index) in searchData.screenList"
          :key="index"
          closable
          size="small"
          @close="closeScreen(index)"
        >
          {{ tag.name }}
        </el-tag>
      </div>
    </div>
    <div class="contract-table">
      <div class="contract-table-card">
        <div class="contract-table-card-tabs">
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            全部合同
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            到期6月以上
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 2,
            }"
            @click="changeTab(2)"
          >
            到期6个月
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 3,
            }"
            @click="changeTab(3)"
          >
            到期5个月
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 4,
            }"
            @click="changeTab(4)"
          >
            到期4个月
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 5,
            }"
            @click="changeTab(5)"
          >
            到期3个月
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 6,
            }"
            @click="changeTab(6)"
          >
            到期2个月
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 7,
            }"
            @click="changeTab(7)"
          >
            到期1个月
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 8,
            }"
            @click="changeTab(8)"
          >
            已到期
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 9,
            }"
            @click="changeTab(9)"
          >
            当月到期
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 10,
            }"
            @click="changeTab(10)"
          >
            下月到期
          </div>
          <div
            :class="{
              'contract-table-card-tab': true,
              'is-active': tabindex == 11,
            }"
            @click="changeTab(11)"
          >
            3个月内到期
          </div>
          <div class="contract-table-card-active"></div>
          <div class="contract-table-card-edit">
            <i class="el-icon-edit-outline" @click="updateTableMenu"></i>
          </div>
        </div>
        <div class="contract-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            @row-click="rowClick"
            style="width: 100%"
            height="98%"
            :row-style="rowStyle"
            v-loading="loading"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <template v-for="(item, index) in tabMenu">
              <el-table-column
                :key="index"
                :property="item.key"
                :fixed="index == 0"
                :label="item.name"
                :min-width="item.key == 'clientName' ? 270 : 110"
                :show-overflow-tooltip="true"
              >
                <template slot-scope="scope">
                  <div
                    v-if="item.key == 'clientName'"
                    class="hri link"
                    @click.stop="showClient(scope.row)"
                  >
                    <i
                      class="el-icon-notebook-2"
                      @click.stop="showGenjin(scope.row)"
                    ></i>
                    {{ scope.row[item.key] }}
                  </div>
                  <div v-else-if="item.key == 'services'" class="hri">
                    <!-- 服务项 -->
                    <div
                      v-for="(ddd, dd) in scope.row[item.key]"
                      :key="dd"
                      class="table-tip"
                      :style="{
                        borderColor: ddd.color,
                        color: ddd.color,
                      }"
                    >
                      <el-tooltip :content="ddd.tip" placement="top">
                        <div>{{ ddd.text }}</div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      item.key == 'serviceConsultant' || item.key == 'charge'
                    "
                    class="hri"
                  >
                    <!-- 服务顾问 -->
                    <div v-for="(ddd, dd) in scope.row[item.key]" :key="dd">
                      <span v-if="dd == 0">
                        {{ ddd }}
                      </span>
                      <span v-else>,{{ ddd }}</span>
                    </div>
                  </div>
                  <div v-else-if="item.key == 'signDepartment'" class="hri">
                    <!-- 签单人部门 -->
                    <div v-for="(ddd, dd) in scope.row[item.key]" :key="dd">
                      <span v-if="dd == 0">
                        {{ ddd.name }}
                      </span>
                      <span v-else>,{{ ddd.name }}</span>
                    </div>
                  </div>
                  <div v-else-if="item.key == 'autoRenew'">
                    <!-- 自动续签 -->
                    <span v-if="scope.row[item.key] == '1'">是</span>
                    <span v-if="scope.row[item.key] == '0'">否</span>
                  </div>
                  <div v-else-if="item.key == 'contractNumber'">
                    <!-- 自动续签 -->
                    <span
                      style="color: #15fc7b; margin: 0 2px"
                      v-if="
                        scope.row['oldContractId'] &&
                          scope.row['oldContractId'] != ''
                      "
                      >续</span
                    >{{ scope.row[item.key] }}
                  </div>
                  <div v-else>
                    {{ scope.row[item.key] }}
                  </div>
                </template>
              </el-table-column></template
            >

            <el-table-column fixed="right" label="操作" width="120">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.renewStatus == '未续签'"
                  @click.native.stop="handleClick(scope.row, 2)"
                  v-showFunction="{
                    active: 'renew',
                    array: allFunctionButtons,
                  }"
                  type="text"
                  size="small"
                  >续签</el-button
                >
                <el-button
                  v-if="scope.row.renewStatus == '未续签'"
                  @click.native.stop="handleClick(scope.row, 3)"
                  v-showFunction="{
                    active: 'end',
                    array: allFunctionButtons,
                  }"
                  type="text"
                  size="small"
                  >结束续签</el-button
                >
                <div
                  class="contract-table-card-content_text"
                  v-if="scope.row.renewStatus != '未续签'"
                >
                  {{ scope.row.renewStatus }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="contract-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 自定义列 -->
    <div id="my-dialog" v-if="tragerDialogData.flag">
      <div class="my-dialog-cc">
        <div class="my-dialog-title">
          <div class="my-dialog-title-text">{{ tragerDialogData.title }}</div>
          <div class="my-dialog-title-active"></div>
          <div class="my-dialog-title-close" @click="closeUpdateTableMenu">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="my-dialog-content">
          <new_drager
            :dragerData="tragerDialogData.data"
            @show="showDrager"
            @dragging="dragging"
            @closeUpdateTableMenu="closeUpdateTableMenu"
            @markSureUpdateTableMenu="markSureUpdateTableMenu"
          ></new_drager>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
        @choseProductTipOne="choseProductTipOne"
        @showRenew="showRenew"
        @buttonOper="drawerOper"
        @addFollowRecord="followRecord"
      ></my_dialog>
    </div>

    <!-- 右边抽屉 -->

    <div id="my-drawer" v-if="contractDrawerData.flag">
      <my_drawer
        :drawerData="contractDrawerData"
        :allFunctionButtons="allFunctionButtons"
        :model="'contractRenew'"
        @show="showDrawer"
        @closeUpdateTableDrawer="closeUpdateTableDrawer"
        @saveDrawerData="saveDrawerData"
        @drawerOper="drawerOper"
        @lineOpers="lineOper"
        @addTags="addTags"
        @linkNow="linkNow"
        @linkNowFile="linkNowFile"
        @showExpendDetails="showExpendDetails"
        @tagsHandleClose="tagsHandleClose"
        @addExpend="addExpend"
        @linkClitent="showClientByID"
        @showRelevanceClient="showRelevanceClient"
        @deleteRelevanceClient="deleteRelevanceClient"
        @addRelevanceClient="addRelevanceClient"
      ></my_drawer>
    </div>
  </div>
</template>

<script>
import drager from "@/components/drager.vue";
import my_drawer from "@/components/my-drawer.vue";
import my_dialog from "@/components/dialog.vue";
import contractDraw from "@/components/contractDraw";
import popo_search from "@/components/popo-search.vue";
import * as contractDrawData from "@/assets/js/contractDraw.js";
import * as clientInfoData from "@/assets/js/clientInfoData.js";
import * as allContractData from "./js/contractData.js";
import * as allClientData from "../client/js/clientData.js";
export default {
  components: {
    drager,
    contractDraw,
    my_drawer,
    popo_search,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      rowStyle: this.$rowStyle,
      loading: true,
      whetherAgencyHead: false,
      searchData: {
        input: "",
        checked: false,
        screenButton_add: 1, // 新增客户
        screenButton_improt: 1, // 导入
        screenButton_export: 1, // 导出
        end_the_manual: 1, // 手动结束
        screenList: [],
        department: "",
        departTreeData: [],
        tempUserId: "",
        userList: [],
        userIdList: [],
        lossReasonOptions: [],
      },
      popoData: {
        popoVisible: false,
        list: contractDrawData.contractInfoSearchData([], [], "", "", []),
      },
      tabindex: 0,
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tabMenu: contractDrawData.contractInfotableMenuData(),
      tableMenuData: {
        selectMenuData: [], // 真正显示的表头数据
        chooseableMenuData: [], // 隐藏的表头
      },
      tableData: [],
      tableButIndex: -1,
      tragerDialogData: {
        title: "自定义列",
        flag: false,
        data: {},
      },
      dialogData: {
        flag: false,
        width: "30%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      contractDrawerData: contractDrawData.contractInfoDrawerData(
        "",
        [],
        [],
        {
          text: "",
          active: "",
        },
        []
      ),
      tempTags: [],
      tempContract: { id: "", name: "" }, // 临时合同
      tempClient: {},
      buttonAuth: [],
      tempServices: [], // 该合同所包含的所有服务
      productList: [], // 所有合同模板/产品数据
      professionList: [], // 所有合同执行模板
      serviceItemList: [], // 所有服务项数据
      employeeList: [], // 所有员工
      departmentList: [], // 所有员工
      clientList: [], // 所有客户
      tipsData: {
        clientTipsList: [], // 客户标签
        pianquList: [], // 片区标签
        levelList: [], // 客户等级
        sourceList: [], // 客户来源
      },

      tempExpenses: "0", // 续签合同时暂存的合同费用
      ttempClient: {},

      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "contractRenew"
      ),
      renewAllFunctionButtons: this.$featureData.getAllFunctionButtons(
        "contractRenew"
      ),
      contractAllFunctionButtons: this.$featureData.getAllFunctionButtons(
        "contractInfo"
      ),
      contractDrawerOptions: this.$tagData.getDrawerOptionsList("contractInfo"), // 抽屉中的下拉框配置项
      contractDrawerAuthorityData: [],
      contractDrawerDataAuthorityList: [],

      clientAllFunctionButtons: this.$featureData.getAllFunctionButtons(
        "clientInfo"
      ),
      clientDrawerOptions: this.$tagData.getDrawerOptionsList("clientInfo"), // 客户抽屉中的下拉框配置项
      clientDrawerAuthorityData: [],
      clientDrawerDataAuthorityList: [],
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "contractRenew",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          this.renewAllFunctionButtons = data;
          this.getContractModelFeatureAuthority();
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    getContractModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "contractInfo",
        this.contractAllFunctionButtons,
        (data) => {
          this.contractAllFunctionButtons = data;
          this.getClientModelFeatureAuthority();
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    // 获取客户的按钮权限
    getClientModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "clientInfo",
        this.clientAllFunctionButtons,
        (data) => {
          this.clientAllFunctionButtons = data;
          // this.isCharge();
          console.log("当前显示的按钮权限", data);
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getDepartmentTreeByUserId();
        this.getTagList();
        this.initMenu();
      });
    },
    initMenu() {
      this.$featureData.getAttributeData(
        "contractRenew",
        true,
        [],
        (attributeData) => {
          this.$menuData.getModelMenuAndAuthority(
            "contractRenew",
            attributeData,
            this,
            (data) => {
              this.tabMenu = data;
              console.log(this.tabMenu);
              this.tragerDialogData.data = JSON.parse(
                JSON.stringify(this.tabMenu)
              );
              this.getEmployeeList();

              this.$tagData.getTagList(
                this.contractDrawerOptions,
                ["客户标签", "片区", "客户来源", "客户等级", "增值税类型"],

                this.baseInfo.company.companyId,
                this
              );
              this.$tagData.getTagList(
                this.clientDrawerOptions,
                ["片区", "客户来源", "增值税类型", "客户等级", "客户标签"],
                this.baseInfo.company.companyId,
                this
              );
            }
          );
        }
      );
    },
    // 获取当前所需标签
    getTagList() {
      let requestData = {
        type: ["流失原因"],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          console.log("当前获取到的标签组:", data);
          for (let index = 0; index < data.length; index++) {
            const element = data[index];

            if (element.type == "流失原因") {
              this.searchData.lossReasonOptions = element.value;
            }
          }
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有员工列表
     */
    getEmployeeList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.employeeList = [];
          this.searchData.userList = [];
          data.forEach((element) => {
            let dd = {
              label: element.userName,
              value: element.userId,
            };
            let tt = {
              name: element.userName,
              id: element.userId,
            };
            this.searchData.userIdList.push(element.userId);
            this.searchData.userList.push(tt);
            this.employeeList.push(dd);
          });
          this.searchData.userList.splice(0, 0, {
            id: "all",
            name: "全部人员",
          });
          this.contractDrawerOptions.allUser = this.employeeList;
          this.clientDrawerOptions.allUser = this.employeeList;
          this.searchData.userId = this.baseInfo.userId;
          this.selectInitTableData(this.baseInfo.userId);
          this.getDepartmentList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有部门列表
     */
    getDepartmentList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/department/getDepartmentList",
        requestData,
        (data) => {
          this.departmentList = [];
          data.forEach((element) => {
            let dd = {
              label: element.departmentName,
              value: element.departmentId,
            };
            this.departmentList.push(dd);
          });
          this.getAllProductList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取部门
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同模板/产品列表
     */
    getAllProductList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        name: "", // 服务产品名称 模糊查询
        type: "",
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
      };
      this.$request(
        "post",
        "/product/getAllProduct",
        requestData,
        (data) => {
          this.productList = data.tableData;

          this.contractDrawerOptions.productList = [];
          data.tableData.forEach((element) => {
            let dd = {
              label: element.name,
              value: element.id,
            };
            this.contractDrawerOptions.productList.push(dd);
          });
          this.getServicesItem();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有服务项列表
     */
    getServicesItem() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        status: "1",
        name: "", // 服务产品名称 模糊查询
        type: "",
      };
      this.$request(
        "post",
        "/servicesItem/getServicesItem",
        requestData,
        (data) => {
          this.serviceItemList = data;
          this.contractDrawerOptions.serviceList = [];
          data.forEach((element) => {
            let dd = {
              label: element.name,
              value: element.id,
            };
            this.contractDrawerOptions.serviceList.push(dd);
          });
          this.getAllClientByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有客户列表
     */
    getAllClientByUserId() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        type: "全部客户", // 与业务角色对应(对应关系暂定 20210618)
        hasLeave: "0", // 是否包含流失,"0"为否,"1"为是
        currentPage: 1, // 当前页数
        pageSize: 1000, // 每页条数
        search: "",
      };
      this.$request(
        "post",
        "/client/getAllClientByCompany",
        requestData,
        (data) => {
          // this.clientList = data.tableData;
          this.clientList = [];
          data.forEach((element) => {
            let dd = {
              id: element.clientId,
              clientName: element.clientName,
            };
            this.clientList.push(dd);
          });
          this.getProfessionList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有合同执行列表
     */
    getProfessionList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        departmentId: "all",
      };
      this.$request(
        "post",
        "/profession/getProfessionList",
        requestData,
        (data) => {
          this.professionList = data;
          this.initTableData();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },

    /**
     * 初始化表格数据
     */
    initTableData() {
      this.tableData = [];
      this.loading = true;
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId, // 当前公司ID
        status: this.tabindex, // 合同状态:"0"->全部合同;"1"->待合同审核合同;"2"->待财务审核合同
        currentPage: this.pageData.currentPage, // 当前页数
        haveRenew: this.searchData.checked, // 是否包含续签
        pageSize: this.pageData.pageSize, // 每页条数
        search: this.searchData.input, // 客户全称/编号/合同编号筛选客户
        userIdList: this.searchData.tempUserId, // 员工id集合
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
      };
      this.$request(
        "post",
        "/contract/getAllRenewContract",
        requestData,
        (data) => {
          this.pageData.total = data.total; // 获取总条目数
          this.contractBase = data.baseData;
          // this.tableData = data.tableData;
          this.tableData = contractDrawData.getInitTableData(
            data.tableData,
            this.tableMenuData.selectMenuData,
            this
          );
          this.$nextTick(() => {
            this.$refs.singleTable.doLayout();
          });
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    /**
     * 筛选弹出框显示
     */
    popoverOpen() {
      let productList = []; // 服务产品
      let serviceItemList = []; // 服务项目
      let companyData = {
        employeeList: this.employeeList, // 员工列表
        departmentList: this.departmentList, // 部门列表
      };
      let industryTypeList = []; // 行业分类
      let tipsData = {
        clientTipsList: this.tipsData.clientTipsList, // 客户标签
        pianquList: this.tipsData.pianquList, // 片区标签
        clientLevelList: this.tipsData.levelList, // 客户等级
        customerSourceList: this.tipsData.sourceList, // 客户来源
      };
      this.productList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        productList.push(dd);
      });
      this.serviceItemList.forEach((element) => {
        let dd = {
          label: element.name,
          value: element.id,
        };
        serviceItemList.push(dd);
      });

      this.popoData.list = contractDrawData.contractInfoSearchData(
        productList,
        serviceItemList,
        companyData,
        tipsData,
        industryTypeList
      );
      this.popoData.popoVisible = !this.popoData.popoVisible;
    },
    /**
     * 筛选弹出框确认事件
     */
    popoMakeSure() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        state: "0",
        list: this.$systemBaseData.makesureSearchData(this.popoData.list),
      };
      this.loading = true;
      this.tableData = [];
      console.log("确认筛选数据:", requestData);
      this.$request(
        "post",
        "/contract/filtrateContract",
        requestData,
        (data) => {
          this.tabindex = "0"; // 默认在全部商机中查找
          this.pageData.total = data.total; // 获取总条目数
          this.tableData = contractDrawData.getInitTableData(
            data.tableData,
            this.tableMenuData.selectMenuData,
            this
          );
          this.loading = false;
          this.popoCanael();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          // this.loading = false;
        }
      );
    },
    /**
     * 筛选弹出框重置
     */
    popoReset() {
      this.$systemBaseData.popoReset(this.popoData.list);
    },
    selectAllRequire(val) {
      this.initTableData();
    },
    /**
     * tab切换事件
     * index: "0"->全部合同;"1"->将到期合同;"2"->已到期合同
     */
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    /**
     * 表格界面按钮操作事件
     */
    handleClick(data, flag) {
      console.log("dddddddd", data);
      if (!this.popoData.popoVisible) {
        switch (flag) {
          case 0:
            // 新增合同
            if (this.searchData.screenButton_add == 2) {
              this.$message.error(
                this.$errorMessage.getErrorMessageInfo("authority")
              );
              return;
            }
            break;
          case 1:
            // 导出合同
            if (this.searchData.screenButton_export == 2) {
              this.$message.error(
                this.$errorMessage.getErrorMessageInfo("authority")
              );
              return;
            }
            break;
          case 2:
            // 合同续签
            this.tempContract = data;
            this.tempContract.name = data.clientName;
            this.dialogData.title = "续签合同";
            this.dialogData.width = "40%";
            this.dialogData.buttonArray = [];
            this.dialogData.buttonArray.push(
              {
                text: "确定",
                active: "sure_renew",
              },
              {
                text: "取消",
                active: "cancel",
              }
            );
            // let time1 = "1900-01-01";
            // if (data.dateDue != "") {
            //   let yy = data.dateDue.split("-");
            //   time1 = parseInt(yy[0]) + 1 + "-" + yy[1] + "-" + yy[2];
            // }
            this.dialogData.data = [
              {
                type: "renew",
                title: "",
                value: {
                  flag: "0",
                  time: data.dateDue,
                  cost: "",
                  year: "1",
                  month: "0",
                },
              },
            ];
            this.dialogData.flag = true;
            break;
          case 3:
            // 手动结束续签
            this.$confirm(
              "手动结束续签后,该合同将无法继续续签, 是否继续?",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$request(
                  "post",
                  "/contract/handOverToRenew",
                  { id: data.id },
                  (res) => {
                    this.$message({
                      type: "success",
                      message: "操作成功!",
                      duration: 1000,
                    });
                    this.$operationRecords.saveOperationRecords(
                      data.id,
                      "手动结束续签!",
                      this.baseInfo,
                      "contract",
                      this,
                      (data2) => {
                        this.initTableData();
                      }
                    );
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                    this.dialogData.flag = false;
                  }
                );
              })
              .catch(() => {});
            break;
          default:
            break;
        }
      } else {
        this.popoCanael();
      }
    },
    /**
     * 员工选择
     */
    selectInitTableData(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.searchData.userIdList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    /**
     * 表格单行点击事件
     */
    rowClick(row, column, event) {
      // if (!this.contractDrawerData.flag) {
      //   //防止重复点击
      //   this.tempContract.id = row.id;
      //   this.tempContract.name = row.clientName;
      //   this.showContractInfoByID();
      //   this.contractDrawerData.flag = true;
      //   console.log("打开抽屉:", this.contractDrawerData);
      // } else {
      //   this.closeUpdateTableDrawer();
      // }
    },
    /**
     * 获取合同信息具体数据
     */
    showContractInfoByID() {
      // 1.拿到合同的按钮权限
      this.allFunctionButtons = JSON.parse(
        JSON.stringify(this.contractAllFunctionButtons)
      );
      setTimeout(() => {
        this.$featureData.getAttributeData(
          "contractInfo",
          false,
          allContractData.returnDrawerKeys("合同信息"),
          (authorityData) => {
            this.contractDrawerAuthorityData = authorityData;
            // 2.拿对应属性的权限
            this.$authority.getModelFeatureAuthority(
              this,
              "contractInfo",
              authorityData,
              (authorityList) => {
                this.contractDrawerDataAuthorityList = authorityList;
                // this.showContractInfoByID();
                let requestData = {
                  contractId: this.tempContract.id,
                  userId: this.baseInfo.userId,
                  companyId: this.baseInfo.company.companyId,
                  authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
                    this.contractDrawerAuthorityData
                  ),
                };
                this.$request(
                  "post",
                  "/contract/showContractByID",
                  requestData,
                  (data) => {
                    // 3.将权限数据对应到界面
                    let butText = {
                      text: "提交",
                      active: "submit",
                    };
                    if (
                      data.data.status == "待提交" ||
                      data.data.status == "合同审核不通过"
                    ) {
                      butText = {
                        text: "提交",
                        active: "submit",
                        key: "cancel",
                      };
                    } else {
                      butText = {
                        text: "电子合同生成",
                        active: "electronic_contract",
                        key: "electronicContract",
                      };
                    }
                    this.contractDrawerData = allContractData.contractInfoDrawerData(
                      this.tempClientName,
                      this.contractDrawerDataAuthorityList,
                      this.whetherAgencyHead, // 是否是机构负责人
                      this.contractDrawerOptions,
                      "合同信息",
                      butText
                    );
                    // this.tempSign = data.data.sign;
                    this.contractDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                      this.contractDrawerData,
                      data.data
                    );
                    allContractData.initData(
                      data,
                      this.contractDrawerData,
                      this.allFunctionButtons,
                      this.whetherAgencyHead
                    );
                    this.contractDrawerData.status = this.whetherAgencyHead
                      ? ""
                      : data.data.status;
                    console.log(
                      "打开抽屉:",
                      this.contractDrawerData,
                      this.contractDrawerOptions
                    );

                    this.contractDrawerData.flag = true;
                  },
                  (errorMessage) => {
                    this.$message.error(errorMessage);
                  }
                );
                // this.contractDrawerData.flag = true;
              }
            );
          }
        );
      }, 0);
    },

    /**
     * 点击客户
     */
    showClient(row) {
      this.tempContract.id = row.id;
      this.tempContract.name = row.clientName;
      this.tempClientName = row.clientName;
      this.tempClient.value = row.clientId;
      this.ttempClient = row;
      this.showClientByID();
    },
    /**
     * 根据客户ID,查看客户信息
     */
    showClientByID() {
      this.allFunctionButtons = JSON.parse(
        JSON.stringify(this.clientAllFunctionButtons)
      );
      this.$featureData.getAttributeData(
        "clientInfo",
        false,
        allClientData.returnDrawerKeys("客户信息"),
        (authorityData) => {
          this.clientDrawerAuthorityData = authorityData;
          // 2.拿对应属性的权限
          this.$authority.getModelFeatureAuthority(
            this,
            "clientInfo",
            authorityData,
            (authorityList) => {
              this.clientDrawerDataAuthorityList = authorityList;
              // 4.拿具体的值
              let requestData = {
                clientId: this.tempClient.value,
                userId: this.baseInfo.userId,
                companyId: this.baseInfo.company.companyId,
                authorityData: this.$initDrawerAuthorityData.getKeysByAuthorityList(
                  this.clientDrawerAuthorityData
                ), // 客户的具体模块权限
              };
              this.$request(
                "post",
                "/client/showClientInfoByID",
                requestData,
                (data) => {
                  // 3.将权限数据对应到界面
                  this.contractDrawerData = allClientData.clientInfoDrawerData(
                    this.tempClientName,
                    this.clientDrawerDataAuthorityList,
                    this.whetherAgencyHead, // 是否是机构负责人
                    this.clientDrawerOptions,
                    "客户信息"
                  );
                  // 5.将具体的值匹配到界面
                  if (data.data.source.length > 0) {
                    data.data.source = data.data.source[0];
                  } else {
                    data.data.source = "";
                  }
                  // 单独处理所在地区数据
                  // if (data.data.area.length == 0) {
                  //   data.data.area = ["","",""]
                  // }
                  this.contractDrawerData = this.$initDrawerAuthorityData.initDrawerData(
                    this.contractDrawerData,
                    data.data
                  );
                  console.log(
                    "处理业务角色前的抽屉数据",
                    this.contractDrawerData
                  );
                  // 单独处理业务角色
                  let isShow = this.contractDrawerData.data.modelList[3].line[0]
                    .isShow;
                  let isDisable = this.contractDrawerData.data.modelList[3]
                    .line[0].isDisable;
                  this.contractDrawerData.data.modelList[3].line = [];
                  let userList = [];

                  this.clientDrawerOptions.allUser.forEach((element) => {
                    let dd = {
                      id: element.value,
                      name: element.label,
                    };
                    userList.push(dd);
                  });
                  for (let i = 0; i < data.data.role.length; i++) {
                    let element = data.data.role[i];
                    let line = {
                      id: element.id,
                      horizontal: true,
                      isShow: isShow,
                      isDisable: isDisable,
                      isSelect: false,
                      tempValue: "",
                      title: element.title,
                      key: "role",
                      type: "tags-select-obj",
                      value: element.value,
                      options: userList,
                    };
                    this.contractDrawerData.data.modelList[3].line.push(line);
                  }

                  this.contractDrawerData.data.customer = data.relevanceClient;
                  this.contractDrawerData.flag = true;
                }
              );
            }
          );
        }
      );
    },
    /**
     * 查看客户的关联客户
     */
    showRelevanceClient() {
      let requestData = {
        userId: this.baseInfo.userId,
        clientId: this.tempClient.value,
      };
      this.$request(
        "post",
        "/client/getAllRelevanceClientListByClientId",
        requestData,
        (data) => {
          this.contractDrawerData.data.gridData = data;
          this.contractDrawerData.data.customer = data.length;
        }
      );
    },
    /**
     * 手动解除关联客户
     */
    deleteRelevanceClient(id) {
      console.log("手动解除关联客户");
      let requestData = {
        clientId: this.tempClient.value,
        relevanceClientId: id,
      };
      this.$request(
        "post",
        "/client/removeRelevanceClientByClientId",
        requestData,
        (data) => {
          this.$message({
            message: "关联客户解除成功!",
            type: "success",
          });
          this.showRelevanceClient();
        }
      );
    },
    /**
     * 手动添加关联客户
     */
    addRelevanceClient() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
        clientId: this.tempClient.value,
      };
      this.$request(
        "post",
        "/client/getNoAssociationClient",
        requestData,
        (data) => {
          let options = [];
          data.forEach((element) => {
            let dd = {
              label: element.clientName,
              value: element.clientId,
            };
            options.push(dd);
          });
          this.dialogData.title = "手动添加关联客户";
          this.dialogData.width = "40%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "makesure_relevance",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );
          this.dialogData.data = [
            {
              type: "select-object-collapse",
              title: "",
              value: [],
              options: options,
              placeholder: "选择客户",
            },
          ];
          this.dialogData.flag = true;
        }
      );
    },
    /**
     * 确定手动关联客户
     */
    makesureRelevance() {
      let list = [];
      this.dialogData.data[0].value.forEach((element) => {
        list.push(element.value);
      });
      let requestData = {
        clientId: this.tempClient.value, // 当前客户
        relevanceClient: list, // 要关联的客户数组
      };
      this.$request(
        "post",
        "/client/setRelevanceClientByClientId",
        requestData,
        (data) => {
          this.dialogData.flag = false;
          this.$message({
            message: "手动关联客户成功!",
            type: "success",
          });
          this.showRelevanceClient();
        }
      );
    },
    /**
     * 根据客户ID,查看客户联系人
     */
    showClientContactDataByID() {
      let requestData = {
        clientId: this.tempClient.value,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/showClientContactByID",
        requestData,
        (data) => {
          this.contractDrawerData = allClientData.getDrawerData(
            this.tempClientName,
            this.clientDrawerOptions,
            "联系人"
          );
          // 单独处理数据
          let itemData = JSON.parse(
            JSON.stringify(this.contractDrawerData.data.modelList[0])
          );
          if (data.length > 0) {
            this.contractDrawerData.data.modelList = [];
          }
          for (let index = 0; index < data.length; index++) {
            let element = data[index];
            let item_data = JSON.parse(JSON.stringify(itemData));
            for (let i = 0; i < item_data.line.length; i++) {
              let ele = item_data.line[i];
              ele.value = element[ele.key1];
            }
            this.contractDrawerData.data.modelList.push(item_data);
          }
          this.contractDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 显示支出详情
     */
    showExpendDetails(data) {
      console.log("显示支出详情:", data, this.tempContract.name);
      let dd = {
        client: this.tempContract.name,
        contractNum: this.tempContract.name,
        contractId: this.tempContract.id,
        expendStatus: data.expendStatus,
        expendNum: data.expendNum,
        time: data.time,
        expendType: data.expendType,
        user: data.user,
        title: data.title,
        file: data.file, // 附件
        services: data.services,
      };
      this.dialogData.title = "支出";
      this.dialogData.width = "40%";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel_expend",
      });
      this.dialogData.data = [
        {
          type: "expend_details",
          title: "",
          value: dd,
        },
      ];
      this.dialogData.flag = true;
    },
    /**
     * 显示合同列表
     */
    getAllContractByClientId() {
      let requestData = {
        clientId: this.tempClient.value,
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        "/contract/getAllContractByClientId",
        requestData,
        (data) => {
          this.contractDrawerData = allClientData.getDrawerData(
            this.tempClientName,
            this.clientDrawerOptions,
            "合同"
          );
          // 单独处理表格数据
          this.contractDrawerData.data.modelList[0].line[0].tableData = data;
          this.contractDrawerData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     *右击改变表头显示与否事件
     */
    changeStatus(data) {
      if (data.index == 0) {
        data.item.isDisable = true;
        this.tragerDialogData.data.selectMenuData.splice(
          this.tragerDialogData.data.selectMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.chooseableMenuData.push(data.item);
      } else {
        data.item.isDisable = false;
        this.tragerDialogData.data.chooseableMenuData.splice(
          this.tragerDialogData.data.chooseableMenuData.indexOf(data.item),
          1
        );
        this.tragerDialogData.data.selectMenuData.push(data.item);
      }
    },
    /**
     * 确定修改表头数据
     */
    markSureUpdateTableMenu() {
      console.log("确定修改表头数据:", resultData);
      this.tabMenu = JSON.parse(JSON.stringify(resultData));
      this.tragerDialogData.flag = false;
      this.initTableData();
      this.$menuData.saveTableMenuData(resultData, "contractRenew", this);
    },
    /**
     * 修改表头顺序dialog打开
     */
    updateTableMenu() {
      this.tragerDialogData.flag = true;
    },

    /**
     * 抽屉中tag移除事件
     * data(tag,i,j):tag->移除的tag,i为第几个model,j为第几line
     */
    tagsHandleClose(data) {
      this.contractDrawerData.data.modelList[data.i].line[data.j].value.splice(
        this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value.indexOf(data.tag),
        1
      );
      this.saveDrawerData(
        this.contractDrawerData.data.modelList[data.i].line[data.j].title
      );
    },
    addTags(data) {
      if (this.contractDrawerData.title == "合同") {
        this.tempTags = this.contractDrawerData.data.modelList[data.i].line[
          data.j
        ].value;
        let v = "2";
        this.tempTags.push(v);
        this.saveDrawerData("合同信息");
      }
      if (this.contractDrawerData.title == "客户") {
        if (
          this.contractDrawerData.data.modelList[data.i].line[data.j].isSelect
        ) {
          let value = this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue;
          if (
            value &&
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.indexOf(value) < 0
          ) {
            // 排除重复选项
            this.contractDrawerData.data.modelList[data.i].line[
              data.j
            ].value.push(value);
            this.saveDrawerData("角色信息");
          }
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = false;
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].tempValue = "";
        } else {
          this.contractDrawerData.data.modelList[data.i].line[
            data.j
          ].isSelect = true;
        }
      }
    },
    /**
     * 抽屉组件中按钮操作事件(主要是显示弹出框事件)
     */
    drawerOper(active) {
      let dd = {};
      let requestData = {};
      switch (active) {
        case "info":
          // 合同信息
          if (this.contractDrawerData.title == "客户") {
            this.showClientByID();
          }
          if (this.contractDrawerData.title == "合同") {
            this.showContractInfoByID();
          }
          break;
        case "end_the_manual":
          // 手动结束合同
          this.$confirm("该合同未到期或未全部回款, 是否继续结束?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/contract/setContractFinish",
                { contractId: this.tempContract.id },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                    duration: 1000,
                  });
                  this.contractDrawerData.flag = false;
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "手动结束合同!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.initTableData();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                  this.dialogData.flag = false;
                }
              );
            })
            .catch(() => {});
          break;
        case "huikuan":
          // 回款计划
          this.getReceiptRecord();

          break;
        case "shoufei":
          // 收费情况
          this.getCharge();
          break;
        case "zhichu":
          // 合同支出
          this.getExpendByContractId();
          break;
        case "fujian":
          // 合同附件
          this.getAttachmentByContractId();
          break;
        case "add_free_serves":
          // 添加赠送的服务项
          for (
            let index = 0;
            index < this.contractDrawerData.data.modelList.length;
            index++
          ) {
            let element = this.contractDrawerData.data.modelList[index];
            for (let i = 0; i < element.line.length; i++) {
              let ele = element.line[i];
              if (ele.key == "freeServes") {
                if (!ele.isDisable) {
                  console.log("当前赠送服务项:", ele);
                  dd = {
                    isEdit: true, // 是否是编辑状态
                    flag: true, // 是否是新增
                    serviceItem: {}, //服务项
                    serviceTime: "", // 服务时间
                  };
                  ele.value.push(dd);
                } else {
                  this.$message({
                    message: "当前合同状态不可编辑!",
                    duration: 1000,
                    type: "error",
                  });
                }
              }
            }
          }
          break;
        case "create-contract":
          // 新建合同
          this.dialogData.title = "选择产品";
          this.dialogData.width = "50%";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "new-contract",
              title: "",
              value: { clientName: this.tempContract.clientName },
            },
          ];
          this.dialogData.flag = true;
          break;
        case "renew":
          // 续签
          this.handleClick(this.tempContract, 2);
          break;
        case "check":
          // 合同审核
          this.dialogData.title = "审核意见";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_check",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [
            {
              type: "radio",
              title: "审核状态",
              value: "1",
              options: [
                {
                  label: "通过",
                  value: "1",
                },
                {
                  label: "不通过",
                  value: "2",
                },
              ],
            },
            {
              type: "input",
              title: "审核意见",
              value: "",
            },
          ];
          this.dialogData.flag = true;

          break;
        case "makesure_check":
          // 提交审核意见
          requestData = {
            userId: this.baseInfo.userId,
            id: this.tempContract.id,
            flag: this.dialogData.data[0].value,
            suggest: this.dialogData.data[1].value, // 合同模板ID
          };
          this.$request(
            "post",
            "/contract/reviewContract",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "操作成功!",
                duration: 1000,
                type: "success",
              });

              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                requestData.flag == "1" ? "合同审核通过!" : "合同审核不通过!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "sure_renew":
          // 确定续签
          switch (this.dialogData.data[0].value.flag) {
            case 1:
            case "1":
              // 延长当前合同有效期
              // let tt =
              //   parseInt(this.dialogData.data[0].value.year) * 12 +
              //   parseInt(this.dialogData.data[0].value.month);
              requestData = {
                contractId: this.tempContract.id, // 原合同ID
                dueToTime: this.dialogData.data[0].value.time, // 原合同到期时间
                expenses: this.dialogData.data[0].value.cost, // 原合同所剩费用
              };
              this.tempExpenses = this.dialogData.data[0].value.cost;
              this.$request(
                "post",
                "/contract/updateRenewContract",
                requestData,
                (data) => {
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "新建续签合同!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.drawerOper("create-contract");
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
              break;
            case "0":
            case 0:
              // 新建续签合同
              // this.drawerOper("create-contract");
              requestData = {
                contractId: this.tempContract.id, // 原合同ID
                dueToTime:
                  parseInt(this.dialogData.data[0].value.year) * 12 +
                  parseInt(this.dialogData.data[0].value.month), // 续签期限
                userId: this.baseInfo.userId,
              };

              this.$request(
                "post",
                "/contract/updateRenewContractByCopyOldContract",
                requestData,
                (data) => {
                  this.tempContract.id = data.id;
                  // this.drawerOper("create-contract");
                  // this.initTableData();
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "合同续签!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.showContractInfoByID();
                      this.dialogData.flag = false;
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
              break;

            default:
              break;
          }
          break;
        case "expend_submit":
          // 合同支出提交
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.$formatTime(new Date(), "YYYY-MM-DD HH:mm:ss"), // 确认支出时间  当状态为完成时自动填充
            title: this.dialogData.data[0].value.title, //收款账户
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
            user: this.baseInfo.userId,
          };
          if (requestData.title == "") {
            this.$message.error("支出标题不能为空!");
            return;
          }
          if (requestData.file == "") {
            this.$message.error("支出附件不能为空!");
            return;
          }
          if (requestData.services[0].expendMoney == "") {
            this.$message.error("支出金额不能为空!");
            return;
          }
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "支出提交!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.getExpendByContractId();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "expend_echeck":
          // 合同支出审核
          requestData = {
            contractId: this.tempContract.id, // 合同ID
            expendNum: this.dialogData.data[0].value.expendNum, // 支出编号
            expendType: this.dialogData.data[0].value.expendType, // 支出类型
            expendStatus: "完成", // 支出状态  待提交  待审核  完成  审核不通过
            time: this.dialogData.data[0].value.time, // 确认支出时间  当状态为完成时自动填充
            receiptAccount: this.dialogData.data[0].value.receiptAccount, //收款账户
            receiptName: this.dialogData.data[0].value.receiptName, // 收款人姓名
            blank: this.dialogData.data[0].value.blank, // 开户银行  如徽商银行等
            des: this.dialogData.data[0].value.des, // 支出备注
            file: this.dialogData.data[0].value.file, // 附件
            services: this.dialogData.data[0].value.services,
          };
          this.$request(
            "post",
            "/contract/updateExpend",
            requestData,
            (data) => {
              this.$message({
                message: "操作成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "支出审核!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.getExpendByContractId();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "delete_contract":
          // 删除合同
          this.$request(
            "post",
            "/contract/beforeDelContract",
            { contractId: this.tempContract.id },
            (data) => {
              if (data.flag) {
                // 正常删除  当前合同没有收款记录
                this.deleteContract();
              } else {
                // 有收款记录,提示删除
                this.$confirm("该合同已有收款记录,是否继续删除?", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    this.deleteContract();
                  })
                  .catch(() => {});
              }
            }
          );
          break;

        /**
         * 客户抽屉操作
         */
        case "hetong":
          this.getAllContractByClientId();
          break;
        case "lianxiren":
          this.showClientContactDataByID();
          break;

        case "newContact":
          let temp = {
            line: [
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "姓名",
                type: "input-but",
                value: "",
                buts: [
                  {
                    text: "公开",
                    active: "gongkai",
                  },
                  {
                    text: "删除",
                    active: "shanchu",
                  },
                ],
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "职位",
                type: "select",
                value: "老板",
                options: [
                  {
                    label: "老板",
                    value: "老板",
                  },
                  {
                    label: "法人",
                    value: "法人",
                  },
                  {
                    label: "股东",
                    value: "股东",
                  },
                  {
                    label: "会计",
                    value: "会计",
                  },
                  {
                    label: "其他",
                    value: "其他",
                  },
                ],
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "电话",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "手机",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "邮件",
                type: "input",
                value: "",
              },
              {
                horizontal: true,
                isShow: true,
                isDisable: false,
                title: "备注",
                type: "input",
                value: "",
              },
            ],
          };
          this.contractDrawerData.data.modelList.push(temp);
          break;
        case "create_contract":
          // 新建合同
          this.drawerOper("create-contract");
          break;
        case "delete_client":
          // 删除客户
          this.dialogData.title = "提示";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push({
            text: "确定",
            active: "makesure_delete_client",
          });
          this.dialogData.buttonArray.push({
            text: "取消",
            active: "cancel",
          });
          this.dialogData.data = [];
          this.dialogData.data.push({
            type: "reminder",
            text: "删除,同时删除其对应商机",
            value: this.tempClientName,
          });
          this.dialogData.flag = true;
          break;
        case "makesure_delete_client":
          // 确定删除
          requestData = {
            clientId: this.tempClient.value,
            status: 0,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/deleteClientStatus",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "客户删除成功,删除的客户可在客户回收站中还原!",
                duration: 1000,
                type: "success",
              });

              this.$operationRecords.saveOperationRecords(
                this.tempClient.value,
                "删除客户!",
                this.baseInfo,
                "client",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "run_off":
          // 申请代账流失
          this.toApplyForCustomerChurn();
          break;
        case "confirm_that_client_loss":
          // 确定客户流失
          dd = {
            typeName: this.dialogData.data[0].value.label, // 服务类型名称
            id: this.dialogData.data[0].value.value, // 服务类型ID
          };
          requestData = {
            clientId: this.tempClient.value,
            serviceType: dd,
            lossReason: this.dialogData.data[1].value,
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
          };
          this.$request(
            "post",
            "/client/clientChurnByClientId",
            requestData,
            (data) => {
              this.dialogData.flag = false;
              this.contractDrawerData.flag = false;
              this.$message({
                message: "成功申请客户流失!",
                duration: 1000,
                type: "success",
              });

              this.$operationRecords.saveOperationRecords(
                this.tempClient.value,
                "申请客户流失!",
                this.baseInfo,
                "client",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;

        case "pause_serve":
          // 暂停代账服务

          break;
        case "zhuanweifeidaizhang":
          // 转为非代账
          break;
        case "get_companyInfo":
          // 获取工商信息
          this.getCompanyInfo();
          break;
        case "genjin":
          // 添加跟进记录
          this.showGenjin(this.ttempClient);
          break;
        case "operating_record":
          // 查看客户的操作记录
          this.$operationRecords.showOperationRecords(
            this.tempClient.value,
            "client",
            this
          );
          break;
        case "operating_record_contract":
          // 查看合同的操作记录
          this.$operationRecords.showOperationRecords(
            this.tempContract.id,
            "contract",
            this
          );
          break;
        case "cancel":
          // 取消
          this.dialogData.flag = false;
          this.contractDrawerData.flag = false;
          break;

        case "makesure_relevance":
          // 确定手动添加关联客户
          this.makesureRelevance();
          break;
        case "adding_associated_customer":
          // 手动添加关联客户
          this.addRelevanceClient();
          break;
        case "cancel_expend":
          this.dialogData.flag = false;
          this.getExpendByContractId();
          break;
        default:
          break;
      }
    },
    /**
     * 获取收费情况
     */
    getCharge() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getCharge",
        requestData,
        (data) => {
          this.contractDrawerData = allContractData.contractInfoDrawerData(
            this.tempClientName,
            this.contractDrawerDataAuthorityList,
            this.whetherAgencyHead, // 是否是机构负责人
            this.contractDrawerOptions,
            "收费情况",
            {}
          );
          this.contractDrawerData.data.modelList[0].line[0].tableData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取收款记录
     */
    getReceiptRecord() {
      console.log("获取回款记录");
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getReceiptRecord",
        requestData,
        (data) => {
          this.contractDrawerData = allContractData.contractInfoDrawerData(
            this.tempClientName,
            this.contractDrawerDataAuthorityList,
            this.whetherAgencyHead, // 是否是机构负责人
            this.contractDrawerOptions,
            "收款记录",
            {}
          );
          this.contractDrawerData.data.modelList[0].line[0].tableData = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 查看具体附件内容
     */
    linkNowFile(data) {
      if (data.item.fileType == "img") {
        // 附件是图片
        this.dialogData.title = "查看收款附件";
        this.dialogData.width = "30%";
        this.dialogData.data = [
          {
            type: "show_img",
            value: data.item.file,
            title: "",
          },
        ];
        this.dialogData.buttonArray = [];
        this.dialogData.flag = true;
      } else if (data.item.file != "") {
        // 附件是文件
        // window.open(data.item.file, "_blank");

        if (this.$keyType.keyType(data.item.file) == "Object") {
          window.open(data.item.file.path, "_blank");
        } else if (this.$keyType.keyType(data.item.file) == "String") {
          window.open(data.item.file, "_blank");
        } else {
          this.$message.error("附件格式错误,请联系管理员!");
        }
      }
    },
    /**
     * 合同删除
     */
    deleteContract() {
      let requestData = {
        contractId: this.tempContract.id,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$confirm("此操作将永久删除该合同, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/contract/deleteContract",
            requestData,
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功",
                duration: 1000,
              });
              this.contractDrawerData.flag = false;
              this.$operationRecords.saveOperationRecords(
                this.tempContract.id,
                "删除合同!",
                this.baseInfo,
                "contract",
                this,
                (data2) => {
                  this.initTableData();
                }
              );
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    linkNow(dd) {
      this.tempContract.id = dd.item.id;
      // 1.拿合同界面的权限
      this.getModelFeatureAuthority();
      // 2.展示合同
      this.showContractInfoByID();
    },
    /**
     * 获取合同支出情况
     */
    getExpendByContractId() {
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getExpendByContractId",
        requestData,
        (data) => {
          this.contractDrawerData = allContractData.contractInfoDrawerData(
            this.tempClientName,
            this.contractDrawerDataAuthorityList,
            this.whetherAgencyHead, // 是否是机构负责人
            this.contractDrawerOptions,
            "合同支出",
            {}
          );
          this.contractDrawerData.data.modelList[0].line[0].value.money =
            data.money;
          this.contractDrawerData.data.modelList[0].line[0].value.data =
            data.tableData;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加支出
     */
    addExpend() {
      let options = [
        {
          id: "",
          name: "刻章",
          flag: false,
        },
        {
          name: "地址费",
          flag: false,
        },
        {
          name: "材料费",
          flag: false,
        },
        {
          name: "外包成本",
          flag: false,
        },
        {
          name: "其他成本",
          flag: false,
        },
        {
          name: "业务招待费",
          flag: false,
        },
      ];
      this.dialogData.title = "新建支出";
      this.dialogData.buttonArray = [];
      this.dialogData.buttonArray.push({
        text: "取消",
        active: "cancel",
      });
      this.dialogData.data = [
        {
          type: "text",
          title: "",
          value: "请选择一个支出类型",
        },
        {
          type: "select_array_one",
          title: "",
          value: options,
        },
      ];
      this.dialogData.flag = true;
    },

    /**
     * 客户
     */
    /**
     * 申请客户流失
     */
    toApplyForCustomerChurn() {
      let requestData = {
        clientId: this.tempClient.value,
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/client/getAllServiceTypeListByClientId",
        requestData,
        (data) => {
          this.dialogData.title = "选择流失类型";
          this.dialogData.buttonArray = [];
          this.dialogData.buttonArray.push(
            {
              text: "确定",
              active: "confirm_that_client_loss",
            },
            {
              text: "取消",
              active: "cancel",
            }
          );
          let options = [];
          data.forEach((element) => {
            let dd = {
              label: element.typeName,
              value: element.id,
            };
            options.push(dd);
          });
          let options2 = [];
          this.searchData.lossReasonOptions.forEach((element) => {
            let dd = {
              label: element.name,
              value: element.value,
            };
            options2.push(dd);
          });
          this.dialogData.data = [
            {
              title: "流失类型",
              type: "select-obj",
              value: { label: "全部", value: "all" },
              options: options,
            },
            {
              title: "流失原因",
              type: "select",
              value: "",
              options: options2,
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加跟进记录
     */
    addFollowRecord() {
      let requestData = {
        clientId: this.tempClient.value, // 商机ID
        userId: this.baseInfo.userId, // 操作人ID
        content: this.dialogData.data[0].value, // 当前操作
        time: "",
      };
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.dialogData.flag = false;
          this.$operationRecords.saveOperationRecords(
            this.tempClient.value,
            "添加跟进记录!",
            this.baseInfo,
            "client",
            this,
            (data2) => {}
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取工商信息
     * 根据企业全称调用企查查接口,获取企业税号、增值税类型、行业分类、所在地址、详细地址
     */
    getCompanyInfo() {
      this.$request(
        "get",
        "/qicc/search?keyword=" +
          this.contractDrawerData.data.modelList[2].line[0].value,
        {},
        (data) => {
          this.contractDrawerData.data.modelList[2].line[0].value =
            data.name == ""
              ? this.clientInfoDrawerData.data.modelList[2].line[0].value
              : data.name;
          this.contractDrawerData.data.modelList[2].line[1].value =
            data.taxNum == ""
              ? this.contractDrawerData.data.modelList[2].line[1].value
              : data.taxNum;
          this.contractDrawerData.data.modelList[2].line[5].value =
            data.address == ""
              ? []
              : this.$addressAutomaticallyMatch.addressAutomaticallyMatch(
                  data.address
                );

          if (
            this.contractDrawerData.data.modelList[2].line[5].value == null ||
            this.contractDrawerData.data.modelList[2].line[5].value.length < 3
          ) {
            this.contractDrawerData.data.modelList[2].line[5].value = [];
          }
          this.contractDrawerData.data.modelList[2].line[6].value =
            data.address == ""
              ? this.contractDrawerData.data.modelList[2].line[6].value
              : data.address;
          this.updateWorkContractByID();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 更新/修改/添加客户联系人
     */
    updateClientContactByID() {
      if (
        !clientInfoData.formatClientContactDrawerData(
          this.contractDrawerData.data.modelList
        ).flag
      ) {
        this.$message({
          message: "联系人手机号格式不正确,请确认填入正确的手机号!",
          type: "error",
          duration: 1000,
        });
        // this.getMyThreadDetailsByThreadId();
        return;
      }
      let requestData = {
        clientId: this.tempClient.value,
        data: clientInfoData.formatClientContactDrawerData(
          this.contractDrawerData.data.modelList
        ).list,
      };
      this.$request(
        "post",
        "/client/updateClientContactByID",
        requestData,
        (data) => {
          // this.saveOperationRecords(
          //   "客户【" + this.tempClientName + "】修改客户联系人!",
          //   true
          // );
          this.$message({
            message: "客户联系人操作成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.tempClient.value,
            "修改客户联系人基本信息!",
            this.baseInfo,
            "client",
            this,
            (data2) => {}
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 抽屉中删除当前联系人
     */
    lineOper(data) {
      switch (data.active) {
        case "gongkai":
          break;
        case "shanchu":
          this.$confirm("此操作将永久删除该联系人, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.contractDrawerData.data.modelList.splice(data.i, 1);
              this.updateClientContactByID();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          break;
      }
    },
    /**
     * 客户结束
     */

    /**
     * 获取合同附件数据
     */
    getAttachmentByContractId() {
      // this.contractDrawerData = contractDrawData.contractEttachmentDrawerData(
      //   this.tempContract.name,
      //   // contractDrawData.getContractExpendData()
      //   [],
      //   this.buttonAuth,
      //   this
      // );
      let requestData = {
        contractId: this.tempContract.id,
      };
      this.$request(
        "post",
        "/contract/getAttachmentByContractId",
        requestData,
        (data) => {
          this.contractDrawerData = allContractData.contractInfoDrawerData(
            this.tempClientName,
            this.contractDrawerDataAuthorityList,
            this.whetherAgencyHead, // 是否是机构负责人
            this.contractDrawerOptions,
            "合同附件",
            {}
          );
          this.contractDrawerData.data.modelList[0].line[0].value.data = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确认创建合同
     */
    choseProductTipOne(data) {
      // 新建合同
      if (this.dialogData.title == "选择产品") {
        let requestData = {
          userId: this.baseInfo.userId,
          companyId: this.baseInfo.company.companyId,
          productId: data.id, // 合同模板ID
          clientId: this.tempContract.clientId, // 客户ID
          isRenew: true,
          oldContractId: this.tempContract.id, // 原合同ID
          expenses: this.tempExpenses,
        };
        this.$request(
          "post",
          "/contract/addContract",
          requestData,
          (data) => {
            this.dialogData.flag = false;
            this.contractDrawerData.flag = false;
            this.$message({
              message: "合同创建成功!",
              duration: 1000,
              type: "success",
            });

            this.$operationRecords.saveOperationRecords(
              data.contractId,
              "新建合同!",
              this.baseInfo,
              "contract",
              this,
              (data2) => {
                this.initTableData();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }

      // 新建支出
      if (this.dialogData.title == "新建支出") {
        let dd = {
          client: this.tempClient.label,
          contractNum: this.tempContract.name,
          contractId: this.tempContract.id,
          expendStatus: "待提交",
          expendNum: "",
          time: "",
          expendType: data.name,
          user: this.baseInfo.userId,
          title: "",
          file: "", // 附件
          services: this.tempServices,
        };
        this.$request(
          "post",
          "/contract/addExpend",
          dd,
          (data) => {
            this.$message({
              message: "支出添加成功!",
              type: "success",
              duration: 1000,
            });
            this.dialogData.flag = false;
            this.$operationRecords.saveOperationRecords(
              this.tempContract.id,
              "添加支出!",
              this.baseInfo,
              "contract",
              this,
              (data2) => {
                this.getExpendByContractId();
              }
            );
          },
          (errorMessage) => {
            this.$message.error(errorMessage);
          }
        );
      }
    },
    /**
     * 保存抽屉数据
     */
    saveDrawerData(drawerTitle) {
      let requestData = {};
      if (this.contractDrawerData.title == "合同") {
        switch (this.contractDrawerData.cardIndex) {
          case 0:
            // 合同信息的保存
            if (
              contractDrawData.formatContractInfoDrawerData(
                this.contractDrawerData.data.modelList
              )
            ) {
              console.log("保存数据:", this.contractDrawerData);
              requestData = {
                contractId: this.tempContract.id,
                userId: this.baseInfo.userId,
                companyId: this.baseInfo.company.companyId,
                data: contractDrawData.formatContractInfoDrawerData(
                  this.contractDrawerData.data.modelList
                ),
              };
              this.$request(
                "post",
                "/contract/updateContractInfoByID",
                requestData,
                (data) => {
                  this.$message({
                    message: "保存成功!",
                    type: "success",
                    duration: 1000,
                  });
                  this.$operationRecords.saveOperationRecords(
                    this.tempContract.id,
                    "修改" + drawerTitle + "信息!",
                    this.baseInfo,
                    "contract",
                    this,
                    (data2) => {
                      this.initTableData();
                    }
                  );
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            } else {
              this.$message.error("服务项名称不能为空!");
            }
            break;
          case 4:
            // 合同附件的上传
            this.contractDrawerData.data.modelList[0].line[0].value.data.forEach(
              (element) => {
                if (this.$keyType.keyType(element.url) != "Object") {
                  let uu = {
                    path: element.url,
                    title: "合同附件",
                  };
                  element.url = uu;
                }
              }
            );
            requestData = {
              contractId: this.tempContract.id,
              userId: this.baseInfo.userId,
              clientId: this.tempClient.value,
              companyId: this.baseInfo.company.companyId,
              data: this.contractDrawerData.data.modelList[0].line[0].value
                .data,
            };
            this.$request(
              "post",
              "/contract/saveAttachmentByContractId",
              requestData,
              (data) => {
                this.$message({
                  message: "附件操作成功!",
                  type: "success",
                  duration: 1000,
                });

                this.$operationRecords.saveOperationRecords(
                  this.tempContract.id,
                  "上传合同附件!",
                  this.baseInfo,
                  "contract",
                  this,
                  (data2) => {
                    this.getAttachmentByContractId();
                    this.initTableData();
                  }
                );
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;

          default:
            break;
        }
      }
      if (this.contractDrawerData.title == "客户") {
        requestData = {
          clientId: this.tempClient.value,
          companyId: this.baseInfo.company.companyId,
          data: [],
        };
        switch (this.contractDrawerData.cardIndex) {
          case 0:
            // 更新客户信息
            requestData.data = clientInfoData.formatClientInfoDrawerData(
              this.contractDrawerData.data.modelList
            );
            this.$request(
              "post",
              "/client/updateClient",
              requestData,
              (data) => {
                // this.saveOperationRecords(
                //   "客户【" + this.tempClientName + "】信息修改!",
                //   true
                // );
                this.$message({
                  message: "修改成功!",
                  type: "success",
                  duration: 1000,
                });
                this.$operationRecords.saveOperationRecords(
                  this.tempClient.value,
                  "修改" + drawerTitle + "信息!",
                  this.baseInfo,
                  "client",
                  this,
                  (data2) => {
                    this.initTableData();
                  }
                );
                // this.initTableData();
              },
              (errorMessage) => {
                this.$message.error(errorMessage);
              }
            );
            break;
          case 1:
            // 更新客户联系人
            this.updateClientContactByID();
            break;

          default:
            break;
        }
      }
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
    /**
     * 修改表头顺序dialog关闭
     */
    closeUpdateTableMenu() {
      this.tragerDialogData.flag = false;
    },
    /**
     * 拖拽表头排序父子组件连锁事件
     */
    showDrager(value) {
      this.tableMenuData = value;
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    /**
     * 右边抽屉关闭事件
     */
    closeUpdateTableDrawer() {
      this.allFunctionButtons = JSON.parse(
        JSON.stringify(this.renewAllFunctionButtons)
      );
      this.contractDrawerData.flag = false;
    },
    /**
     * 筛选弹出框关闭事件
     */
    popoCanael() {
      this.popoData.popoVisible = false;
    },
    /**
     * 关闭筛选标签事件
     */
    closeScreen(index) {
      this.searchData.screenList.splice(index, 1);
      this.initTableData();
    },
    /**
     * 表头div拖动方法
     */
    dragging(value) {
      console.log("div拖动", value);
    },
    /**
     * 续签父子组件连锁事件
     */
    showRenew(data) {
      if (this.dialogData.title == "续签合同") {
        this.dialogData.data[0].value = data;
      }
    },
    /**
     * 右边抽屉父子组件连锁事件
     */
    showDrawer(value) {
      this.contractDrawerData = value;
    },
    /**
     * 查看跟进记录
     */
    showGenjin(clientData) {
      this.ttempClient = clientData;
      let requestData = {
        clientId: clientData.clientId,
        threadId: "",
      };
      this.$request(
        "post",
        "/followRecord/showFollowRecord",
        requestData,
        (data) => {
          this.dialogData.title = "跟进记录-" + clientData.clientName;
          this.dialogData.buttonArray = [];
          this.dialogData.data = [
            {
              type: "genjinjilu",
              value: {
                clientId: clientData.id,
                threadId: "",
                hasContent: true,
                content: "",
                time: "",
                list: data,
                hasTarget: false,
                target: "",
                hasPhase: false,
                phase: "",
              },
            },
          ];
          this.dialogData.flag = true;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 确定添加跟进记录
     */
    followRecord(recordData) {
      console.log("跟进记录:", recordData);
      let requestData = {
        clientId: this.ttempClient.clientId, // 商机ID
        threadId: "", // 商机ID
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId, // 操作人ID
        content: recordData.content, // 当前操作
        time: recordData.time, // 下次跟进时间
        stage: "",
      };
      if (requestData.content == "") {
        this.$message.error("跟进内容不能为空!");
        return;
      }
      this.$request(
        "post",
        "/followRecord/addFollowRecord",
        requestData,
        (data) => {
          this.$message({
            message: "跟进记录添加成功!",
            type: "success",
            duration: 1000,
          });
          this.$operationRecords.saveOperationRecords(
            this.ttempClient.clientId,
            "添加跟进记录:" + recordData.content + "!",
            this.baseInfo,
            "client",
            this,
            (data2) => {
              this.showGenjin(this.ttempClient);
            }
          );
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },

  mounted() {
    this.loading = true;
    this.isCharge();
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#contract-renew {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .contract-screen-fiftrate {
    width: 65px;
    min-width: 65px;
    height: 30px;
    border: 1px solid @boderColor;
    box-sizing: border-box;
    color: @boderActiveColor;
    font-size: @contentFontSize;
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: 0.3s;
    i {
      margin: 0 4px;
    }
  }
  .contract-screen-fiftrate:hover {
    cursor: pointer;
    border-color: @boderActiveColor;
  }
  .contract-screen-search {
    width: 210px;
    min-width: 210px;
    margin-left: 10px;
    height: 30px;
  }
  .contract-screen-checked {
    width: 140px;
    height: 30px;
    min-width: 140px;
    font-size: @contentFontSize;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contract-screen-active {
    flex: 1;
  }
  .contract-screen-but-select {
    width: 140px;
    min-width: 160px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
    cursor: pointer;
    .contract-screen-but-select_but {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.5s;
    }
    .contract-screen-but-select_but:hover {
      background-color: @activeColor2;
    }
    .contract-screen-but-select_select {
      width: 30px;
      height: 100%;
      border-left: 1px solid @activeColor3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .contract-screen-but {
    width: 100px;
    min-width: 100px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @activeColor;
    font-size: @contentFontSize;
    color: #fff;
    margin: 0 5px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .contract-screen-but:hover {
    cursor: pointer;
    background-color: @activeColor2;
  }
  .contract-screen-but-boder {
    width: 50px;
    min-width: 50px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid @activeColor;
    font-size: @contentFontSize;
    background-color: @activeBackground;
    color: @activeColor;
    margin: 0 5px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.5s;
  }
  .contract-screen-but-boder:hover {
    cursor: pointer;
    background-color: @activeBackground2;
  }

  .contract-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .contract-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .contract-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .contract-table-card-tab {
          width: 80px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .contract-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .contract-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .contract-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .contract-table-card-content {
        flex: 1;
        width: 98%;
        .contract-table-card-content_text {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px;
          box-sizing: border-box;
          color: rgb(99, 211, 34);
          font-size: 12px;
        }
      }
      .contract-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
