/**
 * 
 * 获取当前模块的抽屉下拉框的配置
 * @param {*} active 
 */
export function getDrawerOptionsList(active) {
    let options = {}
    switch (active) {
        case "myThread":
        case "allThread":
        case "threadPool":
        case "threadRecycle":
            // 我的商机的抽屉的下拉框配置
            options = {
                allUser: [], // 所有用户
                areaList: [], // 所有片区
                sourceList: [], // 客户所有来源
                addedValueTaxTypeList: [], // 增值税类型
            }
            break;
        case "clientInfo":
        case "clientCheck":
            // 客户资料的抽屉的下拉框配置
            options = {
                allUser: [], // 所有用户
                areaList: [], // 所有片区
                sourceList: [], // 客户所有来源
                addedValueTaxTypeList: [], // 增值税类型
                levelList: [], // 客户等级
                tipList: [], // 客户标签
                qualificationList: "", // 资质类型
                lossReasonList: [], // 流失原因
                clientNumberList: [] // 客户编号
            }
            break;
        case "contractInfo":
        case "contractCheck":
        case "contractRenew":
        case "contractWork":
            // 全部合同的抽屉下拉框配置
            options = {
                allUser: [], // 所有用户
                areaList: [], // 所有片区
                sourceList: [], // 客户所有来源
                addedValueTaxTypeList: [], // 增值税类型
                levelList: "", // 客户等级
                tipList: "", // 客户标签
                productList: [], // 所有产品
                serviceTypeList: [], // 所有服务项类别
                serviceList: [], // 所有服务项
            }
            break

        default:
            break;
    }

    return options
}


/**
 * 获取各种标签数据
 */
export function getTagList(arrayList, type, companyId, this_) {
    let requestData = {
        type: type,
        companyId: companyId
    };
    this_.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
            console.log('标签数据是', data)
            if (data.length == 0) {
                // 初次获取该模块标签,读取配置文件
                arrayList.followStageList = [];
                arrayList.areaList = [];
                arrayList.sourceList = [];
                arrayList.addedValueTaxTypeList = [];
                arrayList.qualificationList = [];
                arrayList.lossReasonList = [];
                arrayList.accountOnBehalfList = [];
                arrayList.intentionLevelList = [];
                arrayList.threadShareTypeList = [];
                arrayList.visitReasonList = [];
            } else {
                data.forEach((element) => {
                    let op = []
                    element.value.forEach(el => {
                        let d = {
                            label: el.name,
                            value: el.value
                        }
                        if (element.type == "客户来源") {
                            d.value = el.name
                        }
                        op.push(d)
                    });
                    if (element.type == "片区") {
                        arrayList.areaList = op;
                    }
                    if (element.type == "客户来源") {
                        arrayList.sourceList = op;
                    }
                    if (element.type == "增值税类型") {
                        arrayList.addedValueTaxTypeList = op;
                    }
                    if (element.type == "客户等级") {
                        arrayList.levelList = op;
                    }
                    if (element.type == "客户标签") {
                        arrayList.tipList = op;
                    }
                    if (element.type == "资质类型") {
                        arrayList.qualificationList = op;
                    }
                    if (element.type == "流失原因") {
                        arrayList.lossReasonList = op;
                    }
                    if (element.type == "代账情况") {
                        arrayList.accountOnBehalfList = op;
                    }
                    if (element.type == "意向等级") {
                        arrayList.intentionLevelList = op;
                    }
                    if (element.type == "共享商机类型") {
                        arrayList.threadShareTypeList = op;
                    }
                    if (element.type == "出访事由") {
                        arrayList.visitReasonList = op;
                    }
                });
            }
        },
        (errorMessage) => {
            this_.$message.error(errorMessage);
        }
    );
}