<template>
  <div id="invoice-dialog">
    <!-- 填写进项发票的dialog -->
    <div class="invoice-line">
      <el-radio-group v-model="value.type" @change="changeTitle">
        <el-radio :label="'4'">电子普通发票</el-radio>
        <el-radio :label="'5'">电子专用发票</el-radio>
        <el-radio :label="'6'">纸质普通发票</el-radio>
        <el-radio :label="'7'">纸质专用发票</el-radio>
      </el-radio-group>
    </div>

    <div class="invoice-line">
      <div class="invoice-line-left">购买方</div>
      <div class="invoice-line-right">
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <div class="invoice-required">*</div>
            <div class="invoice-active"></div>
            名称:
          </div>
          <div class="invoice-line-right-item-input">
            {{ value.buyerName }}
          </div>
        </div>
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <div class="invoice-required">*</div>
            <div class="invoice-active"></div>
            纳税人识别号:
          </div>
          <div class="invoice-line-right-item-input">
            {{ value.buyerTaxpayerNum }}
          </div>
        </div>
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <!-- <div class="invoice-required">*</div> -->
            地址、电话:
          </div>
          <div class="invoice-line-right-item-input">
            {{ value.buyerAddress }},{{ value.buyerTel }}
          </div>
        </div>
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <!-- <div class="invoice-required">*</div> -->
            开户行及账号:
          </div>
          <div class="invoice-line-right-item-input">
            {{ value.buyerBankName }},{{ value.buyerBankAccount }}
          </div>
        </div>
      </div>
    </div>

    <div class="invoice-line">
      <div class="invoice-line-left">含税</div>
      <div class="invoice-line-right" style="border: none">
        <el-switch v-model="value.includeTaxFlag" @change="changeTaxs">
        </el-switch>
      </div>
    </div>

    <div class="invoice-line">
      <div class="invoice-table">
        <div class="invoice-table-line">
          <div class="invoice-table-line-cell">
            <div class="invoice-required">*</div>
            货物或应税劳务、服务名称
          </div>
          <div class="invoice-table-line-cell">规格型号</div>
          <div class="invoice-table-line-cell">单位</div>
          <div class="invoice-table-line-cell">数量</div>
          <div class="invoice-table-line-cell">单价</div>
          <div class="invoice-table-line-cell">
            <div class="invoice-required">*</div>
            金额
          </div>
          <div class="invoice-table-line-cell">
            <div class="invoice-required">*</div>
            税率
          </div>
          <div class="invoice-table-line-cell">
            <div class="invoice-required">*</div>
            税额
          </div>
          <div class="invoice-table-line-cell">
            操作
            <i class="el-icon-circle-plus-outline" @click="add"></i>
          </div>
        </div>
        <div
          class="invoice-table-line"
          v-for="(item, index) in value.itemList"
          :key="index"
        >
          <div class="invoice-table-line-cell">
            <el-input
              placeholder="请输入货物或应税劳务、服务名称"
              v-if="item.flag"
              v-model="item.goodsName"
              @blur="changeCode(item, index)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchCode(item, index)"
              ></el-button>
            </el-input>
            <span v-else @click="item.flag = !item.flag"
              >*{{ item.goodsType }}*{{ item.goodsName }}*</span
            >
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              placeholder="请输入规格型号"
              v-model="item.specificationModel"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-input placeholder="请输入单位" v-model="item.meteringUnit">
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              placeholder="请输入数量"
              v-model="item.quantity"
              @blur="changeAmount(item)"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              placeholder="请输入单价"
              v-model="item.unitPrice"
              @blur="changeAmount(item)"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-input
              placeholder="请输入金额"
              v-model="item.invoiceAmount"
              @blur="changeTax(item)"
            >
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <el-select
              v-model="item.taxRateValue"
              filterable
              placeholder="请选择"
              @change="changeTax(item)"
            >
              <el-option
                v-for="it in taxRateValueList"
                :key="it.value"
                :label="it.label"
                :value="it.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="invoice-table-line-cell">
            <el-input placeholder="请输入税额" v-model="item.taxRateAmount">
            </el-input>
          </div>
          <div class="invoice-table-line-cell">
            <i class="el-icon-help" @click="settingGoods(item, index)"></i>
            <i class="el-icon-remove-outline" @click="deleteItem(index)"></i>
          </div>
        </div>
        <div class="invoice-table-line">
          <div class="invoice-table-line-cell leftal">合计:</div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell" style="border: none"></div>
          <div class="invoice-table-line-cell leftal">¥{{ allMoney }}</div>
          <div class="invoice-table-line-cell"></div>
          <div class="invoice-table-line-cell leftal">¥{{ allTax }}</div>
          <div class="invoice-table-line-cell" style="border: none"></div>
        </div>
        <div class="invoice-table-line">
          <div class="invoice-table-line-cell leftal">
            价税合计(大写):{{ value.allMoney.text }}
          </div>
          <div class="invoice-table-line-cell leftal">
            小写:{{ value.allMoney.num }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="invoice-line"
      v-if="
        value.type == '4' ||
          value.type == 4 ||
          value.type == '5' ||
          value.type == 5
      "
    >
      <div class="invoice-line-left">信息</div>
      <div class="invoice-line-right">
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <!-- <div class="invoice-required">*</div> -->
            收票人手机:
          </div>
          <div class="invoice-line-right-item-input">
            <el-input
              v-model="value.takerTel"
              placeholder="请输入收票人手机"
            ></el-input>
          </div>
        </div>
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <!-- <div class="invoice-required">*</div> -->
            收票人邮箱:
          </div>
          <div class="invoice-line-right-item-input">
            <el-input
              v-model="value.takerEmail"
              placeholder="请输入收票人邮箱"
            ></el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="invoice-line">
      <div class="invoice-line-left">销售方</div>
      <div class="invoice-line-right">
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <div class="invoice-required">*</div>
            <div class="invoice-active"></div>
            名称:
          </div>
          <div class="invoice-line-right-item-input">
            <!-- <el-input
              v-model="value.buyerName"
              placeholder="请输入个人或企业名称"
            > -->
            <el-autocomplete
              class="inline-input"
              v-model="value.clientName"
              :fetch-suggestions="querySearch"
              placeholder="请输入个人或企业名称"
              :trigger-on-focus="false"
              @select="handleSelect"
              value-key="name"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="searchTex"
              ></el-button>
            </el-autocomplete>
            <!-- </el-input> -->
          </div>
        </div>
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <div class="invoice-required">*</div>
            <div class="invoice-active"></div>
            纳税人识别号:
          </div>
          <div class="invoice-line-right-item-input">
            <el-input
              v-model="value.clientTaxpayerNum"
              placeholder="请输入纳税人识别号"
            ></el-input>
          </div>
        </div>
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <!-- <div class="invoice-required">*</div> -->
            地址、电话:
          </div>
          <div class="invoice-line-right-item-input">
            <el-input
              v-model="value.sellerAddress"
              placeholder="请输入地址"
            ></el-input>
          </div>
          <div class="invoice-line-right-item-input">
            <el-input
              v-model="value.sellerTel"
              placeholder="请输入电话"
            ></el-input>
          </div>
        </div>
        <div class="invoice-line-right-item">
          <div class="invoice-line-right-item-span">
            <!-- <div class="invoice-required">*</div>gh -->
            开户行及账号:
          </div>
          <div class="invoice-line-right-item-input">
            <el-input
              v-model="value.sellerBankName"
              placeholder="请输入开户行"
            ></el-input>
          </div>
          <div class="invoice-line-right-item-input">
            <el-input
              v-model="value.sellerBank"
              placeholder="请输入账号"
            ></el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="invoice-line">
      <div class="invoice-line-left">备注</div>
      <div class="invoice-line-right" style="border: none">
        <el-input
          type="textarea"
          v-model="value.remark"
          placeholder="请输入备注"
          :rows="2"
        ></el-input>
      </div>
    </div>

    <div class="invoice-line space-between">
      <div class="line-cell">
        <span>收款人:</span>
        <el-select
          v-model="value.casherName"
          filterable
          placeholder="请选择"
          value-key="id"
        >
          <el-option
            v-for="item in value.employeeList"
            :key="item.id"
            :label="item.userName"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-cell">
        <span>复核人:</span>
        <el-select
          v-model="value.reviewerName"
          filterable
          placeholder="请选择"
          value-key="id"
        >
          <el-option
            v-for="item in value.employeeList"
            :key="item.id"
            :label="item.userName"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-cell">
        <span>开票员:</span>
        <el-select
          v-model="value.drawerName"
          filterable
          placeholder="请选择"
          value-key="id"
        >
          <el-option
            v-for="item in value.employeeList"
            :key="item.id"
            :label="item.userName"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
    </div>

    <el-dialog
      title="设置折扣行"
      :visible.sync="dialogData.flag"
      width="30%"
      :before-close="handleClose"
      center
      :modal="false"
    >
      <div class="invoice-dialog-content">
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">原商品名称:</div>
          <div class="invoice-dialog-content-line-value">
            {{ value.itemList[tempIndex].goodsName }}
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">原商品金额:</div>
          <div class="invoice-dialog-content-line-value">
            {{ value.itemList[tempIndex].invoiceAmount }}
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">原商品税率:</div>
          <div class="invoice-dialog-content-line-value">
            {{ value.itemList[tempIndex].taxRateValue }}
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">折扣率:</div>
          <div class="invoice-dialog-content-line-value">
            <el-input
              v-model="input"
              placeholder="请输入内容"
              @blur="changeDiscountAmount"
            ></el-input>
          </div>
          <div class="invoice-dialog-content-line-span">%</div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">折扣金额:</div>
          <div class="invoice-dialog-content-line-value">
            {{ dialogData.discountAmount }}
          </div>
        </div>
        <div class="invoice-dialog-content-line">
          <div class="invoice-dialog-content-line-title">折扣税额:</div>
          <div class="invoice-dialog-content-line-value">
            {{ dialogData.discountTaxRateAmount }}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="makeSure">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="货物编号选择"
      :visible.sync="goodsDialog.flag"
      width="1250px"
      :before-close="handleClose"
      :modal="false"
      top="10vh"
    >
      <div class="goods-dialog-content">
        <commondity
          :commondityData="goodsDialog.data"
          @checkCode="checkCode"
        ></commondity>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commondity from "./commodity.vue";
import * as invoiceData from "../assets/js/invoiceData";
export default {
  components: { commondity },
  props: ["invoiceDialog"],
  data() {
    return {
      value: this.invoiceDialog,
      //   value: {
      //     id: "", // id为空时,即为新增发票
      //     clientId: "", // 客户id
      //     companyId: "", // 公司id
      //     title: "安徽增值税电子普通发票", // 发票标题
      //     type: "", // 发票类型
      //     allMoney: {
      //       // 价税合计
      //       num: "15000.00", // 小写
      //       text: "壹万伍仟圆整", // 大写
      //     },
      //     clientName: "安徽XXX有限公司", // 客户名称  销售方名称
      //     clientTaxpayerNum: "423454341235547668", // 客户税号  销售方纳税人识别号
      //     buyerName: "XXXXXXX有限公司", // 购买方名称
      //     buyerTaxpayerNum: "", // 购买方纳税人识别号
      //     buyerAddress: "", // 购买方地址
      //     buyerTel: "", // 购买方电话
      //     buyerBankName: "", // 购买方开户行
      //     buyerBankAccount: "", // 购买方银行账号

      //     sellerAddress: "销售方地址", //销售方地址
      //     sellerTel: "", //销售方电话
      //     sellerBankName: "销售方开户行", //销售方开户行
      //     sellerBank: "", //销售方银行账号

      //     takerTel: "", // 收票人手机
      //     takerEmail: "", // 收票人邮箱

      //     itemName: "", //主开票项目名称 可默认为开票项目列表的第一个项目名称
      //     casherName: { id: "", name: "收款人名称" }, //收款人名称
      //     reviewerName: { id: "", name: "复核人名称" }, //复核人名称
      //     drawerName: { id: "", name: "开票人名称" }, //开票人名称
      //     includeTaxFlag: true, // 含税标示  false：不含税，true：含税，默认为 true 含税
      //     itemList: [
      //       // 开票项目列表
      //       {
      //         goodsType:"", // 项目类别
      //         goodsName: "服务费", // 项目名称  等价于itemName
      //         taxClassificationCode: "", // 对应税收分类编码
      //         specificationModel: "", // 对应规格型号
      //         meteringUnit: "", // 单位
      //         quantity: "1", // 数量
      //         unitPrice: "7500.00", // 单价
      //         invoiceAmount: "7500.00", // 金额
      //         taxRateValue: "0", // 税率
      //         taxRateAmount: "0", // 税额 (税额 = 含税金额/(1+税率)*税率)

      //         discountAmount: "", // 折扣金额
      //         discountTaxRateAmount: "", // 折扣税额

      //         deductionAmount: "", // 差额开票抵扣金额
      //         preferentialPolicyFlag: "", // 优惠政策标识
      //         zeroTaxFlag: "", // 零税率标识
      //         vatSpecialManage: "", // 增值税特殊管理
      //       },
      //     ],
      //     remark: "备注备注", // 备注
      //     machineCode: "61616161661616", // 机器编号   机柜编号?
      //     port: "", //端口

      //     employeeList: [
      //       {
      //         name: "",
      //         id: "",
      //       },
      //     ],
      //   },
      taxRateValueList: this.$taxRateValueList,
      dialogData: {
        flag: false,
        discountAmount: 0,
        discountTaxRateAmount: 0,
      },
      tempValue: {},
      tempIndex: 0,
      input: "",

      allCustomers: [],
      allMoney: 0,
      allTax: 0,

      tempGoods: {},
      tempGoodsIndex: 0,
      goodsDialog: {
        flag: false,
        data: {
          treeData: [],
          tableData: [],
          name: "",
        },
      },
    };
  },
  watch: {
    invoiceDialog() {
      //父组件值变化->>子组件也变化
      this.value = this.invoiceDialog;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 通过客户ID,获取客户对应的常用客户
     */
    getAllCustomersByClientId() {
      let requestData = {
        clientId: this.value.clientId,
      };
      this.$request(
        "post",
        "/activeCustomer/queryAllCustomers",
        requestData,
        (data) => {
          this.allCustomers = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 模糊匹配
     */
    querySearch(queryString, cb) {
      var restaurants = this.allCustomers;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.value.clientTaxpayerNum = item.taxNum;
      this.value.sellerAddress = item.address;
      this.value.sellerTel = item.tel;
      this.value.sellerBankName = item.bank;
      this.value.sellerBank = item.bankAccount;
    },
    /**
     * 根据企业名称,获取企业税号等信息
     */
    searchTex() {
      this.$request(
        "get",
        "/qicc/search?keyword=" + this.value.buyerName,
        {},
        (data) => {
          this.value.clientName = data.name;
          this.value.clientTaxpayerNum = data.taxNum;
          this.value.sellerAddress = data.address;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 修改发票类型,同步修改发票标题, 发票限额
     */
    changeTitle(e) {
      this.value.title = invoiceData.changeTitle(e);
      switch (e) {
        case "026":
          this.value.LimitAmount = this.value.commonTicketElectronicMoney; // 电子普票
          break;
        case "028":
          this.value.LimitAmount = this.value.specialTicketElectronicMoney; // 电子专票
          break;
        case "007":
          this.value.LimitAmount = this.value.commonTicketPaperMoney; // 纸质普票
          break;
        case "004":
          this.value.LimitAmount = this.value.specialTicketPaperMoney; //纸质专票
          break;
        default:
          break;
      }
    },
    /**
     * 根据数量和单价,计算金额
     */
    changeAmount(data) {
      this.$cutOutNum.cutOutNum(
        parseFloat(data.quantity) * parseFloat(data.unitPrice),
        this,
        (num) => {
          data.invoiceAmount = num;
          // data.taxRateAmount = num;
          this.changeTax(data);
        }
      );
    },
    /**
     * 根据金额,税率,计算税额
     * 含税情况下:
     *      税额 = 含税金额/(1+税率)*税率
     * 不含税情况下:
     *      税额 = 不含税金额 * 税率
     */
    changeTax(data) {
      let nn = 0;
      // 保证金额不变的条件下,改变税额
      if (this.value.includeTaxFlag) {
        nn =
          (parseFloat(data.invoiceAmount) /
            (1 + parseFloat(data.taxRateValue))) *
          parseFloat(data.taxRateValue);
      } else {
        nn = parseFloat(data.invoiceAmount) * parseFloat(data.taxRateValue);
      }
      this.$cutOutNum.cutOutNum(nn, this, (num) => {
        data.taxRateAmount = num;
        this.sumAllMoney();
      });
      // 保证税额不变的情况下,改变金额
      // if (this.value.includeTaxFlag) {
      //   nn =
      //     ((parseFloat(data.taxRateValue) + 1) *
      //       parseFloat(data.taxRateAmount)) /
      //     parseFloat(data.taxRateValue);
      // } else {
      //   nn = parseFloat(data.taxRateAmount) / parseFloat(data.taxRateValue);
      // }
      // this.$cutOutNum.cutOutNum(nn, this, (num) => {
      //   data.invoiceAmount = num;
      //   this.sumAllMoney();
      // });
    },
    /**
     * 是否含税改变事件
     */
    changeTaxs() {
      this.value.itemList.forEach((element) => {
        this.changeAmountTax(element);
      });
    },
    /**
     * 固定税额,计算金额
     */
    changeAmountTax(data) {
      let nn = 0;
      if (this.value.includeTaxFlag) {
        nn =
          ((parseFloat(data.taxRateValue) + 1) *
            parseFloat(data.taxRateAmount)) /
          parseFloat(data.taxRateValue);
      } else {
        nn = parseFloat(data.taxRateAmount) / parseFloat(data.taxRateValue);
      }
      this.$cutOutNum.cutOutNum(nn, this, (num) => {
        data.invoiceAmount = num;
        this.sumAllMoney();
      });
    },
    /**
     * 计算价税合计总金额
     */
    sumAllMoney() {
      let money = 0;
      let tax = 0;
      this.value.itemList.forEach((element) => {
        money += parseFloat(element.invoiceAmount);
        tax += parseFloat(element.taxRateAmount);
      });
      this.$cutOutNum.cutOutNum(money, this, (num) => {
        this.allMoney = num;
        this.$cutOutNum.cutOutNum(tax, this, (tax) => {
          this.allTax = tax;
          // 计算价税合计
          if (this.value.includeTaxFlag) {
            // 含税
            // 价税总额 = 总金额
            this.value.allMoney.num = this.allMoney;
            this.value.allMoney.text = this.$money.required(this.allMoney);
          } else {
            // 不含税
            // 价税总额 = 总金额 + 总税额
            this.$cutOutNum.cutOutNum(
              parseFloat(this.allMoney) + parseFloat(this.allTax),
              this,
              (allNum) => {
                this.value.allMoney.num = allNum;
                this.value.allMoney.text = this.$money.required(allNum);
              }
            );
          }
        });
      });
    },
    /**
     * 添加货物
     */
    add() {
      if (this.value.itemList.length > 0) {
        if (
          this.value.itemList[this.value.itemList.length - 1].goodsName == "" ||
          this.value.itemList[this.value.itemList.length - 1].invoiceAmount ==
            "" ||
          this.value.itemList[this.value.itemList.length - 1].taxRateValue ==
            "" ||
          this.value.itemList[this.value.itemList.length - 1].taxRateAmount ==
            ""
        ) {
          this.$message({
            type: "error",
            message: "请完善商品信息!",
            duration: 1000,
          });
          return;
        }
      }
      let dd = {
        flag: true,
        goodsType: "", // 项目类别
        goodsName: "", // 项目名称  等价于itemName
        taxClassificationCode: "", // 对应税收分类编码
        specificationModel: "", // 对应规格型号
        meteringUnit: "", // 单位
        quantity: "0", // 数量
        unitPrice: "0.00", // 单价
        invoiceAmount: "0.00", // 金额
        taxRateValue: "0", // 税率
        taxRateAmount: "0", // 税额 (税额 = 含税金额/(1+税率)*税率)
        discountAmount: "", // 折扣金额
        discountTaxRateAmount: "", // 折扣税额
        deductionAmount: "", // 差额开票抵扣金额
        preferentialPolicyFlag: "", // 优惠政策标识
        zeroTaxFlag: "", // 零税率标识
        vatSpecialManage: "", // 增值税特殊管理
      };
      this.value.itemList.push(dd);
    },
    /**
     * 移除货物
     */
    deleteItem(index) {
      this.$confirm("此操作将删除此条 商品行, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.value.itemList.splice(index, 1);
          if (this.value.itemList.length == 0 && index == 0) {
            this.add();
          }
          this.sumAllMoney();
          this.$message({
            type: "success",
            message: "删除成功!",
            duration: 1000,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
            duration: 1000,
          });
        });
    },
    /**
     * 添加折扣行
     */
    settingGoods(data, index) {
      if (
        data.goodsName == "" ||
        data.invoiceAmount == "" ||
        data.taxRateValue == "" ||
        data.taxRateAmount == ""
      ) {
        this.$message({
          type: "error",
          message: "请完善商品信息!",
          duration: 1000,
        });
        return;
      }
      this.tempValue = data;
      this.tempIndex = index;
      // this.$emit("settingGoods", data);
      this.dialogData.flag = true;
    },
    /**
     * 实时改变折扣金额
     */
    changeDiscountAmount() {
      this.$cutOutNum.cutOutNum(
        parseFloat(this.input) *
          0.01 *
          this.value.itemList[this.tempIndex].invoiceAmount, // 原商品金额
        this,
        (num) => {
          this.dialogData.discountAmount = num;
          let nn = 0;
          if (this.value.includeTaxFlag) {
            nn =
              (parseFloat(this.dialogData.discountAmount) /
                (1 +
                  parseFloat(
                    this.value.itemList[this.tempIndex].taxRateValue // 原商品税率
                  ))) *
              parseFloat(this.value.itemList[this.tempIndex].taxRateValue);
          } else {
            nn =
              parseFloat(this.dialogData.discountAmount) *
              parseFloat(this.value.itemList[this.tempIndex].taxRateValue);
          }
          this.$cutOutNum.cutOutNum(nn, this, (money) => {
            this.dialogData.discountTaxRateAmount = money;
          });
        }
      );
    },
    /**
     * 确定设置折扣行
     */
    makeSure() {
      this.value.itemList[
        this.tempIndex
      ].discountAmount = this.dialogData.discountAmount;
      this.value.itemList[
        this.tempIndex
      ].discountTaxRateAmount = this.dialogData.discountTaxRateAmount;
      this.dialogData.flag = false;
    },
    /**
     * 折扣行dialog关闭事件
     */
    handleClose() {
      this.dialogData.flag = false;
      this.goodsDialog.flag = false;
    },
    /**
     * 选择货物编号
     */
    changeCode(data, index) {
      this.tempGoods = data;
      this.tempGoodsIndex = index;
      if (data.taxClassificationCode == "") {
        // 重新获取货物编号
        this.goodsDialog.data.name = data.goodsName;
        this.goodsDialog.flag = true;
      } else {
        data.flag = !data.flag;
      }
    },
    searchCode(data, index) {
      this.tempGoods = data;
      this.tempGoodsIndex = index;
      this.goodsDialog.data.name = this.tempGoods.goodsName;
      this.goodsDialog.flag = true;
    },
    /**
     * 确认选择货物编号
     */
    checkCode(data) {
      this.tempGoods.goodsType = data.abb;
      this.tempGoods.taxClassificationCode = data.num;
      this.tempGoods.flag = !this.tempGoods.flag;
      this.goodsDialog.flag = false;
    },
  },
  mounted() {
    this.getAllCustomersByClientId();
  },
};
</script>

<style lang="less" scoped>
#invoice-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  .invoice-line {
    width: 100%;
    height: auto;
    min-height: 45px;
    margin: 2px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border: 1px solid @boderColor;
    font-size: 12px;
    padding: 0 3px;
    // &:nth-last-child(1) {
    //   border: none;
    // }
    .invoice-line-left {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      //   border-right: 1px solid @boderColor;
      box-sizing: border-box;
    }
    .invoice-line-right {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      border-left: 1px solid @boderColor;
      .invoice-line-right-item {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 3px;
        border-bottom: 1px solid @boderColor;
        box-sizing: border-box;
        &:nth-last-child(1) {
          border: none;
        }
        .invoice-line-right-item-span {
          width: 100px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
        .invoice-line-right-item-input {
          flex: 1;
          width: 0;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0 3px;
          box-sizing: border-box;
        }
      }
    }
    .invoice-table {
      width: 100%;
      height: auto;
      max-height: 200px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 0;

      &::-webkit-scrollbar {
        width: 1px;
      }
      .invoice-table-line {
        width: 100%;
        height: 40px;
        display: flex;
        border-bottom: 1px solid @boderColor;
        box-sizing: border-box;
        &:nth-last-child(1) {
          border: none;
        }
        .invoice-table-line-cell {
          width: 0;
          flex: 1;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 1px solid @boderColor;
          padding: 0 3px;
          box-sizing: border-box;
          &:nth-child(1) {
            border: none;
            flex: 3;
          }
          i {
            cursor: pointer;
            transition: 0.3s;
            margin: 0 3px;
            font-size: 16px;
            &:hover {
              color: @activeColor3;
            }
          }
          span {
            cursor: pointer;
          }
        }
      }
    }
    .line-cell {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      align-items: center;
      span {
        margin-right: 5px;
        width: 70px;
      }
    }
  }
  .invoice-required {
    width: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f00;
    font-size: 18px;
  }
  .invoice-active {
    flex: 1;
  }
  .space-between {
    justify-content: space-between;
  }
  .leftal {
    align-items: center;
    justify-content: flex-start !important;
  }
  .invoice-dialog-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    .invoice-dialog-content-line {
      width: 100%;
      height: 50px;
      display: flex;
      font-size: 16px;
      .invoice-dialog-content-line-title {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .invoice-dialog-content-line-value {
        flex: 1;
        height: 100%;
        width: 0;
        display: flex;
        align-items: center;
        margin-left: 10px;
      }
      .invoice-dialog-content-line-span {
        width: 20px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .el-autocomplete {
    width: 100%;
  }
  .goods-dialog-content {
    width: 1200px;
    height: 600px;
    overflow-y: auto;
    display: flex;
    // align-items: center;
    // justify-content: flex-start;
    &::-webkit-scrollbar {
      width: 1px;
    }
  }
}
</style>
