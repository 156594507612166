<template>
  <div id="thread-setting">
    <!-- 商机设置 -->
    <div class="screen">
      <div class="screen-line">
        <div class="recyle-setting-screen-tabs">
          <template v-for="(item, index) in tabList"
            ><div
              :class="{
                'recyle-setting-screen-tab': true,
                'is-active': tabIndex == item.text,
              }"
              :key="index"
              v-showFunction="{
                active: item.active,
                array: allFunctionButtons,
              }"
              @click="changeTab(item, index)"
            >
              {{ item.text }}
            </div></template
          >
        </div>
      </div>
    </div>
    <div class="table-content">
      <div class="thread-table-card" v-loading="loading">
        <div
          class="thread-table-card-content"
          v-if="tabIndex == '选项值设置'"
          v-showFunction="{
            active: 'showOption',
            array: allFunctionButtons,
          }"
        >
          <div class="thread-table-card-content-tags-setting">
            <div class="thread-table-card-content-tags-setting-left">
              <div
                :class="{
                  'thread-table-card-content-tags-setting-left-line': true,
                  'is-active': index == tagsIndex,
                }"
                v-for="(item, index) in optionValueList1"
                :key="index"
                @click="tagsIndex = index"
              >
                {{ item.name }}
              </div>
            </div>
            <div class="thread-table-card-content-tags-setting-right">
              <div class="thread-table-card-content-tags-setting-right-add">
                <div
                  class="butt"
                  v-if="optionValueList1[tagsIndex].name != '客户来源'"
                  @click="addTags(1, -1)"
                >
                  添加选项
                </div>
                <div class="butt" v-else @click="addTags(2, -1)">
                  添加来源
                </div>
              </div>
              <div
                class="thread-table-card-content-tags-setting-right-list"
                v-if="optionValueList1[tagsIndex].value.length == 0"
              >
                没有相关数据!
              </div>
              <div
                class="thread-table-card-content-tags-setting-right-list"
                v-if="
                  optionValueList1[tagsIndex].name != '客户来源' &&
                    optionValueList1[tagsIndex].value.length > 0
                "
              >
                <div
                  class="thread-table-card-content-tags-setting-right-line"
                  v-for="(item, index) in optionValueList1[tagsIndex].value"
                  :key="index"
                >
                  <div
                    v-if="item.flag"
                    @click="
                      tabList[0].flag == 2 || !optionFlag
                        ? ''
                        : (item.flag = !item.flag)
                    "
                  >
                    {{ index + 1 }}、{{ item.name }}
                  </div>
                  <div v-else>
                    {{ index + 1 }}、
                    <el-input
                      v-model="item.name"
                      placeholder="请输入内容"
                      @blur="addTags(3, index)"
                      @keyup.enter.native="$event.target.blur"
                      :disabled="tabList[0].flag == 2 || !optionFlag"
                    ></el-input>
                  </div>
                </div>
              </div>
              <div
                class="thread-table-card-content-tags-setting-right-list"
                v-if="
                  optionValueList1[tagsIndex].name == '客户来源' &&
                    optionValueList1[tagsIndex].value.length > 0
                "
              >
                <div class="thread-table-card-content-tags-setting-right-line">
                  <div></div>
                  <div>来源</div>
                  <div>二级来源</div>
                  <div>启动</div>
                  <div>操作</div>
                </div>
                <div
                  class="thread-table-card-content-tags-setting-right-line"
                  v-for="(item, index) in optionValueList1[tagsIndex].value"
                  :key="index"
                >
                  <div>{{ index + 1 }}、</div>
                  <div
                    v-if="item.flag"
                    @click="
                      tabList[0].flag == 2 || !optionFlag
                        ? ''
                        : (item.flag = !item.flag)
                    "
                  >
                    {{ item.name }}
                  </div>
                  <div v-else>
                    <el-input
                      v-model="item.name"
                      placeholder="请输入内容"
                      @blur="addTags(4, index)"
                      @keyup.enter.native="$event.target.blur"
                      :disabled="tabList[0].flag == 2"
                    ></el-input>
                  </div>
                  <div v-if="item.flag">
                    <el-input
                      v-model="item.value[1]"
                      placeholder="请输入内容"
                      @blur="addTags(5, index)"
                      @keyup.enter.native="$event.target.blur"
                      :disabled="tabList[0].flag == 2 || !optionFlag"
                    ></el-input>
                  </div>
                  <div v-else>{{ item.value[1] }}</div>
                  <div>
                    <el-switch
                      v-model="item.active"
                      @change="addTags(5, index)"
                      :disabled="tabList[0].flag == 2 || !optionFlag"
                    >
                    </el-switch>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      plain
                      size="small"
                      @click="addTags(6, index)"
                      :disabled="tabList[0].flag == 2 || !optionFlag"
                      ><i class="el-icon-delete"></i>删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="thread-table-card-content"
          v-if="tabIndex == '商机保护'"
          v-showFunction="{ active: 'showProtect', array: allFunctionButtons }"
        >
          <div class="thread-table-card-content-con">
            <div class="thread-table-card-content-con_title">
              循环机制
              <div class="thread-table-card-content-con_title_tip">
                即商机保护时间,每次添加商机的跟进记录时,以最新的跟进时间为准(若无跟进记录,则以商机添加时间为准),每条商机最多保护时间,超过该时间,则该条商机会释放至商机池。
              </div>
            </div>
            <div class="thread-table-card-content-con_value">
              <el-input
                v-model="preserve"
                placeholder="请输入保护天数"
              ></el-input
              ><span>天</span>
            </div>
            <div
              class="thread-table-card-content-con_buttons"
              v-showFunction="{
                active: 'editProtect',
                array: allFunctionButtons,
              }"
            >
              <div
                class="thread-table-card-content-con_button"
                @click="updateProtectDay"
              >
                确定
              </div>
              <div
                class="thread-table-card-content-con_button"
                @click="getProtectDay(flase)"
              >
                取消
              </div>
            </div>
          </div>
          <div class="thread-table-card-content-con">
            <div class="thread-table-card-content-con_title">
              商机阈值
              <div class="thread-table-card-content-con_title_tip">
                每个人同时跟进商机的最大值。
              </div>
            </div>
            <div class="thread-table-card-content-con_value">
              <el-input
                v-model="threshold"
                placeholder="请输入保护阈值"
              ></el-input
              ><span>条</span>
            </div>
            <div
              class="thread-table-card-content-con_buttons"
              v-showFunction="{
                active: 'editProtect',
                array: allFunctionButtons,
              }"
            >
              <div
                class="thread-table-card-content-con_button"
                @click="updateMaxThread"
              >
                确定
              </div>
              <div
                class="thread-table-card-content-con_button"
                @click="getMaxThread"
              >
                取消
              </div>
            </div>
          </div>
        </div>
        <div
          class="thread-table-card-content"
          v-if="tabIndex == '异常电话'"
          v-showFunction="{ active: 'showPhone', array: allFunctionButtons }"
        >
          <div class="thread-table-card-content-search">
            <div class="line">
              <el-input
                placeholder="异常电话"
                v-model="searchData.input"
                @keyup.enter.native="getPeerPhoneList"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click.native.stop="getPeerPhoneList"
                ></el-button>
              </el-input>
            </div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line">
              <div
                class="but"
                @click="handleClick({}, 'import')"
                v-showFunction="{
                  active: 'importPhone',
                  array: allFunctionButtons,
                }"
              >
                导入
              </div>
              <div
                class="but"
                @click="handleClick({}, 'export')"
                v-showFunction="{
                  active: 'exportPhone',
                  array: allFunctionButtons,
                }"
              >
                导出
              </div>
              <div
                class="but"
                @click="handleClick({}, 'add')"
                v-showFunction="{
                  active: 'addPhone',
                  array: allFunctionButtons,
                }"
              >
                添加
              </div>
            </div>
          </div>
          <el-table
            :data="tableData"
            border
            style="width: 98%"
            height="650"
            @selection-change="handleSelectionChange"
            highlight-current-row
          >
            <el-table-column
              type="selection"
              width="35"
              fixed
            ></el-table-column>
            <el-table-column prop="phone" label="电话" width="250">
            </el-table-column>
            <el-table-column prop="userName" label="操作人" width="180">
            </el-table-column>
            <el-table-column prop="time" label="操作时间" width="180">
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column fixed="right" label="操作" width="250">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="handleClick(scope.row, 'edit')"
                  v-showFunction="{
                    active: 'editPhone',
                    array: allFunctionButtons,
                  }"
                  >编辑</el-button
                >
                <el-button
                  @click="handleClick(scope.row, 'delete')"
                  type="text"
                  size="small"
                  style="color: red"
                  v-showFunction="{
                    active: 'deletePhone',
                    array: allFunctionButtons,
                  }"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="thread-table-card-content-page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="pageData.currentPage"
              :page-size="pageData.pageSize"
              layout="total,sizes, prev, pager, next,jumper"
              :total="pageData.total"
            >
            </el-pagination>
          </div>
        </div>
        <div
          class="thread-table-card-content"
          v-if="tabIndex == '出访设置'"
          v-showFunction="{ active: 'showVisit', array: allFunctionButtons }"
        >
          <div class="thread-table-card-content-tags-setting">
            <div class="thread-table-card-content-tags-setting-left">
              <div class="thread-table-card-content-tags-setting-left-title">
                <span>全部标题</span>
                <span @click="editVisitTitle({}, 'add')"
                  ><i class="el-icon-circle-plus-outline"></i
                ></span>
              </div>
              <el-tree
                :data="visitData"
                node-key="id"
                :props="defaultProps"
                :expand-on-click-node="false"
                draggable
                :default-expand-all="false"
                @node-drop="handleDropLeft"
                @node-click="nodeClickLeft"
              >
                <template #default="{ data }">
                  <div
                    :class="{
                      'el-tree-left': true,
                      'is-active': visitIndex == data.sort,
                    }"
                  >
                    <div>
                      <span class="title">{{ data.label }}</span>
                    </div>
                    <div>
                      <span @click="editVisitTitle(data, 'edit')">
                        <i class="el-icon-edit-outline"></i>
                      </span>
                      <span
                        style="margin-left: 6px"
                        @click="editVisitTitle(data, 'delete')"
                      >
                        <i class="el-icon-close"></i>
                      </span>
                    </div>
                  </div> </template
              ></el-tree>
            </div>
            <div class="thread-table-card-content-tags-setting-right">
              <div class="thread-table-card-content-tags-setting-right-add">
                <div
                  class="butt"
                  @click="addVisitTag"
                  v-if="visitData[visitIndex].type !== '1'"
                >
                  添加选项
                </div>
              </div>
              <div
                class="thread-table-card-content-tags-setting-right-list"
                v-if="
                  visitData.length > 0 &&
                    visitData[visitIndex].options.length == 0
                "
              >
                该标题类型不可设置选项!
              </div>
              <div
                class="thread-table-card-content-tags-setting-right-list"
                v-if="
                  visitData.length > 0 &&
                    visitData[visitIndex].options.length > 0
                "
              >
                <el-tree
                  v-if="
                    visitData[visitIndex].options.constructor === Array &&
                      visitData[visitIndex].type !== '1'
                  "
                  :data="visitData[visitIndex].options"
                  node-key="id"
                  :props="defaultProps"
                  :expand-on-click-node="false"
                  draggable
                  :default-expand-all="false"
                  @node-drop="handleDropRight"
                >
                  <template #default="{ data, node }">
                    <!-- <span>{{ data.name }}</span> -->

                    <el-row
                      class="thread-table-card-content-tags-setting-right-tree-line"
                    >
                      <el-col
                        @click.native.stop="data.flag = false"
                        style="text-align: left;"
                      >
                        <span v-if="data.flag">
                          {{ data.index + 1 }}、{{ data.value }}
                        </span>
                        <div v-else>
                          <span> {{ data.index + 1 }}、</span>
                          <el-input
                            v-model="data.value"
                            @blur="updateVisitData(data, node)"
                            @keyup.enter.native="$event.target.blur"
                          ></el-input>
                        </div>
                      </el-col>
                    </el-row> </template
                ></el-tree>
              </div>
              <div
                class="thread-table-card-content-tags-setting-right-list"
                v-if="
                  optionValueList1[tagsIndex].name == '客户来源' &&
                    optionValueList1[tagsIndex].value.length > 0
                "
              >
                <div class="thread-table-card-content-tags-setting-right-line">
                  <div></div>
                  <div>来源</div>
                  <div>二级来源</div>
                  <div>启动</div>
                  <div>操作</div>
                </div>
                <div
                  class="thread-table-card-content-tags-setting-right-line"
                  v-for="(item, index) in optionValueList1[tagsIndex].value"
                  :key="index"
                >
                  <div>{{ index + 1 }}、</div>
                  <div
                    v-if="item.flag"
                    @click="
                      tabList[0].flag == 2 || !optionFlag
                        ? ''
                        : (item.flag = !item.flag)
                    "
                  >
                    {{ item.name }}
                  </div>
                  <div v-else>
                    <el-input
                      v-model="item.name"
                      placeholder="请输入内容"
                      @blur="addTags(4, index)"
                      @keyup.enter.native="$event.target.blur"
                      :disabled="tabList[0].flag == 2"
                    ></el-input>
                  </div>
                  <div v-if="item.flag">
                    <el-input
                      v-model="item.value[1]"
                      placeholder="请输入内容"
                      @blur="addTags(5, index)"
                      @keyup.enter.native="$event.target.blur"
                      :disabled="tabList[0].flag == 2 || !optionFlag"
                    ></el-input>
                  </div>
                  <div v-else>{{ item.value[1] }}</div>
                  <div>
                    <el-switch
                      v-model="item.active"
                      @change="addTags(5, index)"
                      :disabled="tabList[0].flag == 2 || !optionFlag"
                    >
                    </el-switch>
                  </div>
                  <div>
                    <el-button
                      type="danger"
                      plain
                      size="small"
                      @click="addTags(6, index)"
                      :disabled="tabList[0].flag == 2 || !optionFlag"
                      ><i class="el-icon-delete"></i>删除</el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        :allFunctionButtons="allFunctionButtons"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as threadSettingData from "@/assets/js/threadSetting.js";
import * as baseData from "@/assets/js/baseData.js";
import * as systemData from "@/assets/js/systemAuthorityData.js";
import my_dialog from "@/components/dialog.vue";
import * as threadData from "./js/threadData.js";
export default {
  components: {
    draggable,
    my_dialog,
  },
  data() {
    return {
      baseInfo: {},
      loading: true,
      tabIndex: "选项值设置",
      tagsIndex: 0,
      tabList: [
        {
          text: "选项值设置",
          name: "选项值设置",
          flag: 0,
          active: "showOption",
        },
        {
          text: "商机保护",
          name: "商机保护",
          flag: 0,
          active: "showProtect",
        },
        {
          text: "异常电话",
          name: "同行电话",
          flag: 0,
          active: "showPhone",
        },
        {
          text: "出访设置",
          name: "出访设置",
          flag: 0,
          active: "showVisit",
        },
      ],
      optionValueList1: [{ name: "", value: [{ name: "", value: "" }] }], // 选项值设置
      optionValueList2: [{ name: "", value: [{ name: "", value: "" }] }], // 流程设置
      optionValueList3: [{ name: "", value: [{ name: "", value: "" }] }], // 权限设置
      tempTagList: [],
      preserve: "15", // 商机保护天数
      threshold: "30", // 商机阈值

      tableData: [], // 同行电话
      searchData: {
        input: "", // 同行电话模糊搜索
      },
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      dialogData: {
        flag: false,
        width: "40%",
        title: "添加跟进记录",
        id: "",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },
      multipleSelection: [],
      peerFlag: 0, // 同行电话编辑
      optionFlag: true, // 选项值是否可编辑
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "threadSetting"
      ),
      defaultProps: {
        children: "children",
        label: "name",
      },
      visitData: [],
      visitRightData: [],
      visitIndex: 0,
    };
  },
  methods: {
    getModelFeatureAuthority() {
      console.log("获取权限");
      this.$authority.getModelFeatureAuthority(
        this,
        "threadSetting",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log("当前显示的按钮权限", data);
          // 单独处理编辑选项值  editOption
          for (let index = 0; index < data.length; index++) {
            let element = data[index];
            if (element.key == "editOption") {
              if (element.authority == "1") {
                this.optionFlag = true;
              } else {
                this.optionFlag = false;
              }
            }
          }
          console.log("当前选项值是否可以编辑:", this.optionFlag);
        }
      );
    },
    /**
     * 获取选项值标签
     */
    getTagList() {
      let requestData = {
        // model: "thread",
        type: [
          "跟进阶段",
          "片区",
          "客户来源",
          "增值税类型",
          "代账情况",
          "意向等级",
          "共享商机类型",
          "出访事由",
        ],
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/getTagList",
        requestData,
        (data) => {
          this.tempTagList = [];
          this.optionValueList1 = [];
          if (data.length == 0) {
            // 初次获取该模块标签,读取配置文件
            requestData.type.forEach((element) => {
              this.tempTagList.push(baseData.getTagList(element, "thread"));
            });
            baseData.initTagList(this.tempTagList, this.optionValueList1);
          } else {
            this.tempTagList = data;
            if (data.length != requestData.type.length) {
              baseData
                .initTagListPart(data, requestData.type)
                .forEach((element) => {
                  this.tempTagList.push(baseData.getTagList(element, "thread"));
                });
            }
            baseData.initTagList(this.tempTagList, this.optionValueList1);
          }
          console.log("当前模块标签:", this.optionValueList1);
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 修改标签
     */
    updateTagList(array) {
      let requestData = {
        list: array,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/updateTagList",
        requestData,
        (data) => {
          this.getTagList();
          this.$message({
            message: "修改成功!",
            type: "success",
            duration: 1000,
          });
        },
        (errorMessage) => {
          this.getTagList();
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 添加标签选项
     */
    addTags(flag, index) {
      switch (flag) {
        case 1:
          //  添加一般选项,即添加一个输入框
          let dd = {
            name: "",
            value: "",
            flag: false,
          };
          this.optionValueList1[this.tagsIndex].value.push(dd);
          break;
        case 2:
          //  添加表格选项,即添加一行表格数据
          console.log("添加选项", this.optionValueList1[this.tagsIndex]);
          let d = {
            name: "",
            value: ["", ""],
            flag: false,
            active: false,
          };
          this.optionValueList1[this.tagsIndex].value.push(d);
          break;
        case 3:
          //  输入框失去焦点事件
          console.log("添加选项", this.optionValueList1[this.tagsIndex]);
          this.optionValueList1[this.tagsIndex].value[index].flag = !this
            .optionValueList1[this.tagsIndex].value[index].flag;
          if (this.optionValueList1[this.tagsIndex].value[index].name != "") {
            this.optionValueList1[this.tagsIndex].value[
              index
            ].value = this.optionValueList1[this.tagsIndex].value[index].name;
          } else {
            this.optionValueList1[this.tagsIndex].value.splice(index, 1);
          }
          this.updateTagList(this.tempTagList);
          break;
        case 4:
          console.log("添加来源", this.optionValueList1[this.tagsIndex]);
          if (this.optionValueList1[this.tagsIndex].value[index].name != "") {
            this.optionValueList1[this.tagsIndex].value[
              index
            ].value[0] = this.optionValueList1[this.tagsIndex].value[
              index
            ].name;
            this.optionValueList1[this.tagsIndex].value[index].flag = !this
              .optionValueList1[this.tagsIndex].value[index].flag;
            this.updateTagList(this.tempTagList);
          } else {
            this.$message.error("一级来源不能为空!");
          }
          break;
        case 5:
          this.updateTagList(this.tempTagList);
          break;
        case 6:
          this.$confirm("此操作将永久删除该来源, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.optionValueList1[this.tagsIndex].value.splice(index, 1);
              this.updateTagList(this.tempTagList);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        default:
          break;
      }
    },
    /**
     * 切换tab
     */
    changeTab(data, index) {
      // if (this.tabList[index].flag < 3) {
      this.loading = true;
      this.tabIndex = data.text;
      switch (index) {
        case 0:
          // 选项值设置
          this.Loading();
          this.getTagList();
          break;
        case 1:
          // 商机保护
          // this.tabIndex = "商机保护";
          this.Loading();
          this.getProtectDay(true);

          break;
        case 2:
          // 同行电话
          // this.tabIndex = "异常电话";
          this.Loading();
          this.getPeerPhoneList();
          // this.$message.error("功能尚未开放!");
          // this.changeTab({ text: "选项值设置" }, 0);

          break;
        case 3:
          // 出访设置
          // this.tabIndex = "异常电话";
          this.Loading();
          this.getVisitList();
          break;
        default:
          break;
      }
      // } else {
      //   this.$message.error(
      //     this.$errorMessage.getErrorMessageInfo("authority")
      //   );
      // }
    },
    /**
     * 获取商机循环机制(商机保护天数)
     */
    getProtectDay(flag) {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/getProtectDay", requestData, (data) => {
        this.preserve = data;
        if (flag) {
          this.getMaxThread();
        }
      });
    },
    /**
     * 获取商机阈值
     */
    getMaxThread() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/getMaxThread", requestData, (data) => {
        this.threshold = data;
      });
    },
    /**
     * 修改商机循环机制(商机保护天数)
     */
    updateProtectDay() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        protectDay: this.preserve,
      };
      this.$request("post", "/user/updateProtectDay", requestData, (data) => {
        this.$message({
          message: "修改成功!",
          type: "success",
          duration: 1000,
        });
      });
    },
    /**
     * 修改商机阈值
     */
    updateMaxThread() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        maxThread: this.threshold,
      };
      this.$request("post", "/user/updateMaxThread", requestData, (data) => {
        this.$message({
          message: "修改成功!",
          type: "success",
          duration: 1000,
        });
      });
    },
    /**
     * 获取所有同行电话
     */
    getPeerPhoneList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId, //公司id
        phone: this.searchData.input, // 号码（可空）
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
      };
      this.$request(
        "post",
        "/peerPhone/getPeerPhoneList",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.tableData;
        }
      );
    },
    /**
     * 手动操作同行电话
     */
    handleClick(data, active) {
      let requestData = {};
      switch (active) {
        case "edit":
          // 编辑
          this.dialogData.title = "添加异常电话";
          this.dialogData.id = data.id;
          this.dialogData.data = [
            {
              type: "input",
              title: "手机号",
              value: data.phone,
            },
            {
              type: "input",
              title: "备注",
              value: data.remark,
            },
          ];
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "delete":
          // 删除
          this.$confirm("此操作将永久删除数据, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              requestData = {
                id: data.id,
              };
              this.$request(
                "post",
                "/peerPhone/delPeerPhone",
                requestData,
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getPeerPhoneList();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case "add":
          // 添加
          this.dialogData.title = "添加同行电话";
          this.dialogData.id = "";
          this.dialogData.data = [
            {
              type: "input",
              title: "手机号",
              value: "",
            },
            {
              type: "input",
              title: "备注",
              value: "",
            },
          ];
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.flag = true;
          break;
        case "export":
          // 导出
          if (this.multipleSelection.length == 0) {
            this.$message.error("请先选择想要导出的资源");
            return;
          }
          this.multipleSelection.forEach((element, i) => {
            requestData = i == 0 ? element.id : requestData + "," + element.id;
          });
          window.location.href =
            this.$baseurl + "/peerPhone/exportPeerPhone?phoneId=" + requestData;
          break;
        case "import":
          // 导入
          this.dialogData.title = "导入异常电话";
          this.dialogData.data = threadSettingData.improtResourcesDialogData(
            this
          );
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_improt_resources",
            },
          ];
          this.dialogData.flag = true;
          break;

        default:
          break;
      }
    },
    /**
     *
     */
    drawerOper(active) {
      let dd = {};
      let requestData = {};
      switch (active) {
        case "makesure_improt_resources":
          // 确定导入同行手机号
          requestData = {
            userId: this.baseInfo.userId,
            companyId: this.baseInfo.company.companyId,
            url: this.dialogData.data[0].value.url,
          };
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          this.$request(
            "post",
            "/peerPhone/importPeerPhone",
            requestData,
            (data) => {
              this.$message({
                message: data.msg,
                type: "success",
              });
              this.dialogData.flag = false;
              this.getPeerPhoneList();
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
              this.getPeerPhoneList();
            }
          );
          break;
        case "makesure_add":
          // 确定添加同行电话
          requestData = {
            id: this.dialogData.id, // 有则修改,空则添加
            companyId: this.baseInfo.company.companyId, // 公司id
            phone: this.dialogData.data[0].value, // 电话
            userId: this.baseInfo.userId, // 添加、修改人id
            remark: this.dialogData.data[1].value, // 备注
          };
          if (
            requestData.phone != "" &&
            !this.$phoneCheck.mobilePhoneNumberCheck(requestData.phone)
          ) {
            this.$message({
              message: "请填写正确格式的手机号!",
              type: "error",
            });
            return;
          }
          this.$request(
            "post",
            "/peerPhone/conservePeerPhone",
            requestData,
            (data) => {
              this.$message({
                message: "添加成功!",
                type: "success",
              });
              this.dialogData.flag = false;
              this.getPeerPhoneList();
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
              this.getPeerPhoneList();
            }
          );
          break;
        case "makesure_addVisitTitle":
          // 确定添加访问标题
          requestData = {
            model: "thread", // 模块名称，同一个模块新增时设置一样的即可
            label: "", // 标签名，用户自定义设置
            type: "", // 类型：'1' - 输入项；'2' - 单选项；'3' - 多选项
            options: JSON.stringify([]), // 可选项，当类型是选择项时由用户设置可选项，一般是JSON数组
            required: "", // 是否必填，0 - 否；1 - 是
          };
          for (let index = 0; index < this.dialogData.data.length; index++) {
            const element = this.dialogData.data[index];
            if (!element.value && element.value !== 0) {
              this.$message.error("请填写完整信息!");
              return;
            }
            requestData[element.key] = element.value;
          }
          this.$request(
            "post",
            "/labelSetting/add",
            requestData,
            (data) => {
              this.$message.success("添加成功!");
              this.dialogData.flag = false;
              this.getVisitList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "makesure_editVisitTitle":
          // 确定编辑回访标题
          requestData = {
            id: this.visitData[this.visitIndex].id, //
            model: "thread", // 模块名称，同一个模块新增时设置一样的即可
            label: "", // 标签名，用户自定义设置
            type: "", // 类型：'1' - 输入项；'2' - 单选项；'3' - 多选项
            options: JSON.stringify(this.visitData[this.visitIndex].options), // 可选项，当类型是选择项时由用户设置可选项，一般是JSON数组
            required: "", // 是否必填，0 - 否；1 - 是
          };
          for (let index = 0; index < this.dialogData.data.length; index++) {
            const element = this.dialogData.data[index];
            if (!element.value && element.value !== 0) {
              this.$message.error("请填写完整信息!");
              return;
            }
            requestData[element.key] = element.value;
          }
          this.$request(
            "post",
            "/labelSetting/update",
            requestData,
            (data) => {
              this.$message.success("更新成功!");
              this.dialogData.flag = false;
              this.getVisitList();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          // 关闭弹出框
          this.colseDialog();
          break;

        default:
          break;
      }
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.getPeerPhoneList();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getPeerPhoneList();
    },
    handleClose(tag, array) {
      array.splice(array.indexOf(tag), 1);
    },

    showInput(index, array) {
      this.optionValueList1[index].flag = true;
    },
    handleInputConfirm(value, index, array) {
      let inputValue = value;
      if (inputValue) {
        array.push(inputValue);
      }
      this.optionValueList1[index].flag = false;
      this.optionValueList1[index].value = "";
    },
    /**
     * 页面加载动画
     */
    Loading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    /**
     * 弹出框关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
      this.dialogData.data.forEach((element) => {
        if (element.value) {
          element.value = "";
        }
      });
    },
    /**
     * 多选框事件
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 获取出访设置列表
     */
    getVisitList() {
      this.$request(
        "get",
        "/labelSetting/queryByModel?model=thread",
        {},
        (data) => {
          this.visitData = data;
          this.visitData.forEach((item, index) => {
            if (item.options) {
              item.options = JSON.parse(item.options);
            }
          });
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 左侧标题点击
     */
    nodeClickLeft(data, node) {
      console.log("this.", this.visitData);
      console.log("data", data);
      this.visitIndex = data.sort;
    },
    /**
     * 左侧标题拖拽
     */
    handleDropLeft(draggingNode, dropNode, dropType) {
      if (dropType == "inner") {
        this.$message.error("不能拖拽到内部!");
        this.getVisitList();
        return;
      }
      let requestData = {
        model: "thread", // 模块名称
        begin: draggingNode.data.sort, // 开始序号
        end: dropNode.data.sort, // 结束序号
      };
      this.$request(
        "post",
        "/labelSetting/move",
        requestData,
        (data) => {
          this.$message.success("移动成功!");
          this.getVisitList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 右侧内容拖拽
     */
    handleDropRight(draggingNode, dropNode, dropType) {
      if (dropType == "inner") {
        this.$message.error("不能拖拽到内部!");
        this.getVisitList();
        return;
      }
      this.updateVisitData();
    },
    editVisitTitle(data, flag) {
      console.log("data", data);
      switch (flag) {
        case "add":
          this.dialogData.width = "30%";
          this.dialogData.title = "添加出访标题";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_addVisitTitle",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "标题",
              key: "label",
              type: "input",
              value: "",
            },
            {
              title: "类型",
              key: "type",
              type: "select",
              value: "",
              options: [
                {
                  label: "输入项",
                  value: "1",
                },
                {
                  label: "单选项",
                  value: "2",
                },
                {
                  label: "多选项",
                  value: "3",
                },
              ],
            },
            {
              title: "是否必填",
              key: "required",
              type: "radio",
              value: 0,
              options: [
                {
                  label: "是",
                  value: 1,
                },
                {
                  label: "否",
                  value: 0,
                },
              ],
            },
          ];
          this.dialogData.flag = true;
          break;
        case "edit":
          this.dialogData.width = "30%";
          this.dialogData.title = "添加出访标题";
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_editVisitTitle",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              title: "标题",
              key: "label",
              type: "input",
              value: data.label,
            },
            {
              title: "类型",
              key: "type",
              type: "select",
              value: data.type,
              options: [
                {
                  label: "输入项",
                  value: "1",
                },
                {
                  label: "单选项",
                  value: "2",
                },
                {
                  label: "多选项",
                  value: "3",
                },
              ],
            },
            {
              title: "是否必填",
              key: "required",
              type: "radio",
              value: data.required,
              options: [
                {
                  label: "是",
                  value: 1,
                },
                {
                  label: "否",
                  value: 0,
                },
              ],
            },
          ];
          this.dialogData.flag = true;
          break;
        case "delete":
          this.$confirm("此操作将删除该标题, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "get",
                "/labelSetting/del?id=" + data.id,
                {},
                (data) => {
                  this.$message.success("删除成功!");
                  this.getVisitList();
                  this.visitIndex = 0;
                },
                (errorMessage) => {
                  this.$message.error(errorMessage);
                }
              );
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    updateVisitData(data, node) {
      let requestData = this.visitData[this.visitIndex];
      requestData.options = requestData.options.filter((item) => item.value);
      requestData.options.forEach((item, index) => {
        item.flag = true;
        item.index = index;
        item.label = item.value;
      });
      requestData.options = JSON.stringify(requestData.options);
      this.$request(
        "post",
        "/labelSetting/update",
        requestData,
        (data) => {
          this.$message.success("更新成功!");
          this.getVisitList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    addVisitTag() {
      let dd = {
        flag: false,
        value: "",
        index: this.visitData[this.visitIndex].options.length,
      };
      this.visitData[this.visitIndex].options.push(dd);
    },
  },
  mounted() {
    this.getProtectDay(true);
    this.getTagList();
    this.loading = false;
  },
  created() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#thread-setting {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .recyle-setting-screen-tabs {
    width: 90%;
    height: 100%;
    display: flex;
    font-size: 14px;
    margin-left: 20px;
    .recyle-setting-screen-tab {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      transition: 0.3s;
    }
    .recyle-setting-screen-tab:hover {
      cursor: pointer;
      color: @activeColor;
    }
    .is-active {
      color: @activeColor;
      border-bottom: 4px solid @activeColor;
    }
  }
  .table-content {
    height: 80%;
    .thread-table-card {
      width: 98%;
      height: 98%;
      max-height: 830px;
      max-width: 90vw;
      overflow-y: auto;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;

      &::-webkit-scrollbar {
        width: 1px;
      }
      .thread-table-card-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .thread-table-card-content-line,
        .thread-table-card-content-line2 {
          width: 100%;
          flex: 1;
          // min-height: 200px;
          height: 200px;
          border-bottom: 1px solid @boderColor;
          display: flex;
          flex-direction: column;
          overflow: auto;
          &::-webkit-scrollbar {
            width: 1px;
          }
          .thread-table-card-content-line-title {
            width: 98%;
            height: 40px;
            margin-left: 20px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            span {
              height: 60%;
              display: flex;
              align-items: flex-end;
              color: @tipColor;
              font-size: @contentFontSize;
              margin-left: 10px;
            }
            .thread-table-card-content-line-title-save {
              width: auto;
              height: 30px;
              // margin-right: 20px;
              padding: 0 10px;
              border: 1px solid @activeColor3;
              background-color: @activeBackground;
              border-radius: 5px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.3s;
              font-size: 12px;
              &:hover {
                cursor: pointer;
                // transform: scale(1.02);
                background-color: @activeColor;
                color: #fff;
              }
            }
          }
          .thread-table-card-content-line-value {
            width: 95%;
            height: 90%;
            display: flex;
            margin-left: 40px;
            .el-tag + .el-tag {
              margin-left: 20px;
            }
            .button-new-tag {
              margin-left: 20px;
              height: 32px;
              line-height: 30px;
              padding-top: 0;
              padding-bottom: 0;
            }
            .input-new-tag {
              width: 90px;
              margin-left: 10px;
              vertical-align: bottom;
            }
            .div-tags {
              height: auto;
              width: 96%;
              display: flex;
              flex-wrap: wrap;
              & > div {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                & > span {
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                }
              }
              .div-tag {
                width: auto;
                min-width: 30px;
                height: 25px;
                margin: 10px;
                padding: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid @boderActiveColor;
                transition: 0.5s;
                font-size: @contentFontSize;
              }
              .div-tag:hover {
                cursor: pointer;
                border: 1px dashed @activeColor;
                background-color: @activeBackground;
                color: @activeColor;
              }
            }
            .thread-table-card-content-line-value-button {
              width: 98%;
              flex: 1;
              height: 0;
              // overflow: auto;
              display: flex;
              .thread-table-card-content-line-value-button-title {
                width: 15%;
                height: 98%;
              }
              .thread-table-card-content-line-value-button-values {
                flex: 1;
                height: 98%;
                overflow: auto;
                display: flex;
                flex-wrap: wrap;
                min-height: 190px;

                &::-webkit-scrollbar {
                  width: 1px;
                }
              }
            }
            .thread-table-card-content-line-value-button:nth-child(1) {
              // height: 120px;
              border-bottom: 1px solid @boderColor;
              margin-bottom: 10px;
              padding-bottom: 10px;
            }
          }
          .div-flex-column {
            flex-direction: column;
          }
          .div-flex-space-between {
            justify-content: space-between;
          }
        }
        .thread-table-card-content-line2 {
          max-height: 400px;
        }
        .thread-table-card-content-line {
          min-height: 200px;
        }

        .thread-table-card-content-tags-setting {
          width: 100%;
          height: 100%;
          display: flex;
          .thread-table-card-content-tags-setting-left {
            width: 8vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            border-right: 1px solid @activeColor3;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12),
              0 0 6px rgba(0, 0, 0, 0.04);
            .thread-table-card-content-tags-setting-left-line {
              width: 100%;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: @contentFontSize;
              transition: 0.3s;
              color: #000;
              &:hover {
                cursor: pointer;
                color: @activeColor;
              }
            }
            .thread-table-card-content-tags-setting-left-title {
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              background-color: #d1d1d1;
              padding: 10px 10px;
              cursor: pointer;
            }
            .el-tree-left {
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
              padding: 0 10px;
              .title {
                display: inline-block;
                width: 75px;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                white-space: nowrap;
                text-align: left;
              }
            }
            /deep/ .el-tree {
              overflow-y: auto;
              overflow-x: hidden;
              &::-webkit-scrollbar {
                width: 4px;
              }
            }
            /deep/ .el-tree-node__expand-icon.is-leaf {
              display: none !important;
            }
            .is-active {
              color: @activeColor;
            }
          }
          .thread-table-card-content-tags-setting-right {
            flex: 1;
            width: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .thread-table-card-content-tags-setting-right-add {
              width: 100%;
              height: 8vh;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding-left: 20px;
              .butt {
                width: 100px;
                height: 40px;
                border: 1px solid @activeColor;
                background-color: @activeColor;
                color: #fff;
                font-size: @contentFontSize;
                display: flex;
                align-items: center;
                border-radius: 10px;
                justify-content: center;
                transition: 0.3s;
                &:hover {
                  cursor: pointer;
                  background-color: @activeColor2;
                }
              }
            }
            .thread-table-card-content-tags-setting-right-list {
              flex: 1;
              height: 0;
              width: 97%;
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              overflow-x: hidden;
              &::-webkit-scrollbar {
                width: 1px;
              }
              .thread-table-card-content-tags-setting-right-line {
                height: 50px;
                min-height: 50px;
                width: 100%;
                display: flex;
                align-items: center;
                padding-left: 10px;
                box-sizing: border-box;
                border-bottom: 1px solid @boderColor;
                justify-content: space-between;
                & > div {
                  display: flex;
                  width: 100%;
                }
              }
              .thread-table-card-content-tags-setting-right-tree-line {
                width: 100%;
              }
            }
          }
        }
        .thread-table-card-content-con {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          margin: 50px;
          .thread-table-card-content-con_title {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            .thread-table-card-content-con_title_tip {
              color: rgb(250, 92, 0);
              font-size: 12px;
              font-weight: normal;
              margin-left: 10px;
              margin-top: 5px;
            }
          }
          .thread-table-card-content-con_value {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            .el-input {
              width: 220px !important;
              .el-input__inne {
                width: 100% !important;
              }
            }
            span {
              margin-left: 10px;
            }
          }
          .thread-table-card-content-con_buttons {
            width: 60%;
            height: auto;
            display: flex;
            margin-top: 10px;
            .thread-table-card-content-con_button {
              width: 90px;
              height: 30px;
              margin-left: 20px;
              background-color: rgb(53, 162, 250);
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 8px;
              transition: 0.3s;
              &:hover {
                background-color: rgb(46, 137, 211);
                cursor: pointer;
              }
            }
          }
        }
        .el-table {
          margin-top: 5px;
          margin-bottom: 5px;
        }
        .thread-table-card-content-search,
        .thread-table-card-content-page {
          width: 100%;
          height: 4vh;
          display: flex;
          align-items: center;
          // background-color: aqua;
          padding: 0 1%;
          box-sizing: border-box;
          > .line {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            > .but {
              width: auto;
              min-width: 50px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid @activeColor;
              font-size: @contentFontSize;
              background-color: @activeBackground;
              color: @activeColor;
              margin: 0 5px;
              margin-right: 10px;
              border-radius: 4px;
              transition: 0.5s;
              padding: 0 10px;
              box-sizing: border-box;
              &:hover {
                cursor: pointer;
                background-color: @activeBackground2;
              }
            }
          }
        }
      }
    }
  }
}
</style>
