<template>
  <div class="contract-invoice-dialog">
    <div class="contract-invoice-dialog-line">
      <div class="contract-invoice-dialog-line-title">开票金额:</div>
      <div class="contract-invoice-dialog-line-value">
        <el-input
          style="width: 150px"
          class="money"
          v-model="data.money"
          placeholder="请输入开票金额"
          :disabled="moneyFlag"
          @input="changeInput"
        >
          <span slot="prefix">¥</span>
        </el-input>
      </div>
    </div>
    <div class="contract-invoice-dialog-line" style="align-items: flex-start;">
      <div class="contract-invoice-dialog-line-title">发票抬头:</div>
      <div class="contract-invoice-dialog-line-value">
        <div class="contract-invoice-dialog-line-value-box">
          <div class="contract-invoice-dialog-line-value-box-icon">
            <i class="iconfont icon-fapiaoguanli"></i>
          </div>
          <div class="contract-invoice-dialog-line-value-box-content">
            <div class="content-line">
              <el-input
                style="width: 200px"
                v-model="data.buyerInvoiceData.name"
                :disabled="!editFlag"
              ></el-input>
              <span
                class="edit"
                v-if="!editFlag"
                @click="editFlag = !editFlag"
                v-showFunction="{
                  active: 'editInvoiceInfo',
                  array: allFunctionButtons,
                }"
                >编辑</span
              >
              <span
                class="edit"
                v-if="editFlag"
                v-showFunction="{
                  active: 'editInvoiceInfo',
                  array: allFunctionButtons,
                }"
                @click="searchTex"
                >检索</span
              >
              <span
                class="edit"
                v-if="editFlag"
                @click="editFlag = !editFlag"
                v-showFunction="{
                  active: 'editInvoiceInfo',
                  array: allFunctionButtons,
                }"
                >确定</span
              >
            </div>
            <div class="content-line">
              <el-input
                style="width: 250px"
                v-model="data.buyerInvoiceData.taxNum"
                :disabled="!editFlag"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allFunctionButtons: {
      type: Array,
      default: function() {
        return [];
      },
    },
    data: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      moneyFlag: true,
      editFlag: false,
    };
  },
  methods: {
    changeInput(e) {
      this.$forceUpdate();
    },
    searchTex() {
      this.flag1 = true;
      this.$request(
        "get",
        "/qicc/search?keyword=" + this.data.buyerInvoiceData.name,
        {},
        (data) => {
          this.data.buyerInvoiceData.name = data.name;
          this.data.buyerInvoiceData.taxNum = data.taxNum;
          this.data.buyerInvoiceData.address = data.address;
          this.data.buyerInvoiceData.bank = "";
          this.data.buyerInvoiceData.bankAccount = "";
          this.data.buyerInvoiceData.tel = "";
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    console.log("合肥峻岭财务有限公司", this.allFunctionButtons);
    this.allFunctionButtons.forEach((item) => {
      if (item.key == "editInvoiceInfo" && item.authority == "1") {
        this.moneyFlag = false;
      }
    });
  },
};
</script>

<style lang="less" scoped>
.contract-invoice-dialog {
  width: 100%;
  .contract-invoice-dialog-line {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .contract-invoice-dialog-line-title {
      min-width: 100px;
      text-align: right;
      margin-right: 10px;
    }
    .contract-invoice-dialog-line-value {
      .money {
        /deep/ .el-input__inner {
          border: none;
          background-color: #fff;
          padding-left: 18px;
          font-size: 16px;
        }
        /deep/ .el-input.is-disabled .el-input__inner {
          border: none;
          background-color: #fff;
          color: #2c3e50;
        }
      }
      .contract-invoice-dialog-line-value-box {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #d1d3d8;
        border-radius: 5px;
        padding: 15px;
        .contract-invoice-dialog-line-value-box-icon {
          .iconfont {
            font-size: 25px;
          }
        }
        .contract-invoice-dialog-line-value-box-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          min-width: 230px;
          .content-line {
            display: flex;
            align-items: center;
            margin: 3px;

            /deep/ .el-input__inner {
              font-size: 16px;
            }
            /deep/ .el-input.is-disabled .el-input__inner {
              border: none;
              background-color: #fff;
              color: #2c3e50;
            }
          }

          .edit {
            font-size: 14px;
            color: #409eff;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
