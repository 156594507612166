var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"table-payroll"}},[_c('div',{staticClass:"table-payroll-search"},[_c('div',{staticClass:"table-payroll-search-line"},[(_vm.data.type == 'date')?_c('el-date-picker',{attrs:{"type":"month","value-format":"yyyy-MM","placeholder":"选择月","clearable":false},on:{"change":_vm.resetPage},model:{value:(_vm.data.requestData.date),callback:function ($$v) {_vm.$set(_vm.data.requestData, "date", $$v)},expression:"data.requestData.date"}}):_vm._e(),(_vm.data.type == 'dataDate')?_c('el-date-picker',{attrs:{"type":"month","value-format":"yyyy-MM","placeholder":"选择月","clearable":false},on:{"change":_vm.resetPage},model:{value:(_vm.data.requestData.dataDate),callback:function ($$v) {_vm.$set(_vm.data.requestData, "dataDate", $$v)},expression:"data.requestData.dataDate"}}):_vm._e()],1)]),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"tableData",staticClass:"tableData-content",staticStyle:{"width":"1300px"},attrs:{"data":_vm.tableData,"header-cell-style":{ background: '#F5F7FA', color: '#606266' },"height":"500","row-style":{ height: '60px' },"border":"","show-summary":_vm.data.showSummary,"summary-method":_vm.summaryMethods}},[_c('el-table-column',{attrs:{"type":"index","width":"50","fixed":""}}),_vm._l((_vm.data.tableMenuData),function(item,index){return _c('el-table-column',{key:index,attrs:{"property":item.prop,"fixed":item.fixed,"label":item.title,"width":item.prop == 'clientName' ? 170 : item.width,"show-overflow-tooltip":true},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              item.prop == 'royaltyRate' ||
                item.prop == 'chargePersonRatio' ||
                item.prop == 'serviceConsultantRatio' ||
                item.prop == 'sponsorAccountingRatio' ||
                item.prop == 'accountManagerRatio' ||
                item.prop == 'divisionHeadRatio'
            )?_c('div',[_c('span',[_vm._v(" "+_vm._s(scope.row[item.prop])+"%")])]):(item.prop == 'contactNumber')?_c('div',[(
                scope.row.registeredUserId == _vm.baseInfo.userId ||
                  _vm.whetherAgencyHead
              )?_c('span',[_vm._v(_vm._s(scope.row.contactNumber))]):_c('span',[_vm._v("***********")])]):(item.prop == 'duration')?_c('div',[(scope.row.duration)?_c('span',[_vm._v(" "+_vm._s(scope.row.duration + "小时"))]):_c('span')]):(item.prop == 'status')?_c('div',[(scope.row.status == '未完成')?_c('span',{staticStyle:{"color":"#F56C6C","cursor":"pointer"},on:{"click":function($event){return _vm.showVisitDetail(scope.row)}}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v(_vm._s(scope.row.status))]):_vm._e(),(scope.row.status == '已完成')?_c('span',{staticStyle:{"color":"#67C23A","cursor":"pointer"},on:{"click":function($event){return _vm.showVisitDetail(scope.row)}}},[_c('i',{staticClass:"el-icon-edit"}),_vm._v(_vm._s(scope.row.status))]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(scope.row[item.prop])+" ")])]}}],null,true)})})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }