<template>
  <div id="report-financial">
    <!-- 收款报表 -->
    <div class="report-financial-screen">
      <div class="report-financial-screen-line">
        <div class="report-financial-screen-search">
          <el-input
            placeholder="交易单号"
            v-model="searchData.voucherNo"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>
        <div class="report-financial-screen-search">
          <el-input
            placeholder="客户名称"
            v-model="searchData.clientName"
            @keyup.enter.native="initTableData"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click.native.stop="initTableData"
            ></el-button>
          </el-input>
        </div>

        <div class="report-financial-screen-search">
          <el-select
            v-model="searchData.accountId"
            placeholder="请选择收款账户"
            @change="initTableData"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.accountList"
              :key="item.account"
              :label="item.owner"
              :value="item.account"
            >
            </el-option>
          </el-select>
        </div>
        <div class="report-financial-screen-search">
          <el-cascader
            v-model="searchData.productIds"
            :options="searchData.productList"
            placeholder="请选择服务项目"
            @change="initTableData"
            collapse-tags
            :props="{
              multiple: true,
              label: 'name',
              value: 'id',
              emitPath: false,
            }"
            clearable
          ></el-cascader>
        </div>

        <div class="report-financial-screen-search">
          <el-date-picker
            v-model="searchData.month"
            type="monthrange"
            value-format="yyyy-MM"
            range-separator="至"
            start-placeholder="开始月份"
            end-placeholder="结束月份"
            @change="initTableData"
            :clearable="false"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="report-financial-screen-line">
        <div
          class="report-financial-screen-search"
          style="display: flex; align-items: center; justify-content: start;"
        >
          <el-cascader
            style="width: 160px"
            v-model="searchData.department"
            placeholder="请选择部门"
            :options="searchData.departTreeData"
            @change="initTableData"
            clearable
            :show-all-levels="false"
            :props="{ checkStrictly: true, value: 'id' }"
            filterable
          ></el-cascader>
          <el-select
            style="width: 160px"
            v-model="searchData.userId"
            placeholder="请选择员工"
            @change="selectInitTableData2"
            filterable
            clearable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName == '全部' ? '全部人员' : item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <div
          class="report-financial-screen-search"
          style="display: flex; align-items: center; justify-content: start;"
        >
          <el-select
            style="width: 160px"
            v-model="searchData.roleId"
            placeholder="请选择角色"
            @change="initTableData"
            filterable
          >
            <el-option
              v-for="item in searchData.roleList"
              :key="item.authorityId"
              :label="item.authorityName"
              :value="item.authorityId"
            >
            </el-option>
          </el-select>
          <el-select
            v-if="searchData.roleId"
            style="width: 160px"
            v-model="searchData.roleUserId"
            placeholder="请选择员工"
            @change="selectInitTableData1"
            filterable
          >
            <el-option
              v-for="item in searchData.userList"
              :key="item.userId"
              :label="item.userName == '全部' ? '全部人员' : item.userName"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div
          class="
          report-financial-screen-search-left
          report-financial-screen-search"
        ></div> -->
        <div class="report-financial-screen-search">
          <el-checkbox-group
            v-model="searchData.receivedFlag"
            @change="initTableData"
          >
            <el-checkbox label="1">全部收款</el-checkbox>
            <el-checkbox label="2">部分收款</el-checkbox>
            <el-checkbox label="3">未收款</el-checkbox>
          </el-checkbox-group>
        </div>
        <div
          class="report-financial-screen-search"
          style="display: flex; align-items: center; marginLeft: 20px;"
          @change="initTableData"
        >
          <el-checkbox v-model="searchData.refundFlag">跨月退款</el-checkbox>
        </div>
        <div class="report-financial-screen-active"></div>
        <div
          class="report-financial-screen-but-boder"
          @click="handleClick({}, 1)"
          v-if="whetherAgencyHead"
        >
          导出
        </div>
      </div>
    </div>
    <div class="report-financial-table">
      <div class="report-financial-table-card">
        <div class="report-financial-table-card-tabs">
          <div
            :class="{
              'report-financial-table-card-tab': true,
              'is-active': tabindex == 0,
            }"
            @click="changeTab(0)"
          >
            全部收款报表
          </div>
          <div
            :class="{
              'report-financial-table-card-tab': true,
              'is-active': tabindex == 1,
            }"
            @click="changeTab(1)"
          >
            新签收款报表
          </div>
          <div
            :class="{
              'report-financial-table-card-tab': true,
              'is-active': tabindex == 2,
            }"
            @click="changeTab(2)"
          >
            续签收款报表
          </div>
          <div
            class="report-financial-table-card-active"
            v-if="whetherAgencyHead"
          >
            <div class="textArea">签约总金额:</div>
            <div class="numArea">{{ allMoney }}</div>
            <div class="textArea">合同成本:</div>
            <div class="numArea">{{ allCost }}</div>
            <div class="textArea">收款总金额:</div>
            <div class="numArea">{{ allPaymentCollect }}</div>
            <div class="textArea">未回款总金额:</div>
            <div class="numArea">{{ allAmountsOw }}</div>
            <div class="textArea">总利润:</div>
            <div class="numArea">{{ allProfits }}</div>
          </div>
        </div>
        <div class="report-financial-table-card-content">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            row-key="id"
            height="98%"
            :row-style="getTableChildrenStyle"
            v-loading="loading"
            :tree-props="{ children: 'children' }"
            show-summary
            :summary-method="getSummaries"
          >
            <el-table-column type="index" width="55" fixed> </el-table-column>
            <el-table-column
              v-for="(item, index) in tableMenuData"
              :key="index"
              :property="item.key"
              :fixed="index == 0"
              :label="item.title"
              :min-width="item.key == 'clientName' ? 250 : item.width"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row[item.key] }}
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="report-financial-table-card-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageData.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as reportData from "@/assets/js/report.js";
export default {
  data() {
    return {
      baseInfo: {},
      rowStyle: this.$rowStyle,
      whetherAgencyHead: false, // 是否是机构负责人
      loading: true,
      searchData: {
        input: "",
        checked: false,
        refundFlag: false,
        screenButton_export: 1, // 导出
        userId: "", // 旧的部门人员
        roleUserId: "", // 新的部门人员
        accountId: "", // 收款账号
        accountList: [], // 收款账号
        roleId: "", // 角色ID
        roleList: [],
        userList: [],
        tempUserId: [],
        roleUserList: [],
        departTreeData: [],
        department: "",
        productList: [],
        productIds: [],
        month: [
          this.$formatTime(new Date(), "YYYY-MM"),
          this.$formatTime(new Date(), "YYYY-MM"),
        ],
        voucherNo: "", // 交易单号
        clientName: "", // 客户名称
        receivedFlag: [],
      },
      tabindex: 0,

      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 10, // 每页条数
      },
      tableMenuData: reportData.getReportCollectionMenuData(),
      tableData: [],
      allMoney: "", // 签约总金额
      allPaymentCollect: "", // 收款总金额
      allAmountsOw: "", // 欠款总金额
      allProfits: "", // 总利润
      allCost: "", // 合同成本
      allFunctionButtons: this.$featureData.getAllFunctionButtons(
        "financialStatement"
      ),
    };
  },
  methods: {
    getModelFeatureAuthority() {
      this.$authority.getModelFeatureAuthority(
        this,
        "financialStatement",
        this.allFunctionButtons,
        (data) => {
          this.allFunctionButtons = data;
          console.log(this.allFunctionButtons, "按钮权限");
          this.isCharge();
        }
      );
    },
    /**
     * 判断当前登录者是否是机构负责人
     */
    isCharge() {
      let requestData = {
        userId: this.baseInfo.userId,
        companyId: this.baseInfo.company.companyId,
      };
      this.$request("post", "/user/isCharge", requestData, (data) => {
        this.whetherAgencyHead = data.flag;
        this.getEmployeeListByUserId();
      });
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getEmployeeListByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
        isDiMission: false,
        search: "",
      };
      this.$request(
        "post",
        "/user/getEmployeeListByUserId",
        requestData,
        (data) => {
          this.searchData.userList = data;
          this.tempAllEmpolyeeList = [];
          data.forEach((element) => {
            this.tempAllEmpolyeeList.push(element.userId);
          });
          this.searchData.tempUserId = this.tempAllEmpolyeeList;
          this.searchData.roleUserList = this.tempAllEmpolyeeList;
          this.searchData.userList.splice(0, 0, {
            userId: "all",
            userName: "全部人员",
          });
          this.initTableData();
          this.getBusinessAuthorityList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有业务角色列表
     */
    getBusinessAuthorityList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/role/getBusinessAuthorityList",
        requestData,
        (data) => {
          this.searchData.roleList = data;

          this.getAccountList();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有收款账号
     */
    getAccountList() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/companyAccount/getAccountList",
        requestData,
        (data) => {
          this.searchData.accountList = [];
          let dd = {};
          data.forEach((element) => {
            let account = "";
            element.accountList.forEach((ele) => {
              account =
                account == "" ? ele.account : account + "," + ele.account;
            });

            dd = {
              owner: element.owner,
              account: account,
            };
            this.searchData.accountList.push(dd);
          });

          console.log("获取所有收款账号:", this.searchData.accountList);
          this.getDepartmentTreeByUserId();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有下属列表 包括自己
     */
    getDepartmentTreeByUserId() {
      let requestData = {
        companyId: this.baseInfo.company.companyId,
        userId: this.baseInfo.userId,
      };
      this.$request(
        "post",
        // "/setting/getDepartmentTreeByUserId",
        "/department/getDepartmentTreeByUserId",
        requestData,
        (data) => {
          this.searchData.departTreeData = data;
          this.getAllProduct();
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 获取所有产品
     */
    getAllProduct() {
      this.searchData.productList = [];
      let requestData = {
        companyId: this.baseInfo.company.companyId,
      };
      this.$request(
        "post",
        "/servicesItem/getServeTypeAndItem",
        requestData,
        (data) => {
          this.searchData.productList = data;
        }
      );
    },
    handleClick(data, index) {
      switch (index) {
        case 1:
          // 导出收款报表
          let receivedFlag = [];
          if (this.searchData.receivedFlag.length == 0) {
            receivedFlag = ["1", "2", "3"];
          } else {
            for (
              let index = 0;
              index < this.searchData.receivedFlag.length;
              index++
            ) {
              const element = this.searchData.receivedFlag[index];
              switch (element) {
                case "全部收款":
                  receivedFlag.push("1");
                  break;
                case "部分收款":
                  receivedFlag.push("2");
                  break;
                case "未收款":
                  receivedFlag.push("3");
                  break;
                default:
                  break;
              }
            }
          }
          let requestData = {
            searchTime: this.searchData.month, // 每条合同的最后一笔收款时间
            productIds: this.searchData.productIds, // 产品
            department:
              this.searchData.department.length > 0
                ? this.searchData.department[
                    this.searchData.department.length - 1
                  ]
                : "",
            accountId: this.searchData.accountId,
            deUser: this.searchData.tempUserId, // 旧的部门人员
            roleUser: this.searchData.roleUserList, // 新的部门人员
            type: this.tabindex, // '1'- 全部；'2' - 新签；'3' - 续签
            role: this.searchData.roleId,
            companyId: this.baseInfo.company.companyId,
            clientName: this.searchData.clientName, // 客户名称
            voucherNo: this.searchData.voucherNo, // 交易单号
            receivedFlag: receivedFlag, // 1-全部收款 2-部分收款 3-未收款
            flag: "1",
          };
          this.$request(
            "post",
            "/common/setGetRequestParams",
            requestData,
            (data) => {
              window.location.href =
                this.$baseurl + "/report/exportFinancialStatement?flag=" + data;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );

          break;

        default:
          break;
      }
    },
    initTableData() {
      this.loading = true;
      let receivedFlag = [];
      let requestData = {
        searchTime: this.searchData.month, // 每条合同的最后一笔收款时间
        productIds: this.searchData.productIds, // 产品
        department:
          this.searchData.department.length > 0
            ? this.searchData.department[this.searchData.department.length - 1]
            : "",
        accountId: this.searchData.accountId,
        deUser: this.searchData.tempUserId, // 旧的部门人员
        roleUser: this.searchData.roleUserList, // 新的部门人员
        role: this.searchData.roleId,
        refundFlag: this.searchData.refundFlag, // 筛选项 退款
        currentPage: this.pageData.currentPage, // 当前页数
        pageSize: this.pageData.pageSize, // 每页条数
        companyId: this.baseInfo.company.companyId,
        clientName: this.searchData.clientName, // 客户名称
        voucherNo: this.searchData.voucherNo, // 交易单号
        receivedFlag: this.searchData.receivedFlag, // 1-全部收款 2-部分收款 3-未收款
        isRoyalty: false,
        flag: 0,
        type: this.tabindex, // '1'- 全部；'2' - 新签；'3' - 续签
      };
      this.$request(
        "post",
        "/report/financialStatement",
        requestData,
        (data) => {
          this.pageData.total = data.total;
          this.tableData = data.tableData;
          this.allMoney = data.allMoney;
          this.allCost = data.allCost;
          this.allPaymentCollect = data.allPaymentCollect;
          this.allAmountsOw = data.allAmountsOw;
          this.allProfits = data.allProfits;
          this.loading = false;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
          this.loading = false;
        }
      );
    },
    selectInitTableData1(val) {
      if (val == "all") {
        this.searchData.roleUserList = this.tempAllEmpolyeeList;
      } else {
        this.searchData.roleUserList = [val];
      }
      this.initTableData();
    },
    selectInitTableData2(val) {
      if (val == "all") {
        this.searchData.tempUserId = this.tempAllEmpolyeeList;
      } else {
        this.searchData.tempUserId = [val];
      }
      this.initTableData();
    },
    changeTab(index) {
      this.tabindex = index;
      this.initTableData();
    },
    handleCurrentChange(val) {
      this.initTableData();
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    getTableChildrenStyle(data) {
      let row = data.row;
      let style = { height: "65px", backgroundColor: "#ffffff" };
      if (row.onlyChildrenHave) {
        style.backgroundColor = "#ebeef5";
      }
      return style;
    },
    getSummaries(param) {
      const { columns, data } = param;

      const sums = [];
      columns.forEach((column, index) => {
        if (index == 1) {
          sums[index] = "合计";
          return;
        }
        if (
          index == 0 ||
          index == 2 ||
          index == 3 ||
          index == 9 ||
          index == 10 ||
          index == 11 ||
          index == 12 ||
          index == 13 ||
          index == 14 ||
          index == 15
        ) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            let sum = Number(Number(prev) + Number(curr)).toFixed(2); //toFixed(2)数据项保留两位小数
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = Math.round(sums[index] * 100) / 100;
        } else {
          sums[index] = "N/A";
        }
      });

      return sums;
    },
  },
  mounted() {
    this.loading = true;
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    this.searchData.userId = "all";
    this.searchData.roleUserId = "all";
    // this.searchData.tempUserId = ["all"];
    this.getModelFeatureAuthority();
  },
};
</script>

<style lang="less" scoped>
#report-financial {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .report-financial-screen {
    width: 100%;
    height: auto;
    border-bottom: 1px solid @boderColor;
    display: flex;
    align-items: center;
    flex-direction: column;

    .report-financial-screen-line {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      .el-checkbox-group {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .report-financial-screen-fiftrate:hover {
        cursor: pointer;
        border-color: @boderActiveColor;
      }

      .report-financial-screen-search {
        width: auto;
        min-width: 210px;
        height: 30px;
        margin-left: 10px;
        padding: 8px 0;
        &:nth-child(7n + 1) {
          margin-left: 8px;
          // margin-right: 15px;
        }
        /deep/ .collapse-tags {
          flex-wrap: nowrap;
        }
      }

      .report-financial-screen-search-left {
        margin-left: 0;
      }
      .report-financial-screen-active {
        flex: 1;
      }
      .report-financial-screen-but-select {
        width: 140px;
        min-width: 160px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
        cursor: pointer;
        .report-financial-screen-but-select_but {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: 0.5s;
        }
        .report-financial-screen-but-select_but:hover {
          background-color: @activeColor2;
        }
        .report-financial-screen-but-select_select {
          width: 30px;
          height: 100%;
          border-left: 1px solid @activeColor3;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .report-financial-screen-but {
        width: 100px;
        min-width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @activeColor;
        font-size: @contentFontSize;
        color: #fff;
        margin: 0 5px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .report-financial-screen-but:hover {
        cursor: pointer;
        background-color: @activeColor2;
      }
      .report-financial-screen-but-boder {
        width: 50px;
        min-width: 50px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid @activeColor;
        font-size: @contentFontSize;
        background-color: @activeBackground;
        color: @activeColor;
        margin: 0 5px;
        margin-right: 10px;
        border-radius: 4px;
        transition: 0.5s;
      }
      .report-financial-screen-but-boder:hover {
        cursor: pointer;
        background-color: @activeBackground2;
      }
    }
  }
  .report-financial-table {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @contentBackground;
    .report-financial-table-card {
      width: 98%;
      height: 95%;
      max-width: 90vw;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      .report-financial-table-card-tabs {
        width: 100%;
        height: 40px;
        border-bottom: 1px solid @boderColor;
        display: flex;
        font-size: @contentFontSize;
        .report-financial-table-card-tab {
          width: 130px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 5px;
          transition: 0.3s;
        }
        .report-financial-table-card-tab:hover {
          cursor: pointer;
          color: @activeColor;
        }
        .is-active {
          color: @activeColor;
          border-bottom: 2px solid @activeColor;
        }
        .report-financial-table-card-active {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: @contentFontSize;
          .textArea {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .numArea {
            height: 100%;
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: @activeColor;
          }
        }
        .report-financial-table-card-edit {
          width: 30px;
          min-width: 30px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: @editColor;
          cursor: pointer;
        }
      }
      .report-financial-table-card-content {
        flex: 1;
        width: 98%;
        .report-financial-table-card-content_text {
          width: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px;
          box-sizing: border-box;
          color: rgb(99, 211, 34);
          font-size: 12px;
        }
      }
      .report-financial-table-card-bottom {
        width: 100%;
        height: 40px;
        border-top: 1px solid @boderColor;
        display: flex;
        align-items: center;
        .el-input__inner {
          width: 100px !important;
        }
      }
    }
  }

  .table-tip {
    height: 30px;
    width: auto;
    padding: 2px 5px;
    margin: 0 3px;
    border: 1px solid #333;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hri {
    display: flex;
    i {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 3px;
      font-size: 14px;
      color: @activeColor;
    }
  }
  .link {
    color: @activeColor;
    transition: 0.3s;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .el-date-editor.el-input__inner {
    width: 300px !important;
  }
  .el-table__row--level-1 {
    background-color: #dddddd !important;
  }
}
</style>
