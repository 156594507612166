<template>
  <div id="bind-client-table">
    <div class="bind-client-table-line">
      <div class="bind-client-table-line-title">{{ value.clientName }}</div>
      <div class="bind-client-table-line-input"></div>
    </div>
    <div class="bind-client-table-line">
      <div class="bind-client-table-line-input">
        <el-input
          placeholder="请输入企业微信客户名称"
          v-model="search_client"
          @keyup.enter.native="searchData(0)"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click.native.stop="searchData(0)"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div class="bind-client-table-line">
      <el-table
        :data="value.tableData"
        border
        style="width: 100%"
        height="50vh"
      >
        <el-table-column prop="name" label="企业微信客户名称" align="center">
        </el-table-column>
        <el-table-column prop="type" label="联系人类型" align="center">
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="bindWechatClient(scope.row)"
              size="mini"
              :disabled="scope.row.flag == '1' || scope.row.flag == 1"
              >绑定</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["bindClientTableData"],
  data() {
    return {
      value: this.bindClientTableData,
      search_client: "",
      // value: {
      //   clientName: "张三",
      //   tableData: [
      //     {
      //       name: "张三",
      //       type: "微信用户", // 外部联系人的类型，1表示该外部联系人是微信用户，2表示该外部联系人是企业微信用户 (后台转)
      //       externalUserId: "", // 外部联系人的userID  企业微信获取
      //     },
      //   ],
      // },
      input: "",
    };
  },
  watch: {
    expendDetailsData() {
      //父组件值变化->>子组件也变化
      this.value = this.expendDetailsData;
      this.$forceUpdate();
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    bindWechatClient(data) {
      this.$emit("bindWechatClient", data);
    },
    searchData(flag) {
      this.$emit("searchData", { value: this.search_client, flag: flag });
    },
  },
};
</script>

<style lang="less" scoped>
#bind-client-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .bind-client-table-line {
    margin-top: 5px;
  }
}
</style>