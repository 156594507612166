/**
 * 获取发票开具dialog数据
 * @param {*} sellerData  // 当前客户信息  即销售方
 * @param {*} companyId   // 当前公司ID
 * @param {*} invoiceData  // 发票具体信息
 * @param {*} outputInvoice  // 是否是销项发票  true false
 */
export function getInvoiceDialogData( sellerData, companyId, invoiceData, outputInvoice) {
    console.log('sellerData', sellerData.clientInvoiceRemark)
    if (!invoiceData.buyerAddress) {
        if (invoiceData.buyerAddressTel) {
            let buyerAddressTel = invoiceData.buyerAddressTel.split(" ")
            invoiceData.buyerAddress = buyerAddressTel[0]
            invoiceData.buyerTel = buyerAddressTel[1]
        }
    }
    if (!invoiceData.buyerBankName) {
        if(invoiceData.buyerBankNameAccount) {
            let buyerBankNameAccount = invoiceData.buyerBankNameAccount.split(" ")
            invoiceData.buyerBankName = buyerBankNameAccount[0]
            invoiceData.buyerBankAccount = buyerBankNameAccount[1]
        }
    }
    let data = {
        channel: '0', // 保存渠道 '0'- 客户端 '1'- 用户端小程序 '2'- 客户端小程序
        id: invoiceData == "" ? "" : invoiceData.id, // id为空时,即为新增发票
        clientInvoiceRemark: outputInvoice ? sellerData.clientInvoiceRemark : (invoiceData == "" ? [] : invoiceData.clientInvoiceRemark), // 客户发票备注
        // clientInvoiceRemark: sellerData.clientInvoiceRemark, // 客户发票备注
        clientId: sellerData.clientId, // 客户id
        companyId: companyId, // 公司id
        // title: changeTitle(), // 发票标题
        type: invoiceData == "" ? sellerData.companyType == '一般纳税人' ? '81' : '82'  : invoiceData.type, // 发票类型
        paperTicketCode: invoiceData == "" ? '82' : invoiceData.paperTicketCode, // 纸质票种代码
        paperTicketCodeEcho: invoiceData == "" ? '' : JSON.parse(invoiceData.paperTicketCodeEcho), // 纸质票种代码
        showBuyerBankFlag: invoiceData == "" ? 'N' : invoiceData.showBuyerBankFlag, // 是否展示购方银行账号: y/Y-展示; 其它-不展示
        showBuyerAddressFlag: invoiceData == "" ? 'N' : invoiceData.showBuyerAddressFlag, // 是否展示购方地址电话: y/Y-展示; 其它-不展示
        showSellerBankFlag: invoiceData == "" ? 'N' : invoiceData.showSellerBankFlag, // 是否展示销方银行账号: y/Y-展示; 其它-不展示
        showSellerAddressFlag: invoiceData == "" ? 'N' : invoiceData.showSellerAddressFlag, // 是否展示销方地址电话: y/Y-展示; 其它-不展示
        allMoney: invoiceData == "" ? "0.00" : invoiceData.allMoney, // 合计金额
        allTax: invoiceData == "" ? '' : invoiceData.allTax , //  总税额
        discountFlag: false, // 合计折扣 false 不显示 true 显示
        allDiscountMoney: invoiceData == "" ? "0.00" : invoiceData.allDiscountMoney, // 合计折扣金额
        allDiscountTax: invoiceData == "" ? '0.00' : invoiceData.allDiscountTax , //  合计折扣税额
        totalAmount: invoiceData == "" ? "0.00" : invoiceData.totalAmount, // 发票总金额（价税合计）
        totalAmountText:  invoiceData == "" ? "0.00" : invoiceData.totalAmountText, // 发票总金额大写
        sellerName: outputInvoice ? sellerData.name : (invoiceData == "" ? "" : invoiceData.buyerName), // 客户名称  销售方名称
        sellerTaxpayerNum: outputInvoice ? sellerData.taxNum : (invoiceData == "" ? "" : invoiceData.buyerTaxpayerNum), // 客户税号  销售方纳税人识别号
        sellerValueAddedTaxType: outputInvoice ? sellerData.companyType : (invoiceData == "" ? "" : invoiceData.companyType), // 客户税号  销售方增值税类型
        sellerAddress: outputInvoice ? sellerData.address : (invoiceData == "" ? "" : invoiceData.buyerAddress), //销售方地址
        sellerTel: outputInvoice ? sellerData.tel : (invoiceData == "" ? "" : invoiceData.buyerTel), //销售方电话
        sellerBankName: outputInvoice ? sellerData.bank : (invoiceData == "" ? "" : invoiceData.buyerBankName), //销售方开户行
        sellerBankAccount: outputInvoice ? sellerData.bankAccount : (invoiceData == "" ? "" : invoiceData.buyerBankAccount), //销售方银行账号
        buyerName: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerName) : sellerData.name, // 购买方名称
        buyerTaxpayerNum: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerTaxpayerNum) : sellerData.taxNum, // 购买方纳税人识别号
        buyerAddress: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerAddress) : sellerData.address, // 购买方地址
        buyerTel: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerTel) : sellerData.tel, // 购买方电话
        buyerBankName: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerBankName) : sellerData.bank, // 购买方开户行
        buyerBankAccount: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerBankAccount) : sellerData.bankAccount, // 购买方银行账号
        takerTel: invoiceData == "" ? "" : invoiceData.takerTel, // 收票人手机
        takerEmail: invoiceData == "" ? "" : invoiceData.takerEmail, // 收票人邮箱
        itemName: invoiceData == "" ? "" : invoiceData.itemName, //主开票项目名称 可默认为开票项目列表的第一个项目名称
        casherName: invoiceData == "" ? '' : invoiceData.casherName, //收款人名称
        reviewerName: invoiceData == "" ? '' : invoiceData.reviewerName, //复核人名称
        drawerName: invoiceData == "" ? '' : invoiceData.drawerName, //开票人名称
        includeTaxFlag: invoiceData == "" ? 1 : ((invoiceData.includeTaxFlag == 'true' || invoiceData.includeTaxFlag == true) ? 1 : 0), // 含税标示  0：不含税，1：含税，默认为 1 含税
        includeTaxDisable: false,
        itemList: invoiceData == "" ? [
            // 开票项目列表
            {
                disabled: false,
                flag: true,
                goodsType: "", // 项目类别
                goodsName: "", // 项目名称  等价于itemName
                taxClassificationCode: "", // 对应税收分类编码
                specificationModel: "", // 对应规格型号
                meteringUnit: "", // 单位
                quantity: "", // 数量
                unitPrice: "", // 单价
                invoiceAmount: "0.00", // 金额
                taxRateValue:  sellerData.companyType == '小规模纳税人' ? 0.01 : 0.13, // 税率
                taxRateAmount: "0", // 税额 (税额 = 含税金额/(1+税率)*税率)
                deductionAmount: "", // 差额开票抵扣金额
                invoiceLineNature: '0', // 发票行性质 0 正常行 1 折扣行 2 被折扣行
                preferentialPolicyFlag: '1', // 优惠赠策标识 0 未使用 1 使用
                zeroTaxFlag: '0', // 零税率标识 0 代表正常税率 1 出口免税和其他免税优惠政策(免税）2 不征增值税（不征税）3 普通零税率 （0%） 
                vatSpecialManage: '简易征收', // 增值税特殊管理 简易征收 稀土产品 免税 不征税 先征后退
            },
        ] : invoiceData.itemList,
        remark: invoiceData == "" ? "" : invoiceData.remark, // 备注
        isUrgent: invoiceData == "" ? 0 : invoiceData.isUrgent, // 加急发票
        specialTicketCode: invoiceData == "" ? '' : invoiceData.specialTicketCode, // 特定业务标识
        specialField: invoiceData == "" ? '' : JSON.parse(invoiceData.specialField), // 特定业务字段
        specialFieldEcho: invoiceData == "" ? '' : JSON.parse(invoiceData.specialFieldEcho), // 特定信息
        collect: invoiceData == "" ? '0' : invoiceData.collect, // 征收方式
        balanceDocumentList: balanceDocumentList(), // 差额凭证类型
        collectField: invoiceData == "" ? [] : JSON.parse(invoiceData.collectField),
        collectFieldEcho: invoiceData == "" ? {
            pzlx: '', // 凭证类型
            fphm: '', // 发票号码
            kjrq: '', // 开具日期
            pzhjje: '', // 凭证合计金额
            bckcje: '', // 本次扣除金额
            bz: '', // 备注
        } : JSON.parse(invoiceData.collectFieldEcho), // 征收方式字段
    }
    return data
}

/**
 * 获取合同发票开具dialog数据
 * @param {*} clientData  // 当前客户信息  即购买方
 * @param {*} companyId   // 当前公司ID
 * @param {*} invoiceData  // 发票具体信息
 * @param {*} outputInvoice  // 是否是销项发票  true false
 * @param {*} sellData  // 销售方信息
 * @param {*} auditedIncome  // 当前收款单 收款金额
 */
export function getInvoiceDialogDataByContract(clientData, companyId, invoiceData, outputInvoice,  sellData, invoiceMoney, _this) {
    if (!invoiceData.buyerAddress) {
        if (invoiceData.buyerAddressTel) {
            let buyerAddressTel = invoiceData.buyerAddressTel.split(" ")
            invoiceData.buyerAddress = buyerAddressTel[0]
            invoiceData.buyerTel = buyerAddressTel[1]
        }
    }
    if (!invoiceData.buyerBankName) {
        if(invoiceData.buyerBankNameAccount) {
            let buyerBankNameAccount = invoiceData.buyerBankNameAccount.split(" ")
            invoiceData.buyerBankName = buyerBankNameAccount[0]
            invoiceData.buyerBankAccount = buyerBankNameAccount[1]
        }
    }
    
    let data = {
        channel: '0', // 保存渠道 '0'- 客户端 '1'- 用户端小程序 '2'- 客户端小程序
        id: invoiceData == "" ? "" : invoiceData.id, // id为空时,即为新增发票
        clientInvoiceRemark: invoiceData == "" ? "" : invoiceData.clientInvoiceRemark, // 客户发票备注
        clientId: sellData ? sellData.id : '', // 客户id
        companyId: companyId, // 公司 id
        companyType: clientData.companyType, // 公司类型
        // title: changeTitle(indexFlag), // 发票标题
        type: invoiceData == "" ? '82' : invoiceData.type, // 发票类型
        paperTicketCode: invoiceData == "" ? '82' : invoiceData.paperTicketCode, // 纸质票种代码
        paperTicketCodeEcho: invoiceData == "" ? '' : JSON.parse(invoiceData.paperTicketCodeEcho), // 纸质票种代码
        showBuyerBankFlag: invoiceData == "" ? 'N' : invoiceData.showBuyerBankFlag, // 是否展示购方银行账号: y/Y-展示; 其它-不展示
        showBuyerAddressFlag: invoiceData == "" ? 'N' : invoiceData.showBuyerAddressFlag, // 是否展示购方地址电话: y/Y-展示; 其它-不展示
        showSellerBankFlag: invoiceData == "" ? 'N' : invoiceData.showSellerBankFlag, // 是否展示销方银行账号: y/Y-展示; 其它-不展示
        showSellerAddressFlag: invoiceData == "" ? 'N' : invoiceData.showSellerAddressFlag, // 是否展示销方地址电话: y/Y-展示; 其它-不展示
        allMoney: invoiceData == "" ? "0.00" : invoiceData.allMoney, // 合计金额
        allTax: invoiceData == "" ? '' : invoiceData.allTax , //  总税额
        discountFlag: false, // 合计折扣 false 不显示 true 显示
        allDiscountMoney: invoiceData == "" ? "0.00" : invoiceData.allDiscountMoney, // 合计折扣金额
        allDiscountTax: invoiceData == "" ? '0.00' : invoiceData.allDiscountTax , //  合计折扣税额
        totalAmount: invoiceMoney, // 发票总金额（价税合计）
        totalAmountText:  invoiceData == "" ? "0.00" : invoiceData.totalAmountText, // 发票总金额大写
        sellerName: sellData ? sellData.clientName : '', // 客户名称  销售方名称
        sellerTaxpayerNum: sellData ? sellData.enterpriseNumber : '', // 客户税号  销售方纳税人识别号
        sellerAddress: sellData ? sellData.address : "", // 销售方地址
        sellerTel: sellData ? sellData.tel : '', // 销售方电话
        sellerBankName: sellData ? sellData.bankName : '', // 销售方开户行
        sellerBankAccount: sellData ? sellData.bankAccount : '', // 销售方银行账号
        sellerValueAddedTaxType: sellData ? sellData.addedValueTaxType : '', // 销售方增值税类型
        buyerName: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerName) : clientData.name, // 购买方名称
        buyerTaxpayerNum: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerTaxpayerNum) : clientData.taxNum, // 购买方纳税人识别号
        buyerAddress: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerAddress) : clientData.address, // 购买方地址
        buyerTel: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerTel) : clientData.tel, // 购买方电话
        buyerBankName: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerBankName) : clientData.bank, // 购买方开户行
        buyerBankAccount: outputInvoice ? (invoiceData == "" ? "" : invoiceData.buyerBankAccount) : clientData.bankAccount, // 购买方银行账号
        takerTel: invoiceData == "" ? "" : invoiceData.takerTel, // 收票人手机
        takerEmail: invoiceData == "" ? "" : invoiceData.takerEmail, // 收票人邮箱
        itemName: invoiceData == "" ? "" : invoiceData.itemName, //主开票项目名称 可默认为开票项目列表的第一个项目名称
        casherName: sellData ? sellData.casherName : '', //收款人名称
        reviewerName: sellData ? sellData.reviewerName : '', //复核人名称
        drawerName: sellData ? sellData.drawerName : '', //开票人名称
        includeTaxFlag: invoiceData == "" ? 1 : ((invoiceData.includeTaxFlag == 'true' || invoiceData.includeTaxFlag == true) ? 1 : 0), // 含税标示  0：不含税，1：含税，默认为 1 含税
        includeTaxDisable: false,
        
        itemList: invoiceData == "" ? [
            // 开票项目列表
            {
                flag: false,
                goodsType: "经纪代理服务", // 项目类别
                goodsName: "服务费", // 项目名称  等价于itemName
                taxClassificationCode: "3040802010200000000", // 对应税收分类编码
                specificationModel: "", // 对应规格型号
                meteringUnit: "", // 单位
                quantity: "", // 数量
                unitPrice: "", // 单价
                invoiceAmount: invoiceMoney, // 金额
                taxRateValue: sellData.addedValueTaxType == '小规模纳税人' ? 0.01 : 0.13, // 税率
                taxRateAmount: "0", // 税额 (税额 = 含税金额/(1+税率)*税率)
                deductionAmount: "", // 差额开票抵扣金额
                invoiceLineNature: '0', // 发票行性质 0 正常行 1 折扣行 2 被折扣行
                preferentialPolicyFlag: '1', // 优惠赠策标识 0 未使用 1 使用
                zeroTaxFlag: '0', // 零税率标识 0 代表正常税率 1 出口免税和其他免税优惠政策(免税）2 不征增值税（不征税）3 普通零税率 （0%） 
                vatSpecialManage: '简易征收', // 增值税特殊管理 简易征收 稀土产品 免税 不征税 先征后退
            },
        ] : invoiceData.itemList,
        remark: invoiceData == "" ? "" : invoiceData.remark, // 备注
        isUrgent: invoiceData == "" ? 0 : invoiceData.isUrgent, // 加急发票
        specialTicketCode: invoiceData == "" ? '' : invoiceData.specialTicketCode, // 特定业务标识
        specialField: invoiceData == "" ? '' : JSON.parse(invoiceData.specialField), // 特定业务字段
        specialFieldEcho: invoiceData == "" ? '' : JSON.parse(invoiceData.specialFieldEcho), // 特定信息
        collect: '0', // 征收方式
        balanceDocumentList: balanceDocumentList(), // 差额凭证类型
        collectField: invoiceData == "" ? [] : JSON.parse(invoiceData.collectField),
        collectFieldEcho: invoiceData == "" ? {
            pzlx: '', // 凭证类型
            fphm: '', // 发票号码
            kjrq: '', // 开具日期
            pzhjje: '', // 凭证合计金额
            bckcje: '', // 本次扣除金额
            bz: '', // 备注
        } : JSON.parse(invoiceData.collectFieldEcho), // 征收方式字段
    }
    data.totalAmountText = _this.$money.required(data.totalAmount); // 价税合计大写
    let nn = (parseFloat(data.itemList[0].invoiceAmount) /
      (1 + parseFloat(data.itemList[0].taxRateValue))) *
    parseFloat(data.itemList[0].taxRateValue)
    data.itemList[0].taxRateAmount = nn.toFixed(2) // 商品税额
    data.allTax =  nn.toFixed(2) // 总税额
    data.allMoney =  data.totalAmount - data.allTax // 总金额
    return data
}

/**
 * 发票预览
 * @param {Object} data 发票数据
 */
export function getInvoiveDetails(data) {
    let dialogData = {}
    let nowDate = ''
   if (data.billingDate) {
    nowDate = data.billingDate.split(' ')[0]
   } // 开票日期 开票之后才有
    if (data != "") {
        dialogData = {
            channel: data.channel, // 保存渠道 '0'- 客户端 '1'- 用户端小程序 '2'- 客户端小程序
            id: data.id, // 发票ID
            type: data.type, // 发票类型
            status: data.status, // 发票状态
            clientId: data.clientId, // 客户ID
            title: changeTitle(data.type), // 发票标题
            loadingBtn: false, // 控制推送开具loading效果
            flag: data.flag || false, // 是否为发票审核页面 是 为 true
            includeTaxFlag: data.includeTaxFlag, // 是否含税：0-不含税；1-含税
            invoiceNumber: data.invoiceNumber, // 发票号码
            allTax: data.allTax, // 发票总税额
            allMoney: data.allMoney, // 发票总金额（不含税）
            takerTel: data.takerTel, // 收票人手机
            takerEmail: data.takerEmail, // 收票人邮箱
            totalAmount: data.totalAmount, // 发票总金额（价税合计）
            totalAmountText:  data.totalAmountText, // 发票总金额大写
            sellerName: data.sellerName, // 客户名称  销售方名称
            sellerTaxpayerNum: data.sellerTaxpayerNum, // 客户税号  销售方纳税人识别号
            qrCode: data.qrCode, // 二维码 电子发票才有  开票成功后才有
            securityCode: data.securityCode, // 防伪码(校验码)  开票成功后才有 普票使用  增值税发票为空
            invoiceDate: nowDate, // 开票日期  开票成功后才有
            buyerName: data.buyerName, // 购买方名称
            buyerTaxpayerNum: data.buyerTaxpayerNum, // 购买方纳税人识别号
            buyerAddress: data.buyerAddress, // 购买方地址
            buyerTel: data.buyerTel, // 购买方电话
            buyerAddressTel: data.buyerAddress + data.buyerTel, // 购买方地址、电话
            buyerBankName: data.buyerBankName,  // 购买方开户行
            buyerBankAccount: data.buyerBankAccount, // 购买方银行账号
            buyerBankName: data.buyerBankName, // 购买方开户行
            buyerBankAccount: data.buyerBankAccount, // 购买方银行账号
            buyerBankNameAccount: data.buyerBankName + data.buyerBankAccount, // 购买方开户行、银行账号
            sellerAddress: data.sellerAddress, // 销售方地址
            sellerTel: data.sellerTel, // 销售方电话
            sellerAddressTel: data.sellerAddress + data.sellerTel, //销售方地址、电话   客户公司地址
            sellerBankName: data.sellerBankName, //销售方开户行
            sellerBankAccount: data.sellerBankAccount, //销售方银行账号
            sellerBankNameAccount:data.sellerBankName + data.sellerBankAccount, // 销售方开户行、银行账号
            showBuyerBankFlag: data.showBuyerBankFlag,  // 是否展示购方银行账号: y/Y-展示; 其它-不展示
            showBuyerAddressFlag: data.showBuyerAddressFlag, // 是否展示购方地址电话: y/Y-展示; 其它-不展示
            showSellerBankFlag: data.showSellerBankFlag,  // 是否展示销方银行账号: y/Y-展示; 其它-不展示
            showSellerAddressFlag: data.showSellerAddressFlag, // 是否展示销方地址电话: y/Y-展示; 其它-不展示
            itemName: data.itemName, //主开票项目名称 可默认为开票项目列表的第一 个项目名称
            casherName: data.casherName == "{}" || data.casherName == "" ? "" : data.casherName, //收款人名称
            reviewerName: data.reviewerName == "{}" || data.reviewerName == "" ? "" : data.reviewerName, //复核人名称
            drawerName: data.drawerName == "{}" || data.drawerName == "" ? "" : data.drawerName, //开票人名称
            itemList: data.itemList,
            submitRemark: data.submitRemark,  // 发票备注 开票之后才有
            remark: data.remark, // 备注
            specialTicketCode: data.specialTicketCode ? data.specialTicketCode : '', // 特定业务标识
            specialField: data.specialField ? JSON.parse(data.specialField) : '', // 特定业务字段
            specialFieldEcho: data.specialFieldEcho ? JSON.parse(data.specialFieldEcho) : '', // 特定信息
            collect: data.collect ? data.collect : '', // 征收方式
            collectField: data.collectField ? JSON.parse(data.specialFieldEcho) : '', // 征收方式字段
            collectFieldEcho: data.collectFieldEcho ? JSON.parse(data.specialFieldEcho) : '', // 征收信息
        }
    } else {
        dialogData = ""
    }
    return dialogData
}

/**
 * 获取客户开出的所有销项发票
 * @param {*} data  发票表格数据
 * @param {*} clientName  客户名称
 * @param {*} buyerName 搜索项  购买方名称
 * @param {*} time 搜索项  时间
 * @param {*} type 搜索项  发票类型
 */ 
export function getInvoiceDrawerData(data, clientName, buyerName, time,  type) {
    let item_data = {
        line: [{
            horizontal: true,
            isShow: true,
            isDisable: false,
            title: "",
            type: "table",
            summary: false,
            menu: [
              {
                  key: "buyerName",
                  value: "购买方名称",
                  type: "link",
                  width: "200",
              }, {
                  key: "status",
                  value: "发票状态",
                  type: "text",
                  width: "100",
              }, {
                  key: "type",
                  value: "发票种类",
                  type: "invoiceType",
                  width: "100",
              },{
                  key: "totalAmount",
                  value: "总金额",
                  type: "text", 
                  width: "100",
              }, {
                  key: "billingDate",
                  value: "开票日期",
                  type: "text",
                  width: "100",
              },
              // {
              //     value: "操作",
              //     type: "delete",
              //     width: "50",
              //     buts:[{
              //         icon: 'el-icon-delete',
              //     }]
              // },
            ],
            tableData: []
        }]
    }
    let invoiceDrawerData = {
        title: "发票",
        flag: false,
        hasSetting: false,
        settingOperList: [],
        buttonList: [],
        data: {
            title: clientName,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            allTotalAmount: '', // 总金额
            buyerName: buyerName || '', // 购买方名称
            time: time || [], // 筛选时间
            type: type || [], // 发票类型
            options: [
                {
                  label: "普票",
                  value: "普票",
                  children: [
                    {
                      label: "数电普票",
                      value: "82",
                    },
                    {
                      label: "数电纸票",
                      value: "86",
                    },
                  ],
                },
                {
                  label: "专票",
                  value: "专票",
                  children: [
                    {
                      label: "数电专票",
                      value: "81",
                    },
                    {
                      label: "数电纸专",
                      value: "85",
                    },
                  ],
                },
              ], // 搜索项 发票类型筛选列表
            butArray: [{
                text: "导出",
                active: "export"
            }], 
            modelList: []
        },
        CardList: [
            {
                text: "全部",
                active: "全部",
                key: "-1"
            },
            {
                text: "未开票",
                active: "未开票",
                key: "01"
            },
            {
                text: "开票成功",
                active: "开票成功",
                key: "02"
            },
            {
                text: "开票失败",
                active: "开票失败",
                key: "03"
            },
        ],
        cardIndex: 0,
        }
        if (data.length > 0) {
            invoiceDrawerData.data.modelList = [],
            invoiceDrawerData.data.allTotalAmount = '',
            data = transTableData(data)
            invoiceDrawerData.data.allTotalAmount = data.reduce((prev, item) => prev + item.totalAmount, 0)
            item_data.line[0].tableData = data
            invoiceDrawerData.data.modelList.push(JSON.parse(JSON.stringify(item_data)))
        }
        return invoiceDrawerData
}

/**
 * 发票 电子税务局身份
 */
export function taxPosition() {
    return  [
        {
          label: "法定代表人",
          value: "01",
        },
        {
          label: "财务负责人",
          value: "02",
        },
        {
          label: "办税员",
          value: "03",
        },
        {
          label: "管理员",
          value: "05",
        },
        {
          label: "社保经办人",
          value: "08",
        },
        {
            label: "开票员",
            value: "09",
        },
        {
          label: "销售人员",
          value: "10",
        },
    ]
}

/**
 * 发票 运输工具种类
 */
export function transportationList() {
    return [
        {
            label: "铁路运输",
            value: 1
        },{
            label: "公路运输",
            value: 2
        },{
            label: "水路运输",
            value: 3
        },{
            label: "航空运输",
            value: 4
        },{
            label: "管道运输",
            value: 5
        },{
            label: "其他运输工具",
            value: 6
        }
    ]
}

/**
 * 发票 获取运输工具种类
 */
export function getTransportation(value) {
    let list = transportationList()
    let type = ''
    list.forEach(item => {
        if(item.value == value) {
            type = item.label
        }
    });
    return type
}

/**
 * 发票 不动产单位
 */
export function acreageList() {
    return [
        {
            label: "平方千米",
            value: 1
        },{
            label: "平方米",
            value: 2
        },{
            label: "孔公里",
            value: 3
        },{
            label: "公顷",
            value: 4
        },{
            label: "亩",
            value: 5
        },{
            label: "h ㎡",
            value: 6
        },{
            label: "k ㎡",
            value: 7
        },{
            label: "㎡",
            value: 8
        }
    ]
}

/**
 * 发票 身份证类型
 */
export function cardList() {
    return [
        {
            label: "居民身份证",
            value: 201
        },{
             label: "组织机构代码证",
             value: 101
        },{
            label: "营业执照",
            value: 102
        },{
            label: "税务登记证",
            value: 103
        },{
            label: "其他单位证件",
            value: 199
        },{
            label: "军官证",
            value: 202
        },{
            label: "武警警官证",
            value: 203
        },{
            label: "士兵证",
            value: 204
        },{
            label: "军队离退休干部证",
            value: 205
        },{
            label: "残疾人证",
            value: 206
        },{
            label: "残疾军人证 (1-8 级)",
            value: 207
        },{
            label: "外国护照",
            value: 208
        },{
            label: "港澳居民来往内地通行证",
            value: 210
        },{
            label: "中华人民共和国往来港澳通行证",
            value: 212
        },{
            label: "台湾居民来往大陆通行证",
            value: 213
        },{
            label: "大陆居民往来台湾通行证",
            value: 214
        },{
            label: "外国人居留证",
                value: 215
        },{
            label: "外交官证",
            value: 216
        },{
            label: "使（领事）馆证",
            value: 217
        },{
            label: "海员证",
            value: 218
        },{
            label: "香港永久性居民身份证",
            value: 219
        },{
            label: "台湾身份证",
            value: 220
        },{
            label: "澳门特别行政区永久性居民身份证",
            value: 221
        },{
            label: "外国人身份证件",
            value: 222
        },{
            label: "就业失业登记证",
            value: 224
        },{
            label: "退休证",
            value: 225
        },{
            label: "离休证",
            value: 226
        },{
            label: "中国护照",
            value: 227
        },{
            label: "城镇退役士兵自谋职业证",
            value: 228
        },{
            label: "随军家属身份证明",
            value: 229
        },{
            label: "中国人民解放军军官转业证书",
            value: 230
        },{
            label: "中国人民解放军义务兵退出现役证",
            value: 231
        },{
            label: "中国人民解放军士官退出现役证",
            value: 232
        },{
            label: "外国人永久居留身份证（外国人永久居留证）",
            value: 233
        },{
            label: "就业创业证",
            value: 234
        },{
            label: "香港特别行政区护照",
            value: 235
        },{
            label: "澳门特别行政区护照",
            value: 236
        },{
            label: "中华人民共和国港澳居民居住证",
            value: 237
        },{
            label: "中华人民共和国台湾居民居住证",
            value: 238
        },{
            label: "《中华人民共和国外国人工作许可证》(A类)",
            value: 239
        },{
            label: "《中华人民共和国外国人工作许可证》(B类)",
            value: 240
        },{
            label: "《中华人民共和国外国人工作许可证》(C类)",
            value: 241
        },{
            label: "医学出生证明",
            value: 291
        },{
            label: "其他个人证件",
            value: 299
        }
    ]
}

/**
 * 发票 农产品收购证件类型
 */
export function cardListByFarming() {
    return [
        {
            label: "税务登记证",
            value: 103
        },{
            label: "居民身份证",
            value: 201
        },{
            label: "外国护照",
            value: 208
        },{
            label: "港澳居民来往内地通行证",
            value: 210
        },{
            label: "台湾居民来往大陆通行证",
            value: 213
        },{
            label: "外国人居留证",
            value: 215
        },{
            label: "香港永久性居民身份证",
            value: 219
        },{
            label: "澳门特别行政区永久性居民身份证",
            value: 221
        },{
            label: "外国人永久居留身份证（外国人永久居留证）",
            value: 233
        },{
            label: "其他个人证件",
            value: 299
        }
    ]
}

/**
 * 发票 切换发票类型
 * @param {String} type 发票类型代码
 */
export function changeTitle(type) {
    let title = ""
    switch (type) {
        case "81":
            title = "电子发票 （增值税专用发票）";
            break;
        case "82":
            title = "电子发票（普通发票）";
            break;
        case "85":
            title = "纸质发票 （增值税专用发票）";
            break;
        case "86":
            title = "纸质发票 （普通发票）";
            break;
        default:
            break;
    }
    return title
}

/**
 * 发票 特定业务列表数据
 */
export function invoiceAffair() {
    return  [
        {
          label: "建筑服务",
          value: "03",
        },
        {
          label: "货物运输服务",
          value: "04",
        },
        {
          label: "不动产销售",
          value: "05",
        },
        {
          label: "不动产经营租赁服务",
          value: "06",
        },
        {
          label: "成品油服务",
          value: "01",
        },
        {
          label: "旅客运输服务",
          value: "09",
        },
        {
          label: "农产品收购",
          value: "16",
        },
        {
          label: "自产农产品销售",
          value: "12",
        },
        {
          label: "拖拉机和联合收割机",
          value: "13",
        },
    ]
}

/**
 * 发票 征收方式列表数据
 */
export function invoiceCollect() {
    return  [
        {
          label: "普通征税",
          value: "0",
        },
        {
          label: "差额征税-全额开票",
          value: "2",
        },
        {
          label: "差额征税-差额开票",
          value: "3",
        },
      ]
}

/**
 * 发票 差额凭证类型
 */
export function balanceDocumentList() {
    return  [
        {
          label: "数电发票",
          value: "01",
          hsxsje: [
            { required: true, message: "请输入含税销售额", trigger: 'blur' },
          ],
          fphm: [
            { required: true, message: "请输入数电票号码", trigger: "blur" },
          ],
          kjrq: [{ required: true, message: "请输入开具日期", trigger: "blur" }],
          pzhjje: [
            { required: true, message: "请输入凭证合计金额", trigger: "blur" },
          ],
          bckcje: [
            { required: true, message: "请输入本次扣除金额", trigger: "blur" },
          ],
        },
        {
          label: "其他扣除凭证",
          value: "09",
          hsxsje: [
            { required: true, message: "请输入含税销售额", trigger: "blur" },
          ],
  
          pzhjje: [
            { required: true, message: "请输入凭证合计金额", trigger: "blur" },
          ],
          bckcje: [
            { required: true, message: "请输入本次扣除金额", trigger: "blur" },
          ],
          bz: [{ required: true, message: "请输入备注", trigger: "blur" }],
        },
      ]
}

/**
 * 发票 全电纸质发票票种代码
 * @param {String} type 发票类型代码
 */
export function paperTicketCodeEcho(type) {
    let data = {
        list: [],
        code: ''
    }
    switch (type) {
        case '86':
            // 数电纸普
            data.list = [
                {
                    label: '2016 版增值税普通发票（二联折叠票）',
                    value: '04'
                },
                {
                    label: '2016 版增值税普通发票（五联折叠票）',
                    value: '05'
                },
                {
                    label: '2008 版增值税普通发票（二联无金额限制版）',
                    value: '000008101200'
                }
            ]
            data.code = data.list[0].value
            break;
        case '85':
            // 数电纸专
            data.list = [
                {
                    label: '增值税专用发票（中文三联无金额限制版）',
                    value: '1130'
                },
                {
                    label: '增值税专用发票(中文六联无金额限制版)',
                    value: '1160'
                },
            ]
            data.code = data.list[0].value
            break;
        default:
            break;
    }
    return data
}

/**
 * 发票 特定信息
 * @param {String} specialTicketCode 
 */
export function changeSpecialFieldEcho(specialTicketCode) {
    let title = ''
    invoiceAffair().forEach(item => {
        if (item.value === specialTicketCode) {
            title = item.label
            }
    })
    let data = {}
    switch (specialTicketCode) {
        case '03':
            // 建筑服务
            data = {
                title,
                list: [
                    {
                        title: "土地增值税项目编号",
                        type: "input",
                        key: "tdzzsxmbh",
                        is_require: false,
                        value: "",
                    },
                    {
                        title: "建筑服务发生地",
                        type: "area",
                        key: "fwfsd",
                        is_require: true,
                        disabled: true,
                        value: "",
                    },{
                        title: "详细地址",
                        type: "input",
                        key: "fullAddress",
                        is_require: false,
                        value: "",
                    },{
                        title: "建筑项目名称",
                        type: "input",
                        key: "jzxmmc",
                        is_require: true,
                        value: "",
                    },{
                        title: "跨地（市）标志",
                        type: "select",
                        key: "kdsbz",
                        is_require: true,
                        value: "N",
                        options: [
                            {
                                label: "是",
                                value: 'Y'
                            },
                            {
                                label: "否",
                                value: 'N'
                            }
                        ]
                    }, {
                        title: "跨区域涉税事项报验管理编号",
                        type: "input",
                        key: "kqysssxbgglbm",
                        is_require: false,
                        value: "",
                        click: true
                    }
                ]
            }
            break;
        case '04':
            // 货物运输服务
            data = {
                title,
                list: [
                    {
                        title: "",
                        type: "table",
                        key: "hwysfw",
                        is_require: false,
                        value: [
                            {
                                ysgjzl: '',
                                ysgjhp: "",
                                qyd: "",
                                ddd: "",
                                yshwmc: "",
                            },
                          ],
                        tableData: [
                            {
                                label: "运输工具种类",
                                prop: 'ysgjzl',
                                type: 'select',
                                width: "",
                                icon: '',
                                options: transportationList()
                            },
                            {
                                label: "运输工具牌号",
                                prop: 'ysgjhp',
                                type: 'input',
                                width: "",
                                icon: '',
                            },
                            {
                                label: "起运地",
                                prop: 'qyd',
                                type: 'input',
                                disabled: true,
                                width: "",
                                icon: 'el-icon-location-information',
                            },
                            {
                                label: "到达地",
                                prop: 'ddd',
                                type: 'input',
                                disabled: true,
                                width: "",
                                icon: 'el-icon-location-information',
                            },
                            {
                                label: "运输货物名称",
                                prop: 'yshwmc',
                                type: 'input',
                                width: "",
                                icon: '',
                            },
                            {
                                label: "操作",
                                prop: '',
                                type: 'button',
                                width: "80",
                                icon: '',
                                button: [
                                    {
                                        active: 'add',
                                        icon: 'el-icon-plus'
                                    },
                                    {
                                        active: 'delete',
                                        icon: 'el-icon-minus'
                                    },
                                ]
                            }
                        ],
                    },
                ]
            }
            break;
        case '05':
            // 不动产销售
            data = {
                title,
                list: [
                    {
                        title: "不动产单元代码",
                        type: "input",
                        key: "wqhtbabh",
                        is_require: false,
                        value: "",
                    },
                    {
                        title: "不动产地址",
                        type: "area",
                        key: "fwfsd",
                        is_require: true,
                        disabled: true,
                        value: "",
                    },{
                        title: "详细地址",
                        type: "input",
                        key: "fullAddress",
                        is_require: false,
                        value: "",
                    },{
                        title: "跨地（市）标志",
                        type: "select",
                        key: "kdsbz",
                        is_require: true,
                        value: "否",
                        options: [
                            {
                                label: "是",
                                value: 'Y'
                            },
                            {
                                label: "否",
                                value: 'N'
                            }
                        ]
                    },{
                        title: "土地增值税项目编号",
                        type: "input",
                        key: "tdzzsxmbh",
                        is_require: false,
                        value: "",
                    },{
                        title: "核定计税价格",
                        type: "input",
                        key: "hdjsjg",
                        is_require: false,
                        value: "",
                    },{
                        title: "实际成交含税金额",
                        type: "input",
                        key: "sjcjhsje",
                        is_require: false,
                        value: "",
                    },{
                        title: "房屋产权证书",
                        type: "input",
                        key: "cqzsh",
                        is_require: false,
                        value: "",
                    },{
                        title: "面积单位",
                        type: "select",
                        key: "dw",
                        is_require: true,
                        value: '',
                        options: acreageList()
                    },
                ]
            }
            break;
        case '06':
            // 不动产经营租赁服务
            data = {
                title,
                list: [
                    {
                        title: "不动产地址",
                        type: "area",
                        key: "fwfsd",
                        is_require: true,
                        disabled: true,
                        value: "",
                    },{
                        title: "详细地址",
                        type: "input",
                        key: "fullAddress",
                        is_require: false,
                        value: "",
                    },{
                        title: "租赁期起止",
                        type: "times",
                        key: "zlrq",
                        is_require: true,
                        value: ["", ""],
                    },{
                        title: "跨地（市）标志",
                        type: "select",
                        key: "kdsbz",
                        is_require: true,
                        value: "否",
                        options: [
                            {
                                label: "是",
                                value: 'Y'
                            },
                            {
                                label: "否",
                                value: 'N'
                            }
                        ]
                    },{
                        title: "产权证书/证号",
                        type: "input",
                        key: "cqzsh",
                        is_require: true,
                        value: "",
                    },{
                        title: "面积单位",
                        type: "select",
                        key: "dw",
                        is_require: true,
                        value: '',
                        options: acreageList()
                    },
                ]
            }
            break;
        case '09':
            // 旅客运输服务
            data = {
                title,
                list: [
                    {
                        title: "",
                        type: "table",
                        key: "userIdList",
                        is_require: false,
                        value: [
                            {
                                cxr: "",
                                cxrq: '',
                                sfzjlx: "",
                                sfzjhm: "",
                                qyd: "",
                                ddd: "",
                                jtgjlx: "",
                                dengj: "",
                            },
                          ],
                        tableData: [
                            {
                                label: "出行人",
                                prop: 'cxr',
                                type: 'input',
                                width: "",
                                icon: '',
                            },
                            {
                                label: "出行日期",
                                prop: 'cxrq',
                                type: 'time',
                                width: "160",
                                icon: '',
                            },
                            {
                                label: "出行人证件类型",
                                prop: 'sfzjlx',
                                type: 'select',
                                width: "",
                                icon: '',
                                options: cardList()
                            },
                            {
                                label: "出行人证件号码",
                                prop: 'sfzjhm',
                                type: 'input',
                                width: "",
                                icon: '',
                            },
                            {
                                label: "出发地",
                                prop: 'qyd',
                                type: 'input',
                                disabled: true,
                                width: "",
                                icon: 'el-icon-location-information',
                            },
                            {
                                label: "到达地",
                                prop: 'ddd',
                                type: 'input',
                                disabled: true,
                                width: "",
                                icon: 'el-icon-location-information',
                            },
                            {
                                label: "交通工具类型",
                                prop: 'jtgjlx',
                                type: 'select',
                                width: "",
                                icon: '',
                                options: transportationList()
                            },
                            {
                                label: "等级",
                                prop: 'dengj',
                                type: 'input',
                                width: "",
                                icon: '',
                            },
                            {
                                label: "操作",
                                prop: '',
                                type: 'button',
                                width: "80",
                                icon: '',
                                button: [
                                    {
                                        active: 'add',
                                        icon: 'el-icon-plus'
                                    },
                                    {
                                        active: 'delete',
                                        icon: 'el-icon-minus'
                                    },
                                ]
                            }
                        ],
                    },
                ]
            }
            break;
        case '16':
            // 农产品收购
            data = {
                title,
                list: [
                    {
                        title: "农产品收购证件类型",
                        type: "select",
                        key: "ncpsgzjlx",
                        is_require: false,
                        value: "",
                        options: cardListByFarming()
                    }
                ]
            }
            break;
        case '13':
            // 拖拉机和联合收割机
            data = {
                title,
                list: [
                    {
                        title: "发动机号码",
                        type: "input",
                        key: "fdjhm",
                        is_require: true,
                        value: "",
                    },
                    {
                        title: "底盘号/机架号",
                        type: "input",
                        key: "dphgzbh",
                        is_require: true,
                        value: "",
                    },
                ]
            }
            break;
        default:
            break;
    }
    return data
}

/**
 * 转换发票特定信息数据
 * @param {Object} invoiceData  
 */
export function transSpecialField(invoiceData) {
    let data = {}
    invoiceData.specialFieldEcho.list.forEach(item => {
        if(item.type == 'table') {
            data = item.value
            return
        }
        data[item.key] = item.value
    })
    return data
}

/**
 * 转换发票表格信息
 * @param {Object} invoiceData  
 */
export function transTableData(tableData) {
    let data = tableData
    data.forEach((element) => {
        switch(element.status) {
            case'01':
                element.status = '未开票'
               break;
           case'02':
               element.status = '开票成功'
               break;
           case'03':
               element.status = '开票失败'
               break;
            case'04':
               element.status = '已红冲'
               break;
           default:
               break;
        }
        switch (element.type) {
            case "82":
                element.type = "数电普票"
                element.color = "#35c07d"
                break;
            case "81":
                element.type = "数电专票"
                element.color = "#35c07d"
                element.weight = 700
                break;
            case "86":
                element.type = "数电纸普"
                element.color = "#f9a437"
                break;
            case "85":
                element.type = "数电纸专"
                element.color = "#f9a437"
                element.weight = 700
                break;
            default:
                break;
        }
      });
      return data
}

/**
 * 根据税率改变税率标识 
 */
export function changeTaxType(taxRateValue) {
    let data = {
        preferentialPolicyFlag: '', // 优惠赠策标识 0 未使用 1 使用
        zeroTaxFlag: '', // 零税率标识 0 代表正常税率 1 出口免税和其他免税优惠政策(免税）2 不征增值税（不征税）普通零税率 （0%） 
        vatSpecialManage: '', // 增值税特殊管理 简易征收 稀土产品 免税 不征税 先征后退
    }
    if(taxRateValue == 0) {
        // 免税
        data.preferentialPolicyFlag = '1'
        data.zeroTaxFlag = '1'
        data.vatSpecialManage = '免税'
    } else{
        // 简易征收
        data.preferentialPolicyFlag = '1'
        data.zeroTaxFlag = '0'
        data.vatSpecialManage = '简易征收'
    }
    return data
}

/**
 * 获取客户开出的所有进项发票
 * @param {*} data  发票表格数据
 * @param {*} clientName  客户名称
 */
// export function getInComeInvoiceDrawerData(data, clientName, employee) {
//     let item_data = {
//         line: [{
//             horizontal: true,
//             isShow: true,
//             isDisable: false,
//             title: "",
//             type: "table",
//             summary: false,
//             menu: [{
//                 key: "invoiceNum",
//                 value: "进项发票编号",
//                 type: "link",
//                 width: "",
//             }, {
//                 key: "status",
//                 value: "发票状态",
//                 type: "text",
//                 width: "80",
//             }, {
//                 key: "money",
//                 value: "总金额",
//                 width: "80",
//                 type: "text",
//             }, {
//                 key: "buyerName",
//                 value: "购买方名称",
//                 type: "text",
//                 width: "80",
//             }, {
//                 key: "type",
//                 value: "发票种类",
//                 type: "text",
//                 width: "80",
//             }, {
//                 key: "tradeNo",
//                 value: "订单号",
//                 type: "text",
//                 width: "80",
//             }, ],
//             tableData: []
//         }]
//     }
//     let invoiceDrawerData = {
//         title: "发票",
//         flag: false,
//         hasSetting: false,
//         settingOperList: [],
//         buttonList: [],
//         data: {
//             title: clientName,
//             customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
//             isDisable: false, // 是否只读
//             butArray: [{
//                 text: "修改计划",
//                 active: ""
//             }],
//             modelList: []
//         }
//     }

//     let tt = {
//         line: [{
//             isShow: true,
//             isDisable: false,
//             title: "个税人数",
//             type: "input",
//             value: employee.num
//         }, {
//             isShow: true,
//             isDisable: false,
//             title: "个税金额",
//             type: "input",
//             value: employee.money
//         }]
//     }
//     invoiceDrawerData.data.modelList.push(JSON.parse(JSON.stringify(tt)))

//     if (data.length > 0) {
//         // invoiceDrawerData.data.modelList = []
//         item_data.line[0].tableData = data
//         invoiceDrawerData.data.modelList.push(JSON.parse(JSON.stringify(item_data)))
//     }
//     return invoiceDrawerData
// }

/**
 * 获取理票登记抽屉数据
 * @param {*} data 
 * @param {*} title 
 */
export function getInvoiceRegisterDrawerData(data, title) {
    let drawerData = {
        title: "销售商机",
        flag: false,
        hasSetting: false,
        colseanimationflag: false,
        CardList: [{
            text: "理票登记",
            active: "register",
            flag: 0
        }, {
            text: "操作记录",
            active: "operation",
            flag: 0
        }, ],
        cardIndex: 0,
        data: {
            title: title,
            butArray: [{
                text: "消息推送",
                active: "",
                authority: 1
            }],
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                line: [{
                        isShow: true, // 是否显示
                        isDisable: false, // 是否只读
                        title: "理票状态",
                        type: "select",
                        value: data == "" ? "未整理" : (data.status == null ? "" : data.status),
                        options: [{
                                label: "未整理",
                                value: "未整理",
                            },
                            {
                                label: "整理中",
                                value: "整理中",
                            },
                        ],
                    },
                    {
                        isShow: true,
                        isDisable: false,
                        title: "理票时间",
                        flag: "1", // 当前字段是否在其他字段中:0不在,1在
                        type: "time",
                        value: data == "" ? "" : (data.time == null ? "" : data.time),
                    },
                ]
            }, {
                line: [{
                    isShow: true,
                    isDisable: false,
                    title: "",
                    flag: "1", // 当前字段是否在其他字段中:0不在,1在
                    type: "invoice-register",
                    value: {
                        flag: true,
                        data: data == "" ? [{
                            name: "销售专票",
                            num: "", // 数量
                            money: "", // 金额
                            des: "" // 备注
                        }, {
                            name: "销售普票",
                            num: "", // 数量
                            money: "", // 金额
                            des: "" // 备注
                        }, {
                            name: "进项专票",
                            num: "", // 数量
                            money: "", // 金额
                            des: "" // 备注
                        }, {
                            name: "进项普票",
                            num: "", // 数量
                            money: "", // 金额
                            des: "" // 备注
                        }, {
                            name: "费用发票",
                            num: "", // 数量
                            money: "", // 金额
                            des: "" // 备注
                        }, {
                            name: "银行流水",
                            num: "", // 数量
                            money: "", // 金额
                            des: "" // 备注
                        }, ] : data.type,
                        total: data == "" ? 0 : data.total,
                    },
                }]
            }, {
                line: [{
                    isShow: true,
                    isDisable: false,
                    title: "文件",
                    flag: "1", // 当前字段是否在其他字段中:0不在,1在
                    type: "file",
                    value: data == "" ? [] : (data.fileUp == null ? [] : data.fileUp),
                }]
            }, {
                line: [{
                    isShow: true,
                    isDisable: false,
                    title: "备注",
                    flag: "1", // 当前字段是否在其他字段中:0不在,1在
                    type: "textarea",
                    value: data == "" ? "" : (data.remark == null ? "" : data.remark),
                }]
            }]
        }
    }
    return drawerData
}

/**
 * 获取理票登记操作记录抽屉数据
 * @param {*} data 
 * @param {*} title 
 */
export function getInvoiceRegisterOperationDrawerData(data, title) {
    let drawerData = {
        title: "销售商机",
        flag: false,
        hasSetting: false,
        colseanimationflag: false,
        CardList: [{
            text: "理票登记",
            active: "register",
            flag: 0
        }, {
            text: "操作记录",
            active: "operation",
            flag: 0
        }, ],
        cardIndex: 1,
        data: {
            title: title,
            customer: 0, // 关联客户,与商机状态关联,(只有商机状态为成交才有值),其他时候为空字符串
            isDisable: false, // 是否只读
            modelList: [{
                line: [{
                    isShow: true,
                    isDisable: false,
                    title: "",
                    type: "operation",
                    value: data == "" ? [] : data,
                }, ]
            }]
        }
    }

    return drawerData
}

/**
 * 理票登记保存数据前格式化数据
 * @param {*} data 原数据
 * @param {*} clientData 客户数据
 * @param {*} id 理票ID
 */
export function formatSaveInvoiceRegisterDrawerData(data, clientData, id) {
    let resultData = {
        clientId: clientData.id, // 客户Id
        year: clientData.year, // 年份
        month: clientData.month, // 月份
        id: id,
        status: data[0].line[0].value, // 理票状态
        time: data[0].line[1].value, // 理票时间
        type: data[1].line[0].value.data,
        fileUp: data[2].line[0].value, // 文件上传
        remark: data[3].line[0].value, // 备注
    }
    return resultData
}

/**
 * 导入常用客户的dialog数据
 */
export function importInvoiceDialogData(this_) {
    return [{
        type: "import_invoice",
        value: {
            url: "",
            text: "",
            action: this_.$fileBaseurl + "/uploadImageByMultipartFile",
            uploadParameter: {
                systemName: "finance",
                title: "导入常用客户",
            }, 
            headers: { 
                token: "95931b371eb64f4a9c862983ca8a5d15",
            },
            modelType: "activeCustomerMode"
        },

    }]
}

