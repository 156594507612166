<template>
  <div id="person-icon">
    <!-- 头像设置 -->
    <div class="table-content">
      <div class="person-icon-content">
        <div class="person-icon-content-title">头像设置</div>
        <div class="person-icon-content-data">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :headers="headers"
            :data="uploadParameter"
            :on-success="handleAvatarSuccess"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div class="person-icon-content-data-img">
            <img
              style="width: 300px; height: 300px; border-radius: 150px"
              :src="imgUrl"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseInfo: {},
      action: this.$fileBaseurl + "/uploadImageByMultipartFile",
      imgUrl:
        "http://114.55.138.13:8089/20210324/13:36:00_我的世界守则_1616564160719.jpg",
      headers: {
        token: "95931b371eb64f4a9c862983ca8a5d15",
      },
      uploadParameter: {
        //上传时携带的参数
        systemName: "",
        title: "图像",
      },
    };
  },
  methods: {
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {
        userId: this.baseInfo.userId, // 员工ID
        icon: response.result,
      };
      this.$request(
        "post",
        "/user/updateEmployeeIcon",
        requestData,
        (data) => {
          this.$message({
            message: "图像修改成功!",
            type: "success",
            duration: 1000,
          });
          this.imgUrl = response.result.path;
          // sessionStorage.setItem("userData");
          this.$store.dispatch("setIcon", response.result.path);
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
  },
  mounted() {
    this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    console.log(this.baseInfo);
    this.imgUrl = this.$store.getters.getIcon;
  },
};
</script>

<style lang="less" scoped>
#person-icon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .person-icon-content {
    width: 98%;
    height: 95%;
    max-width: 90vw;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .person-icon-content-title {
      width: 90%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: 40px;
      font-size: 17px;
    }
    .person-icon-content-data {
      width: 80%;
      margin-left: 80px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 14px;
      .person-icon-content-data-img {
        margin-top: 20px;
      }
    }
  }
}
</style>
