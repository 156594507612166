/**
 * 获取当前模块的当前状态对应所有状态按钮
 */
export function getStatus(status, model) {
    let statusList = []

    switch (model) {
        case "myThread":
            // 我的商机
        case "allThread":
            // 我的商机
        case "clientThread":
            // 已签约客户
        case "threadPool":
            // 商机池
        case "threadRecycle":
            // 回收站
            switch (status) {
                case "已成交":
                    // 删除 新建
                    statusList = ["delete", "create"]
                    break;
                case "待跟进":
                case "跟进中":
                    // 删除 跟进 转成交 置为无效 释放至线索池 
                    statusList = ["delete", "followUp", "turnover", "invalid", "openSea"]
                    break;
                case "待分配":
                    // 删除 分配 转成交 恢复
                    statusList = ["delete", "distribution", "restore", "turnover"]
                    break;
                case "无效":
                    // 彻底删除 还原 跟进 转成交 恢复
                    statusList = ["shiftDelete", "reduction", "followUp", "turnover", "restore"]
                    break;

                default:
                    break;
            }
            break;

        case "contractInfo":
            // 全部合同
            switch (status) {
                case "待提交":
                    statusList = ["makeInvoice", "receivable"]
                    break;
                case "合同审核不通过":
                    // 提交 删除合同 添加合同支出 上传合同附件 手动结束 编辑
                    statusList = ["makeInvoice", "delete", "edit", "addSpend", "addAttachment", "finish", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break;
                case "待合同审核":
                case "财务审核不通过":
                    // 添加合同支出 上传合同附件 查账 手动结束 彻底删除
                    statusList = ["makeInvoice", "addSpend", "shiftDelete", "addAttachment", "finish", "receivable", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break;
                case "待财务审核":
                    // 添加合同支出 上传合同附件 查账 下单 手动结束 电子合同生成 彻底删除
                    statusList = ["makeInvoice", "addSpend", "shiftDelete", "addAttachment", "finish", "receivable", "orders", "electronicContract", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break;
                case "执行中":
                case "服务中":
                case "待下单":
                    // 电子合同生成 添加合同支出 上传合同附件 查账 手动结束 彻底删除
                    statusList = ["makeInvoice", "addSpend", "shiftDelete", "addAttachment", "finish", "receivable", "electronicContract", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break;
                case "已结束":
                    // 添加合同支出 上传合附件 查账 彻底删除
                    statusList = ["makeInvoice", "addSpend", "shiftDelete", "addAttachment", "receivable", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break;
                case "已终止":
                    // 恢复合同 添加合同支出 上传合同附件 彻底删除
                    statusList = ["makeInvoice", "addSpend", "shiftDelete", "addAttachment", "receivable", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break;
                default:
                    break;
            }
            break
        case "contractCheck":
            // 合同审核
            switch (status) {
                case "待合同审核":
                case "财务审核不通过":
                    // 合同审核 
                    statusList = ["checkInfo", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break;
                case "待财务审核":
                    statusList = ["checkMoney", "audit", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break

                default:
                    // 其他所有状态  财务查账
                    statusList = ["audit", "showClient", "showPaymentAttachment", "showSpendAttachment", "showAttachment", "showChargeIs", "showSpend"]
                    break;
            }
            break
        case "contractWork":
            // 合同派工
            switch (status) {
                case "可提交":
                    // 合同审核 
                    statusList = ["edit", "roleAuthority", "editClientName"]
                    break;
            }
            break

        case "employee":
            switch (status) {
                case "离职":
                    statusList = ["delete"]
                    break;
                case "在职":
                    statusList = ["add", "edit", "updateRecorder", "departure", "bind", "updatePsw"]
                    break;

                default:
                    break;
            }
            break
        default:
            break;
    }
    console.log("当前模块显示所有按钮:", model, status, statusList);
    return statusList
}