<template>
  <div id="customer">
    <div class="customer-top">
      <div class="customer-top-search">
        <el-input v-model="name" placeholder="请输入内容">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          ></el-button>
        </el-input>
      </div>
      <div class="customer-top-buts">
        <div class="customer-top-but" @click="handelClick(0)">新增常用客户</div>
        <div class="customer-top-but" @click="handelClick(1)">导入</div>
        <div class="customer-top-but" @click="handelClick(2)">导出</div>
      </div>
    </div>
    <div class="customer-table">
      <el-table
        :data="value.list"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="name" label="客户名称" width="190">
        </el-table-column>
        <el-table-column prop="taxNum" label="税号" width="170">
        </el-table-column>
        <el-table-column prop="address" label="地址"> </el-table-column>
        <el-table-column prop="tel" label="电话" width="120"> </el-table-column>
        <el-table-column prop="bank" label="开户行"> </el-table-column>
        <el-table-column prop="bankAccount" label="账号"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="tableClick(scope.row, 0)"
              >编辑</el-button
            >
            <el-button type="text" @click="tableClick(scope.row, 1)"
              >移除</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- <div class="customer-table-cell head">
        <div class="cell">序号</div>
        <div class="cell">客户名称</div>
        <div class="cell">税号</div>
        <div class="cell">地址</div>
        <div class="cell">电话</div>
        <div class="cell">开户行</div>
        <div class="cell">账号</div>
        <div class="cell">操作</div>
      </div>
      <div
        class="customer-table-cell"
        v-for="(item, index) in value.list"
        :key="index"
      >
        <div class="cell">{{ index + 1 }}</div>
        <div class="cell">{{ item.name }}</div>
        <div class="cell">{{ item.taxNum }}</div>
        <div class="cell">{{ item.address }}</div>
        <div class="cell">{{ item.tel }}</div>
        <div class="cell">{{ item.bank }}</div>
        <div class="cell">{{ item.bankAccount }}</div>
        <div class="cell">
          <el-button
            type="text"
            @click="tableClick(item, index, 0)"
          >编辑</el-button>
          <el-button
            type="text"
            @click="tableClick(item, index, 1)"
          >移除</el-button>
        </div>
      </div> -->
    </div>

    <!-- 弹出框 -->
    <div id="my-dialog" v-if="dialogData.flag">
      <my_dialog1
        :width="dialogData.width"
        :title="dialogData.title"
        :buttonArray="dialogData.buttonArray"
        :data="dialogData.data"
        @colseDialog="colseDialog"
        @dialogOper="drawerOper"
      ></my_dialog1>
    </div>
  </div>
</template>

<script>
// import my_dialog1 from "./dialog.vue";
import * as invoiceData from "@/assets/js/invoiceData.js";
export default {
  //   name: "customer",
  props: ["customerData"],
  components: {
    my_dialog1: () => import("./dialog.vue"),
  },
  data() {
    return {
      baseInfo: "",
      value: this.customerData,
      // value: {
      //   clientId: "", // 客户ID
      //   list: [
      //     {
      //       id: "",
      //       name: "阿里巴巴",
      //       taxNum: "42342",
      //       address: "杭州",
      //       tel: "4254",
      //       bank: "中国银行",
      //       bankAccount: "54344534544",
      //     },
      //   ],
      // },
      name: "",
      multipleSelection: [],
      dialogData: {
        flag: false,
        width: "40%",
        title: "添加跟进记录",
        buttonArray: [
          {
            text: "提交",
            active: "submit_gnjinjilu",
          },
        ],
        data: [],
      },

      tempClient: {},
    };
  },
  watch: {
    customerData() {
      //父组件值变化->>子组件也变化
      this.value = this.customerData;
    },
    value(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    /**
     * 获取所有常用客户
     */
    getAllCustomer() {
      let requestData = {
        clientId: this.value.clientId,
      };
      this.$request(
        "post",
        "/activeCustomer/queryAllCustomers",
        requestData,
        (data) => {
          this.value.list = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * 新增常用客户或导出常用客户
     */
    handelClick(flag) {
      let requestData = {};
      switch (flag) {
        case 0:
          //   新增常用客户
          this.dialogData.title = "新增常用客户";
          this.dialogData.buttonArray = [
            {
              text: "获取工商信息",
              active: "get_info",
            },
            {
              text: "确认",
              active: "add",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "input",
              title: "客户全称",
              value: "",
            },
            {
              type: "input",
              title: "税号",
              value: "",
            },
            {
              type: "input",
              title: "地址",
              value: "",
            },
            {
              type: "input",
              title: "电话",
              value: "",
            },
            {
              type: "input",
              title: "开户行",
              value: "",
            },
            {
              type: "input",
              title: "账号",
              value: "",
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 导入常用客户;
          this.dialogData.title = "导入常用客户";
          this.dialogData.data = invoiceData.importInvoiceDialogData(this);
          this.dialogData.buttonArray = [
            {
              text: "确定",
              active: "makesure_import_invoice",
            },
          ];

          this.dialogData.flag = true;
          break;
        case 2:
          //   导出常用客户
          let ids = this.multipleSelection.map((item) => {
            return item.id;
          });
          if (ids.length == 0) {
            this.$message.error("请先选择要导出的发票!");
            return;
          }
          let requestData = {
            ids: ids,
          };
          window.location.href =
            this.$baseurl +
            "/activeCustomer/exportCommonCustomer?data=" +
            JSON.stringify(requestData);
          break;
        default:
          break;
      }
    },
    /**
     * 表格操作事件
     */
    tableClick(row, flag) {
      this.tempClient = row;
      let requestData = {};
      switch (flag) {
        case 0:
          // 编辑常用客户
          this.dialogData.title = "编辑常用客户";
          this.dialogData.buttonArray = [
            {
              text: "获取工商信息",
              active: "get_info",
            },
            {
              text: "确认",
              active: "update",
            },
            {
              text: "取消",
              active: "cancel",
            },
          ];
          this.dialogData.data = [
            {
              type: "input",
              title: "客户全称",
              value: row.name,
            },
            {
              type: "input",
              title: "税号",
              value: row.taxNum,
            },
            {
              type: "input",
              title: "地址",
              value: row.address,
            },
            {
              type: "input",
              title: "电话",
              value: row.tel,
            },
            {
              type: "input",
              title: "开户行",
              value: row.bank,
            },
            {
              type: "input",
              title: "账号",
              value: row.bankAccount,
            },
          ];
          this.dialogData.flag = true;
          break;
        case 1:
          // 移除常用客户
          requestData = {
            id: row.id,
          };
          this.$request(
            "post",
            "/activeCustomer/deleteCustomers",
            requestData,
            (data) => {
              this.$message({
                message: "常用客户移除成功!",
                type: "success",
                duration: 1000,
              });
              this.getAllCustomer();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * 模糊搜索
     */
    search() {
      let requestData = {
        name: this.name,
        clientId: this.value.clientId,
      };
      this.$request(
        "post",
        "/activeCustomer/searchCustomersByName",
        requestData,
        (data) => {
          this.value.list = data;
        },
        (errorMessage) => {
          this.$message.error(errorMessage);
        }
      );
    },
    /**
     * dialog操作事件
     */
    drawerOper(active) {
      let requestData = {};
      switch (active) {
        case "add":
          // 确认添加常用客户
          requestData = {
            name: this.dialogData.data[0].value, // 名称,
            clientId: this.value.clientId, // 客户ID,
            taxNum: this.dialogData.data[1].value, // 企业税号,
            address: this.dialogData.data[2].value, // 详细地址,
            tel: this.dialogData.data[3].value, // 电话,
            bank: this.dialogData.data[4].value, // 开户行,
            bankAccount: this.dialogData.data[5].value, // 银行账号,
          };
          this.$request(
            "post",
            "/activeCustomer/addCustomers",
            requestData,
            (data) => {
              this.$message({
                message: "常用客户添加成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getAllCustomer();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "update":
          // 确认修改常用客户
          requestData = {
            name: this.dialogData.data[0].value, // 名称,
            id: this.tempClient.id, // 常用客户ID,
            taxNum: this.dialogData.data[1].value, // 企业税号,
            address: this.dialogData.data[2].value, // 详细地址,
            tel: this.dialogData.data[3].value, // 电话,
            bank: this.dialogData.data[4].value, // 开户行,
            bankAccount: this.dialogData.data[5].value, // 银行账号,
          };
          this.$request(
            "post",
            "/activeCustomer/updateCustomers",
            requestData,
            (data) => {
              this.$message({
                message: "常用客户修改成功!",
                type: "success",
                duration: 1000,
              });
              this.dialogData.flag = false;
              this.getAllCustomer();
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "get_info":
          // 通过企查查获取企业工商信息
          this.$request(
            "get",
            "/qicc/search?keyword=" + this.dialogData.data[0].value,
            {},
            (data) => {
              this.dialogData.data[0].value = data.name;
              this.dialogData.data[1].value = data.taxNum;
              this.dialogData.data[2].value = data.address;
            },
            (errorMessage) => {
              this.$message.error(errorMessage);
            }
          );
          break;
        case "cancel":
          this.colseDialog();
          break;
        case "makesure_import_invoice":
          // 确定导入资源模板
          requestData = {
            clientId: this.value.clientId,
            url: this.dialogData.data[0].value.url,
          };
          if (requestData.url == "") {
            this.$message.error("请先选择要导入的文件!");
            return;
          }
          console.log("导入资源数据:", requestData);
          this.$request(
            "post",
            "/activeCustomer/importCommonCustomer",
            requestData,
            (data) => {
              this.$message.success("导入成功");
              this.dialogData.flag = false;
              this.getAllCustomer();
            },
            (err) => {
              this.$message({
                message: err,
                type: "error",
              });
              this.dialogData.flag = false;
            }
          );
          break;
        default:
          break;
      }
    },
    /**
     * dialog关闭事件
     */
    colseDialog() {
      this.dialogData.flag = false;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    created() {
      this.baseInfo = JSON.parse(sessionStorage.getItem("userData"));
    },
  },
};
</script>

<style lang="less" scoped>
#customer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .customer-top {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .customer-top-search {
      width: 50%;
      height: 100%;
    }
    .customer-top-buts {
      width: auto;
      height: 100%;
      display: flex;
      font-size: 14px;
      .customer-top-but {
        width: auto;
        padding: 5px 15px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: 25px;
        border: 1px solid @activeColor;
        background-color: @activeBackground;
        border-radius: 5px;
        transition: 0.3s;
        color: rgb(85, 85, 85);
        &:hover {
          cursor: pointer;
          background-color: @activeColor2;
          color: #fff;
        }
      }
    }
  }
  .customer-table {
    width: 100%;
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 1px;
    }
    .customer-table-cell {
      width: 100%;
      height: auto;
      min-height: 38px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid @boderColor;
      box-sizing: border-box;
      font-size: 12px;
      &:nth-last-child(1) {
        border: none;
      }
      .cell {
        flex: 1;
        width: 0;
        height: auto;
        text-align: left;
        padding: 5px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .head {
      background-color: @activeBackground;
    }
  }
}
</style>
