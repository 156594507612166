import { render, staticRenderFns } from "./thread_all.vue?vue&type=template&id=67ba0875&scoped=true&"
import script from "./thread_all.vue?vue&type=script&lang=js&"
export * from "./thread_all.vue?vue&type=script&lang=js&"
import style0 from "./thread_all.vue?vue&type=style&index=0&id=67ba0875&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67ba0875",
  null
  
)

export default component.exports